.SpecialIndicators {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
}

.carts-dropdown {
  background-color: #fff;
  padding: 0px 5px;
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  text-align: center;

  i.icon {
    font-size: 12px;
  }
}

.cart-all-option {
  text-align: center !important;
}

.cart-selected-option {
  background-color: #e8f3fa !important;
}

.grayFunnelIcon {
  color: #767676cc;
  font-size: 10px !important;
}

.shoppingCartIcon {
  color: #6b6b6b;
}

.absoluteFunnel {
  position: absolute;
  right: 1px;
  font-size: 11px !important;
}

.dropdown-menu-container > *:not(:last-child) {
  border-bottom: 1px solid #edeeef !important;
}

.orange-cart {
  color: #f2711c !important;
}

.yellow-cart {
  color: #cccc00 !important;
}

.yellow-cart-border {
  border: 1px solid #cccc00 !important;
}

.yellow-cartbg {
  background-color: rgba(204, 204, 0, 0.2);
}

i.icon.shopping.cart.yellow {
  color: #cccc00 !important;
}
