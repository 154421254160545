.DealerDocumentationTable {
  .doc-table-category {
    background: none !important;
    border: 1px solid #e2e2e3 !important;
    color: black !important;
    font-weight: normal !important;
  }

  .edit-button-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    cursor: pointer;
    height: 28px;
    background: #c7eed0;
    border-radius: 5px;
  }

  .edit-icon {
    font-size: 14px !important;
    color: #32bf53 !important;
    margin: 0px !important;
  }

  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
