.CarQuickView-container {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 29px 29px 29px 22px;
  width: 534px;
  height: 178.48px;

  &__inner {
    height: 126px;
    display: flex;
    align-items: flex-start;

    .car-image-container {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 35px;

      i.car.icon {
        font-size: 6em;
        color: #ccc;
        position: relative;
        top: 27px;
      }

      img {
        width: 197px;
        height: 100%;
        border-radius: 4px;
      }
    }

    .car-details {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 35px;
      row-gap: 15px;
      height: 100%;

      .detail-item {
        display: flex;
        flex-direction: column;
        font-size: 12.6px;
        line-height: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .detail-title {
          font-weight: bold;
          margin-bottom: 3px;
        }

        span.detail-value {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
