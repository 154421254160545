.DealerUsersReportTable {
  margin-top: 15px;

  .ReactTable {
    thead tr th {
      vertical-align: bottom !important;

      padding-left: 0px;
    }
    thead tr th,
    tbody tr td {
      text-align: left !important;
    }

    thead tr th::first-letter {
      text-transform: capitalize;
    }

    .threshold-placeholder {
      padding-left: 12px;
    }
  }

  .threshold-value-cell {
    color: #000000cc;
    padding: 4.5px 8.2px;
    border-radius: 4px;
  }

  .title-sorting-icons-header {
    position: relative;

    .title {
      display: inline-block;
      position: relative;
      left: 5px;

      strong {
        transform: rotate(180deg);
        writing-mode: tb;
        text-orientation: mixed;
      }
    }

    .icons {
      display: inline-block;
      font-size: 14px;
      cursor: pointer;
      position: absolute;
      right: 0px;
      bottom: 0px;
      margin: 0px !important;

      i {
        position: relative;
        top: 3px;
      }
    }
  }

  th:nth-child(1),
  th:nth-child(2),
  th:nth-child(3) {
    .title-sorting-icons-header {
      .title {
        left: 10px;

        strong {
          transform: unset;
          writing-mode: unset;
          text-orientation: unset;
        }
      }
      .icons {
        position: relative;
        left: 10px;
      }
    }
  }
}

.thresholdrange-wrapper {
  display: flex;

  & > * {
    max-width: 30px;
    font-size: 13px;
    font-weight: 500;
    padding: 3px;
    display: flex;
    align-items: center;
    text-align: center;
    outline: none;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    appearance: textfield;
    -moz-appearance: textfield;
  }

  .min-threshold-input {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid #ee3115;
  }

  .max-threshold-input {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #4d853b;
    border-left: none;
  }
}
