$brown-bg: #795647;
$light-brown-bg: #79564799;

$pink-bg: #ea1e62;
$light-pink-bg: #ea1e6299;

$tomato-bg: #ff5621;
$light-tomato-bg: #ff562199;

$orange-bg: #ffc008;
$light-orange-bg: #ffc00899;

$yellow-bg: #ffeb3b;
$light-yellow-bg: #ffeb3b99;

$lemon-bg: #bfde1b;
$light-lemon-bg: #bfde1b99;

$green-bg: #8cc34a;
$light-green-bg: #8cc34a99;

$grass-bg: #4caf4f;
$light-grass-bg: #4caf4f99;

$space-bg: #5f7d8b;
$light-space-bg: #5f7d8b99;

$blue-bg: #4050b5;
$light-blue-bg: #4050b599;

$skyblue: #2084ce;

$grey-bg: #607d8b;
$light-grey-bg: #607d8b99;

$light-gray: #e0e1e2;

$purple-bg: #6c0699;
$light-purple-bg: #6c069999;

$limegreen-bg: #21ba45;
$light-limegreen-bg: #21ba4533;

$red-bg: #ff0202;
$light-red-bg: #ff020233;

$mango-bg: #ffba39;
$light-mango-bg: #ffba3933;

$grey: #767676;
$light-grey: #70707029;
