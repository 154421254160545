.Manufacturer {
  &__status {
    margin: 0 !important;
    i.icon {
      height: auto;
    }
    &-approved {
      background-color: #21ba45 !important;

      i.icon {
        color: #ffff;
      }
    }

    &-declined {
      background-color: #ff0000 !important;

      i.icon {
        transform: translate(1px, 0);
        color: #ffff;
      }
    }

    &-pending {
      background-color: #064299 !important;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;

      i {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      i.icon {
        color: #ffff;
      }
    }
  }
}
