.NotificationWrapper {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 5px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;

  .content-column {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

    div {
      display: flex;
      line-height: 1;

      .title {
        font-size: 14px;
        padding-right: 5px;
        margin: 0;
      }

      .value {
        font-size: 14px;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 230px;
      }
    }
  }
}
