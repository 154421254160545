.KeylockerCommunicationLog {
  background-color: #f9fafb;
  cursor: pointer;

  .DetailsCards-container {
    padding: 11px 21px;
  }

  .column {
    padding-left: 0px !important;
  }
}
.header.KeylockerCommunicationLogHeader {
  padding: 25px !important;
  justify-content: space-between;

  .header-title {
    display: flex;
    align-items: center;

    div {
      margin: 5px 0px 0px 5px;
    }
  }
}

.KeylockerCommunicationLogContent {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;

  .keylocker-comm-section-container {
    border: 2px solid rgba(187, 194, 198, 0.75);
    margin: 1rem 0;

    .ui.grid {
      margin: 0px;
    }

    .column {
      padding-left: 1em !important;
    }

    .event-info-rows {
      margin-top: 5px;
    }

    .section-rows {
      padding: 0px 0px 0px 1em;
      margin: 10px 0px 10px 0px;

      .column {
        padding-left: 0px !important;
      }
    }

    .services-header,
    .service {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .services-header {
      font-weight: bold;
      margin-bottom: 20px;
    }

    .service {
      margin-bottom: 0.8rem;
      padding-bottom: 0.8rem;
      border-bottom: 1px solid #f3f5f7;
    }

    .service:last-child {
      border-bottom: none;
    }

    .agreements-row {
      margin-bottom: 10px;
      display: flex;
      align-items: flex-start;
      cursor: pointer;

      div:first-child {
        margin-right: 10px;
      }
    }

    .signature-img-container {
      background-color: #f3f5f7;
      border: 2px solid rgba(187, 194, 198, 0.75);
      margin-left: 1rem;
    }

    .event-timestamp {
      text-align: right;
    }

    .event-info-rows > span:not(:first-child) {
      margin-left: 10px;
    }
  }

  .keylocker-comm-section-container:first-child {
    margin: 0px 0px 1rem 0px;
  }

  .keylocker-comm-section-header {
    background-color: #f3f5f7;
    font-weight: 700;
    padding: 1em;
  }
}

.actions.KeylockerCommunicationLogActions {
  justify-content: flex-end !important;
}
