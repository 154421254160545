.ChangePasswordModal {
    .header {
        font: normal normal bold 18px/23px "Lato";
        letter-spacing: 0px;
        color: #000000;
        font-size: 18px;
        line-height: 23px;
    }
    .checkIcon {
        font-size: 1.15em;
        margin-top: 15px;
        color: green;
    }
    .crossIcon {
        font-size: 1.15em;
        margin-top: 15px;
        color: #f54545;
    }
    &-description {
        font: normal normal 14px/23px "Lato";
        letter-spacing: 0px;
        color: #000000;
        font-size: 14px;
        line-height: 23px;
    }
    &-action {
        .inner {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            gap: 1rem;
            .cancel-btn {
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #e2e2e3;
                border-radius: 4px;
                height: 36px;
                color: #000000 !important;
                .icon.cancel {
                    color: #000000;
                }
            }
            .save-btn {
                background: #21ba45 0% 0% no-repeat padding-box;
                border-radius: 4px;
                opacity: 1;
                height: 36px;
            }
        }
    }
    $strength-colors: (darkred, orangered, orange, yellowgreen, green);
    $strength-gap: 6px;
    .strength-meter {
        position: relative;
        height: 3px;
        background: #ddd;
        margin: 7px 0;
        border-radius: 2px;
        &:before,
        &:after {
            content: "";
            height: inherit;
            background: transparent;
            display: block;
            border-color: #fff;
            border-style: solid;
            border-width: 0 $strength-gap 0;
            position: absolute;
            width: calc(20% + #{$strength-gap});
            z-index: 10;
        }
        &:before {
            left: calc(20% - #{$strength-gap / 2});
        }
        &:after {
            right: calc(20% - #{$strength-gap / 2});
        }
        .strength-meter-fill {
            background: transparent;
            height: inherit;
            position: absolute;
            width: 0;
            border-radius: inherit;
            transition: width 0.5s ease-in-out, background 0.25s;
            @for $i from 1 through 5 {
                &[data-strength="#{$i - 1}"] {
                    width: (20% * $i);
                    background: nth($strength-colors, $i);
                }
            }
        }
    }
    .required.password {
        position: relative;
        i.icon {
            position: absolute;
            right: 12px;
            bottom: 15px;
            color: #b5b5b5 !important;
        }
    }
    .equal.width.fields.password-group {
        gap: 0.5rem !important;
    }
    &-success {
        background: #def5e3 0% 0% no-repeat padding-box;
        border: 1px solid #21ba45;
        border-radius: 4px;
        opacity: 1;
        padding: 10px 13px;
        font-family: "Lato" !important;
        font-size: 14px;
        letter-spacing: 0px;
        color: #21ba45;
    }
}