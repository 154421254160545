.Manufacturer {
  &__requestModal {
    &-header {
      display: flex;

      h3 {
        margin-bottom: 0;
      }
      justify-content: space-between;
      padding-right: 25px !important;

      &--closeBtn {
        position: relative;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) !important;
          margin: 0 !important;
        }
      }
    }

    &-content {
      &--input {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}
