.connected-checklists-container {
  margin: 15px;
  border: 1px solid #e2e2e3;
  border-radius: 4px;

  .connected-checklist {
    padding: 10px 15px;
    display: flex;
    gap: 3px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #e2e2e3;
    }
  }
}
