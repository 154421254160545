.Appointment-SnoozedItems {
  border: 1px solid #e2e2e3;
  border-radius: 4px;
  margin-top: 13px;
  .column {
    padding: 0 !important;
    margin: 0;
    display: flex !important;
    align-items: center;
    p {
      &:first-child {
        padding-left: 14px;
      }
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &.header {
      height: 36px;
      background-color: #f9fafb;
      p {
        font-size: 14px;
        font-weight: bold;
        &:first-child {
          padding-left: 14px;
        }
      }
    }
    &.content {
      height: 36px;
      &.last {
        padding-right: 14px !important;
      }
      p {
        font-size: 12.6px;
        &:first-child {
          padding-left: 14px;
        }
      }
    }
    .snooze-images {
      padding-bottom: 20px;
      width: 100%;
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: #e2e2e3;
      &.no-border {
        display: none;
      }
    }
  }
}

.Appointment-SnoozedItems,
.SnoozeTable {
  .snooze-action {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    p {
      font-size: 12.6px;
      strong {
        margin-right: 12px;
      }
      small {
        margin-left: 3px;
        color: #767676;
        font-size: 9px;
      }
    }
    .status-container {
      height: 27px;
      border: 1px solid #e2e2e3;
      border-radius: 4px;
      display: flex;
      align-items: center;
      width: fit-content;
      justify-content: flex-end;
      .status-dropdown {
        border-right: none;
        i.icon.size-m {
          font-size: 12px;
          margin-top: -12px;
        }
        .menu {
          display: none;
          box-shadow: none;
          border: none !important;
        }
        .visible.transition {
          margin-top: 2px;
          div {
            display: flex;
            border: 1px solid #e2e2e3;
            background: #fff;
            box-shadow: none;
            i.icon {
              margin-right: 0;
            }
            &:first-child {
              border-top-right-radius: 4px;
              border-top-left-radius: 4px;
            }
            &:last-child {
              border-bottom-right-radius: 4px;
              border-bottom-left-radius: 4px;
            }
          }
        }
      }
      .action-dropdown {
        border-right: none;
        i.icon {
          margin-top: -8px;
        }
        .menu {
          display: none;
          box-shadow: none;
          border: none !important;
        }
        .visible.transition {
          margin-top: 2px;
          width: fit-content;
          box-shadow: none !important;
          :hover {
            background-color: #fafafa;
          }
          div {
            border: 1px solid #e2e2e3;
            background-color: #fff;
            width: 100%;
            display: flex;
            justify-content: left;
            align-items: center;
            box-shadow: none;
            height: 29px;
            font-weight: bold;
            i.icon {
              margin: 10px 10px 10px 0;
            }
            &:first-child {
              border-top-right-radius: 4px;
              border-top-left-radius: 4px;
            }
            &:last-child {
              border-bottom-right-radius: 4px;
              border-bottom-left-radius: 4px;
            }
          }
        }
      }
      i.icon {
        margin: 0px;
        &.pointer {
          cursor: pointer;
        }
      }
      div {
        height: 100%;
        border-left: 1px solid #e2e2e3;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:first-child {
          border-left: none;
        }
      }
    }
    .empty-status-container {
      width: 32px;
    }
  }
}

.SnoozeTable {
  margin-top: 13px;
  .snooze-action {
    .status-container {
      height: 25px;
      .action-dropdown {
        i.icon {
          margin-top: -2px;
        }
      }
    }
  }
  &-header {
    display: flex;
    border: 1px solid #e2e2e3;
    border-radius: 4px;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: 5px;
    background: #f9fafb 0% 0% no-repeat padding-box;
    h3 {
      margin: 0;
    }
    .icon {
      margin: 0;
      height: auto;
    }
    &.expanded {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &.withPagination {
    .ReactTable {
      border-radius: 0;
    }
  }

  .ReactTable {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .td-action {
      padding-right: 14px;
      .action-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;
        .snooze-action {
          width: auto;
        }
      }
    }
  }
  .ReactTablePagination {
    background: #f9fafb 0% 0% no-repeat padding-box;
    margin: 0;
    padding: 14px;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid #e2e2e3;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .pagination-info {
      display: none;
    }
  }
}
