.SubitemsTable {
    margin: 10px 0;
    th {
        padding-right: 5px;
    }
    tr {
        height: 48px;
    }
    .subitem-delete {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #fff;
        border-radius: 4px;
        max-width: 35px;
        height: 35px;
        i.icon {
            margin-top: -7px;
            margin-right: 0px;
        }
    }
}