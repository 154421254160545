.aceEditorContainer {
  width: "100%";
  border: 1px solid #f0f0f0;
}

.aceEditorContainerInvalid {
  border: 1px solid red;
}

.aceEditor {
  width: "100%";
  height: "300px";
  font-size: 14;
  line-height: 2;
}
