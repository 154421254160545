.StatusCellIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 30px;
  width: 36px;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  color: "#000000";

  .svg-face-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    overflow: hidden;

    img {
      width: 18px;
      height: 18px;
    }
  }

  .small-icon {
    position: absolute;
    bottom: 4px;
    left: 0px;
    font-size: 9px !important;
    z-index: 1;
  }

  .bo-icon {
    font-weight: 600;
    color: #000;
  }

  .top-right {
    position: absolute;
    top: -3px;
    right: -2px;
    font-size: 10px !important;
    z-index: 1;

    &.envelope {
      top: -4px;
      right: 0;
      font-size: 9px !important;
    }
  }

  i {
    color: #000;
    font-size: 14px !important;
  }

  i.icon {
    margin: 0px;
  }

  svg {
    color: #000;
    font-size: 16px !important;
  }
}
