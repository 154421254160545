/*******************************
         Site Overrides
*******************************/

.ui.label > .icon {
    margin: 0;
}

.ui.circular.label {
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}