@import "../../../../../../colorbox.scss";

.checkin-price {
  margin-right: 87px !important;
}

.notificationEmailActive {
  background-color: $limegreen-bg;
}

.changeHandleStatus {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin-top: -10px !important;
  }
}

.mailTriggerWrapper {
  i {
    top: 2px;
    color: #ffff;
  }

  i.icon {
    color: #ffff;
  }
}
