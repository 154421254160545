.BackordersDashboardTable {
  padding: 0px 10px 55px 10px;

  &__bo-note-container {
    max-width: 700px;
  }

  &__table-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &__table-action-buttons-container {
    padding-right: 10px;
  }

  &__table-header-text {
    font-size: 13px;
    color: #000000cc;
    font-weight: 900;
  }
}
