.CustomerDetail {
  padding: 0 1em;
  .row {
    border-bottom: 1px solid #eee;
    strong {
      margin-right: 1em;
    }
  }

  .preferred-channel-button {
    min-width: 70px;
    padding: 5px 8px 5px 8px;
    background-color: #fff;
    border: 1px solid #e2e2e3;
    border-radius: 4px;
    cursor: pointer;

    .icon {
      font-size: 20px;
      margin-right: 1px;
    }

    span {
      vertical-align: 2px;
    }
  }

  .preferred-channel-button:nth-child(1) {
    margin-right: 10px;
  }

  .active-preferred-channel {
    background-color: #55aa0d;
    color: #fff;
  }
}
