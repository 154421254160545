#new-intervention {
    .field {
        padding-left: 0px;
        padding-right: 0px;
    }
    .withdropdown {
        width: 100%;
        position: relative;
    }
    .dropdownTrigger {
        background-color: transparent;
        display: flex;
        margin: 0;
        margin-bottom: 5px;
        margin-right: 7px;
        align-items: center;
        padding: 0;
    }
    .transition {
        right: -7px;
    }
    .withdropdownButton {
        &.black {
            color: #000;
        }
        &.grey {
            color: #767676;
        }
    }
    .no-label {
        color: #767676 !important;
    }
}

.ui.visible.dimmer {
    .InterventionModal {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
    }
}

.intervention-modal-title {
    font-size: 18px;
}

.new-attachment-container {
    margin-top: 13.5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    .attachment-view {
        padding: 0 2px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .attachment-name {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            max-width: 75%;
            p {
                font-size: 14px;
                color: #3175c6;
                margin: 0;
            }
            i.icon {
                margin-right: 0px;
                color: #414141 !important;
                font-size: 14px;
                height: auto;
            }
        }
        .attachment-delete {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            p {
                font-size: 12px;
                font-weight: bold;
                font-style: italic;
                color: #000000;
                margin: 0;
            }
            i.icon {
                cursor: pointer;
                margin-right: 0px;
                color: #414141 !important;
                font-size: 14px;
                height: auto;
            }
        }
    }
}