.LocationLicense-Navbar {
  #navbar-portal-container {
    padding-left: 30px;
    justify-content: flex-start;
  }
}
.LocationLicenseOverview {
  &-Navbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    flex: 1;

    @media (max-width: 1500px) {
      gap: 15px;
    }

    &-Item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      font-size: 13px;
      letter-spacing: 0px;
      span {
        font: normal normal 900 13px / 24px Lato;
        color: #000000cc;
      }

      &:last-child {
        flex: 1;
        min-width: 0;
        width: 35em;
      }
      .span-ellipsis {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
      }
    }
  }
  &-Header {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-Actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  }
  &-container {
    padding: 0 10px;
  }
  &-toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    border: 1px solid #e2e2e3;
    height: 36px;
    padding: 10px 20px;
    border-radius: 4px;
    opacity: 1;
    span {
      font: normal normal 14px / 24px Lato;
      letter-spacing: 0px;
      color: #000000;
    }
  }
  &-add-container {
    button {
      font: normal normal 600 14px/17px Lato;
      letter-spacing: 0px !important;
      color: #000000 !important;
    }
  }
  .MonthlyTable {
    table {
      border-radius: 6px !important;
      overflow: hidden;
    }

    tbody {
      td.td-code {
        padding: 0 !important;
      }
      td.td-actions {
        .subitem-delete {
          align-items: center;
          display: flex;
          justify-content: flex-end;
          padding-right: 17px;
          div {
            align-items: center;
            border: 1px solid #e2e2e3;
            border-radius: 4px;
            display: flex;
            height: 25px;
            justify-content: center;
            padding: 0 6px;
            text-align: center;
            i {
              margin: 0;
            }
          }
        }
      }
    }
  }
  .license-code {
    background: #d2f1da 0% 0% no-repeat padding-box;
    border: 1px solid #e2e2e3;
    color: #00c12d;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 40px;
  }
  .no-license-code {
    background: #ffd9d9 0% 0% no-repeat padding-box;
    border: 1px solid #e2e2e3;
    color: red;
    height: 36px;
    display: flex;
    max-width: 40px;
    align-items: center;
    justify-content: center;
  }

  .checkbox-automatic-licenses {
    transform: scale(0.7);
  }
}

.location-overview-header-tab {
  border: 1px solid #e6e6e6 !important;
  font-weight: bold !important;
}

.ui.wide.popup {
  white-space: nowrap;
  color: #414141;
}
