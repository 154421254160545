.PinItems {
    padding-top: 16px;
    .small-status {
        margin-right: 10px;
        padding: 0px !important;
        .bo-icon {
            padding-right: 0;
            gap: 0;
        }
    }
    .ui.button {
        i.icon {
            margin-left: -13px !important;
        }
    }
    .pin-status {
        border: 1px solid #e2e2e3;
        height: 25px;
        width: 32px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        i.icon {
            margin: -3px 0 0 0;
        }
    }
    .pin-type {
        padding-right: 0px;
        cursor: pointer;
        .label {
            height: 25px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            i.icon {
                margin: 0px;
            }
        }
        .icon-count-wrapper {
            padding-right: 0;
            gap: 0;
        }
    }
}