.RobSection {
    .segment_title{
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 14px;
        color: #000000CC;
        border: 0;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }

    .section_title {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 14px;
        color: #000000CC;
    }
    .data_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 7px;
        .title {
            font-weight: bold;
            font-size: 14px;
            color: #000000CC;
            flex: 1;
        }
        .value {
            font-size: 14px;
            flex: 1;
            text-align: right;
        }
    }
}