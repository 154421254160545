.Snooze {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  .owner-info {
    .owner-name {
      font-size: 14px;
      font-weight: 900;
      color: red;
    }
    .owner-title {
      color: #767676 !important;
      font-size: 12.6px;
    }
    .owner-email {
      color: #414141;
      font-size: 12.6px;
    }
  }
}
