.Snooze-Videos {
    display: flex;
    align-items: center;
    gap: 12px;
    overflow-x: auto;
    flex-wrap: wrap;
    .video-view {
        padding: 1px;
        border: 1px solid #e2e2e3;
        width: min-content;
        position: relative;
        margin-top: 20px;
        width: 231px;
        height: 130px;
        overflow: hidden;
        video {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        .video-controls {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            position: absolute;
            bottom: 10.5px;
            width: 100%;
        }
        .video-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 7px;
        }
        .video-action-button {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: #fff;
            border-radius: 4px;
            width: 33px;
            height: 25px;
            i.icon {
                margin: 0;
                height: auto;
            }
        }
    }
}