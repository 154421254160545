.CarNotes {
    .car-notes-header {
      width: 20%;
      display: flex;
  
      .car-notes-header-title {
        font-size: 18px;
        text-transform: capitalize;
        font-weight: 600;
  
        .car-notes-chevron-icon {
          font-size: 18px;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
  
    .car-notes-content > div:first-child {
      margin-top: 20px;
    }
  
    .car-notes-content {
      .receptionist-remarks-header {
        width: 20%;
        display: flex;
        margin-bottom: 10px;
  
        .receptionist-remarks-header-title {
          font-size: 14px;
          text-transform: capitalize;
          margin-right: 5px;
          font-weight: 600;
        }
      }
  
      .dms-remarks-header {
        width: 20%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
  
        .dms-remarks-header-title {
          font-size: 18px;
          text-transform: capitalize;
          margin-right: 5px;
          margin-left: 5px;
          font-weight: 600;
        }
      }
    }
  }
  