.AppointmentSpecialIndicators-container {
  display: flex;
  column-gap: 4px;
  justify-content: flex-end;
  .ui.red.label {
    background-color: #ff020233 !important;
    border-color: #ff0202 !important;
    color: #ff0202 !important;
  }
  .ui.green.label {
    background-color: #48913d33 !important;
    border-color: #48913d !important;
    color: #48913d !important;
  }
  .ui.orange.label {
    background-color: #ffba3933 !important;
    border-color: #ffba39 !important;
    color: #ffba39 !important;
  }
  .ui.blue.label {
    background-color: #2185d033 !important;
    border-color: #2185d0 !important;
    color: #2185d0 !important;
  }
  .FilterIcon-kiosk-label {
    height: 20px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    gap: 3px;
    background-color: #21ba45 !important;
    color: #fff !important;
  }
}
