.recharts-wrapper.TotalPieChart {
  width: 100%;
  height: 100%;
  margin: auto;
  .custom-label-inner-text {
    display: block;
    font-size: 23px;
    line-height: 23px;
    color: #000000cc;
  }

  .total-pie-chart-legend {
    display: grid;
    column-gap: 10px;
    list-style-type: square;
  }
}
