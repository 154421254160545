.ReactTable {
  border-spacing: 0px;
  border-top: 1px solid #e2e2e3;
  border-right: 1px solid #e2e2e3;
  border-left: 1px solid #e2e2e3;
  text-align: left;
  border-radius: 4px;
  width: 100%;
  thead {
    background-color: #f9fafb;
    padding: 0 10px;
    th {
      font-size: 12.6px;
    }
    tr {
      height: 36px;
    }
  }

  tbody {
    tr {
      height: 36px;
      &:hover {
        background-color: #f9fafb;
        cursor: pointer;
      }
    }
  }

  th {
    padding-left: 12px;
    border-bottom: 1px solid #e2e2e3;
  }

  td {
    border-bottom: 1px solid #e2e2e3 !important;
    padding-left: 12px;
    p {
      font-size: 12.6px;
    }
    .ellipsis {
      font-size: 12.6px;
      white-space: nowrap;
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ellipsis.medium {
      width: 150px;
    }
  }

  .no-results {
    padding: 25px 0px !important;
    text-align: center !important;
    color: #000000cc !important;
  }
}

.ReactTablePagination {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  bottom: 0;
  z-index: 1;
}
