/*******************************
         Site Overrides
*******************************/

.ui.modal {
    & > i.close.icon {
        top: 1.25rem;
        right: 1rem;
        color: #767676;
        font-size: 1.3em;
    }

    & > div.header {
        padding: 25px 0 24px 25px;
        font-size: 18px !important;
        display: flex;
        gap: 15.8px;
        align-items: center;
    }

    & > i.close.icon {
        font-size: 1.4em !important;
    }

    & > div.content {
        padding: 2rem;
    }

    & > div.actions {
        display: flex;
        justify-content: space-between;
        background-color: #FFFFFF;
        border-top: 1px solid #E2E2E3;
        padding: 18px 24px;

        .action-buttons > * {
            margin-left: 16px;
        }
    }
}