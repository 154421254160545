.GeneralInfo {
  .ui.buttons.withdropdown {
    background-color: #f9fafb;
    border-radius: 4px;
    border: 1px solid #dededf;

    .button {
      padding: 5px 10px 5px 5px;
      background-color: inherit;
    }

    .icon.button {
      margin-top: 5px;
    }

    .withdropdownButton {
      width: auto;

      &.black {
        color: #000;
      }

      &.grey {
        color: #767676;
      }
    }
  }

  .fields {
    margin: 0px !important;
    display: flex;
    justify-content: space-between;

    button {
      width: 100%;
    }
  }

  .pin.dropdown:hover {
    border-color: rgba(34, 36, 38, 0.35);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
  }

  .pin.dropdown {
    cursor: pointer;
    line-height: 1em;
    min-height: 2.7em;
    background: #ffffff;
    display: inline-block;
    padding: 0.78em 2.1em 0.78em 1em;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28rem;
    transition:
      box-shadow 0.1s ease,
      width 0.1s ease;
  }

  .no-label {
    color: #767676 !important;
  }

  .one.wide.field.mt-28.user-approved-icon {
    width: 38px !important;
    height: 38px !important;
    padding: 0px;
    margin: 0px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      height: 38px !important;
    }
  }

  .input-row-equal-spacing {
    .field {
      padding: 0 0 !important;
      margin-right: 10px !important;

      &:last-child {
        margin-right: 0px !important;
      }
    }
  }

  .label-dropdown-container {
    width: 58px !important;
  }
}
