.CarDrivingTrend {
  margin-top: 20px;
  .MileageChart-Wrapper {
    border: 1px solid #eee;
    padding: 0.75em;
    margin-bottom: 1em;

    .MileageChart {
      .MileageChart__Legend {
        margin-bottom: 1em;
        .MileageChart__Legend__Text {
          display: inline-block;
          margin-right: 1%;
          padding: 0.15em 0;
          text-align: center;
          width: 19%;
          &.thirtyKM {
            background-color: "rgba(135, 206, 235, 0.4)";
            border: "3px solid rgba(135, 206, 235, 1)";
          }
          &.thirtyFiftyKM {
            background-color: "rgba(175,196,131,0.4)";
            border: "3px solid rgba(175,196,131,1)";
          }
          &.fiftySeventyKM {
            background-color: "rgba(252,162,78,0.4)";
            border: "3px solid rgba(252,162,78,1)";
          }
          &.seventyHunderdKM {
            background-color: "rgba(224,126,96,0.4)";
            border: "3px solid rgba(224,126,96,1)";
          }
          &.hunderdKM {
            background-color: "rgba(191,59,59,0.4)";
            border: "3px solid rgba(191,59,59,1)";
          }
        }
      }
    }
  }
}
