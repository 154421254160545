.UploadImage {
  .preview-btn {
    margin-left: 5px;
  }
}
.upload-container {
  .ui.input {
    position: absolute;
  }
  .UploadImage.new {
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #dededf;
    .button {
      height: 36px;
      background-color: #e0e1e2;
      display: flex;
      align-items: center;
      padding: 0 15px;
      cursor: pointer;

      .btn-text {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .fileInput-ellipsis {
      margin-left: 16px;
      font-size: 14px;
      white-space: nowrap;
      max-width: 400px;
      min-width: 178px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .upload-view {
    margin-top: 24px;
    width: 300px;
    height: 240px;
    background-color: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .delete-container {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #e2e2e3;
      width: 35px;
      height: 25px;
      position: absolute;
      bottom: 1px;
      right: 1px;
      i.icon {
        margin: -10px 0 0 0;
      }
    }
  }

  .ui.input > input {
    display: none;
  }
}
