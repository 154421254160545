.DealerLocationDropdown-wrapper {
  display: flex;

  .labeled.icon.button {
    padding-left: 2.5em !important;

    i {
      font-size: 15px;
      margin-left: 10px !important;
      margin-top: 2px !important;
    }
  }

  .location-placeholder-icon {
    position: absolute;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .LocationDropdown {
    margin: 0 10px;
    background-color: transparent !important;
    text-align: left;
    box-shadow: none !important;

    .active {
      background-color: #f7f7f7 !important;
      font-weight: 600 !important;
    }

    input {
      padding-top: 5px !important;
    }

    .locationItem {
      .icon {
        background-color: transparent !important;
        font-size: 13px !important;
      }

      span {
        font-size: 12.6px;
        font-weight: 400;
        color: #414141;
      }
    }
  }
}

.last-location-item {
  border-bottom: 1px solid #d9d9da !important;
}

.dealer-dropdown-option {
  font-weight: 600 !important;
}
