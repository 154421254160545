.container-styleguide {
  padding: 10px;
}
.buttons-styleguide {
  margin-top: 10px;
}
.outline-buttons,
.default-buttons {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
  a {
    margin-right: 5px;
  }
}

.default-buttons.nogap {
  gap: 0;
}
