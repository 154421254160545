.CopyContent {
  &.container {
    align-items: "center";
    display: flex;
    flex-direction: row;
  }

  &.icon {
    cursor: pointer;
    color: #21ba45;
    margin-right: 5px;
  }
}