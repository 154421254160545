.Logs {
  p {
    margin: 0;
  }
  table {
    tbody {
      tr {
        td {
          vertical-align: baseline;
          position: relative;
          div {
            position: absolute;
            top: 7px;
          }
          .description {
            position: unset;
            margin: 7px 0;
            width: 500px;
            word-wrap: break-word;
          }
        }
      }
    }
  }
  .actions {
    justify-content: flex-end !important;
  }
  .car-check {
    display: flex;
    align-items: flex-end;
    gap: 10px;
  }
  .attachments-list {
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .Logs-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 25px !important;
    .ui.small.button {
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 18px;
      i.icon {
        margin: 0px 0px 0px 0px !important;
        padding: 0px;
        font-size: 16px;
      }
    }
    .ui.small.button.-appointment-status {
      width: 36px;
      height: 36px;
    }
  }
}

.log-modal-title {
  font-size: 18px;
}
