/*******************************
         Site Overrides
*******************************/

.field > label {
    font-size: 14px !important;
    font-weight: 700 !important;
}

.field > label.big {
    font-size: 15px !important;
}

.field > label.small {
    font-size: 12px !important;
}

.ui.form textarea {
    font-family: "Lato";
}