.NewAppointment {
  .ml-0 {
    margin-left: 0px !important;
  }

  .ui.basic.button {
    margin-right: 20px;
    width: 38px;
    i {
      color: #21ba45;
    }
  }

  button.button {
    height: 36px;
    color: #000000;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin-left: 22px;

    i.icon {
      font-size: 14px;
      font-weight: 600;
    }
  }

  button.ui.green.button.-appointment-statu.left-icon.mt-14.rowButton {
    margin-left: 0px;
    font-size: 14px;
    font-weight: 300;
    height: 37.5px;

    i {
      font-size: 14px;
      transform: translateX(15px) !important;
    }
  }

  div.content {
    height: calc(100vh * 0.8) !important;
    overflow-y: scroll;
  }

  label.big {
    font-size: 19px !important;
    font-weight: 400 !important;

    i.icon {
      font-size: 19px;
      margin-right: 5px;
    }
  }

  .action-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  .ui.form .fields {
    .react-datepicker-wrapper {
      width: 100%;
    }

    .rc-time-picker {
      width: 100%;

      input {
        height: 36px;
      }
    }

    .dropdown-search {
      width: 33.33% !important;
    }

    .search {
      display: flex;
      width: 100%;
      align-items: center;

      button {
        margin-top: 0px !important;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        height: 38px;
        padding: 0 12.3px;
        flex: none;
        font-size: 14px;
        font-weight: 600;

        i.icon {
          opacity: 1;
        }
      }

      input {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      &.rounded {
        input {
          border-radius: 4px;
        }
      }
    }

    .wide.field:last-child {
      padding-right: 12px;
    }

    .car-dropdown {
      background-color: transparent !important;
      text-align: left;
      box-shadow: none !important;
      border: 1px solid #dededf;
      border-radius: 4px;
      height: 36px;
      min-width: 300px;

      &.active {
        background-color: #fff !important;
        border: 1px solid #3498db !important;
      }

      .icon {
        background-color: transparent !important;
        font-size: 13px !important;
      }

      .text {
        font-size: 12.6px;
        font-weight: 400 !important;
        color: #414141;
      }
    }

    .intervention-actions {
      width: 70px !important;
    }
  }

  .results.transition.visible {
    max-height: 300px;
    height: auto;
    overflow-y: scroll;
  }
}
