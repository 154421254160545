/*******************************
         Site Overrides
*******************************/

th {
    font-size: 12.6px;
    line-height: 24px;
    font-weight: 700;
}

tr, tr td {
    font-size: 12.6px;
    line-height: 17px;
    font-weight: 400;
     p {
      font-size: 12.6px;
    }
}