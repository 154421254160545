.Activitylog-filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
    label {
        font-family: "Lato" !important;
        font-size: 13px;
        letter-spacing: 0px;
        font-weight: bold;
        color: #000000;
    }
    .ui.selection.dropdown {
        min-width: 124px;
    }
}