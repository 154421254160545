.AppointmentDetails {
    &-AdvisedCriticalItemsHistory {
        &-AdvisedCriticalItemsTable {
            .ReactTable {
                thead {
                    th:last-child {
                        padding-right: 14px;
                        text-align: right;
                    }
                }
                .td-action {
                    padding-right: 14px;
                }
                .header-action-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
                .hidden-items-button {
                    display: flex;
                    cursor: pointer;
                    justify-content: center;
                    align-items: center;
                    width: 32px;
                    height: 25px;
                    border-radius: 4px;
                    background-color: #ffffff;
                    border: 1px solid #e2e2e3;
                    color: white;
                    i {
                        height: auto;
                        margin: 0;
                    }
                }
                td {
                    &.text-center {
                        text-align: center;
                        display: table-cell !important;
                    }
                }
            }
            .ReactTablePagination {
                justify-content: flex-end;
                .pagination-info {
                    display: none;
                }
            }
        }
    }
}