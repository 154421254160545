.ErrorFallback {
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  position: absolute;
  left: 0;
  &-overlay {
    background: #134e5e;
    background: -webkit-linear-gradient(to right, rgb(113, 178, 128), rgb(19, 78, 94));
    background: linear-gradient(to right, rgb(113, 178, 128), rgb(19, 78, 94));
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
  }
  &-container {
    max-width: 790px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
  &-car-repair {
    position: relative;
    img {
      width: 100%;
    }
  }
  &-svg-icon {
    position: absolute;
    right: 0;
    bottom: 15px;
    box-shadow: 0px 8px 10px #2F2C36;
    max-height: 93px;
  }
  &-error-text {
    position: absolute;
    right: 0;
    bottom: -8px;
    padding: 8px 30px;
    background: #000000;
    border-radius: 5px;
    font: normal normal bold 21px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
    box-shadow: 8px 8px 10px #2F2C36;
  }
  &-action-buttons {
    margin-top: 25px;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    .ui.button {
      height: 63px;
      font: normal normal 600 30px / 16px Lato;
      letter-spacing: 0px;
      opacity: 1;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      &.icon.labeled {
        background-color: #1DC05F;
        color: #FFFFFF;
        &:hover {
          background-color: #1DC05F;
        }
        i {
          background-color: #46B245 !important;
          color: #ffffff !important;
        }
      }
      &.light {
        color: #1EC05F;
        padding: 0 50px;
      }
    }
  }
  &-logo {
    h1 {
      color: #fff;
      font-family: Arial;
      font-size: 118px;
      font-weight: 700;
      letter-spacing: -5.9px;
      margin: 0;
      span {
        color: #1fbf5f;
      }
    }
    h3 {
      color: #fff;
      font-family: Arial;
      font-size: 35px;
      font-weight: 300;
      letter-spacing: -1.75px;
      margin: 0;
      padding: 35px 0 0 8px;
    }

  }
}
