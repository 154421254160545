.Manufacturer {
  &__filters-container {
    padding: 0 10px;
    margin: 10px 0 22px 0;
    display: grid;
    gap: 11px;
    align-items: start;

    .limegreen-color {
      color: #21ba45;
    }

    .status-filters-wrapper {
      align-self: center;
    }

    &--addButton {
      border: 1px solid #e2e2e3 !important;
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px;
      padding-right: 12px;

      i {
        color: #21ba45;
        padding: 0 20px;
        border-right: 1px solid #70707029;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        font: normal normal 600 14px/17px Lato;
        margin: 0 auto;
        margin-left: 8px;
      }
    }

    &-4 {
      grid-template-columns: repeat(2, minmax(220px, 1fr)) minmax(210px, 1fr) 1fr 40px;
    }

    &-5 {
      grid-template-columns: max-content repeat(2, minmax(220px, 1fr)) minmax(210px, 1fr) 1fr 40px;
    }
  }
}
