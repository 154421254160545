.TyreMotionReport {
  &__chart {
    width: 100%;
    height: 400px;
    margin: 20px 0;
  }

  &__tooltip {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &-label {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
      color: #333;
    }

    &-row {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-content {
      font-size: 13px;
      line-height: 1.4;
    }

    &-location {
      font-weight: 500;
      margin-right: 8px;
    }

    &-value {
      margin-right: 12px;
      color: #666;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__label-text {
    fill: #666;
    font-size: 11px;
  }
}
