.Manufacturer {
  &__RequestModal {
    &-actions {
      display: flex;
      justify-content: flex-end !important;

      &--approve {
        background-color: #ffba39 !important;
        color: #fff !important;
      }
    }

    &-header {
      background-color: #ffba39 !important;
      color: #fff !important;
    }

    &-content {
      display: flex !important;
      gap: 4px;
      flex-direction: column !important;

      &--textarea {
        padding: 8px 12px;
        border: 1px solid #dededf;
        border-radius: 4px;
        outline: none;
        resize: vertical;
        margin-top: 10pxs;
      }
    }
  }
}
