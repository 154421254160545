@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
@import "./colorbox.scss";

#zohohc-asap-web-button {
  left: -100px;
}

body {
  height: 100dvh;
  overflow: hidden;
  margin: 0;
  font-family:
    "Lato",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
  font-size: 14px;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  transition: 0.15s opacity;

  &:hover {
    text-decoration: none;
    color: inherit;
    opacity: 0.65;
  }
}

.Loader {
  color: #767676 !important;

  &::before {
    border-color: rgba(0, 0, 0, 0.15) !important;
  }

  &::after {
    border-color: #767676 transparent transparent !important;
  }
}

p.small {
  font-size: 12.6px;
}

button.ui.button {
  &:disabled {
    background-color: #e2e2e3 !important;
    color: #767676;
  }
}

button.ui.left-icon {
  i.icon {
    margin-right: 20px !important;
    margin-left: -15px !important;
  }
}

.afBrownBg {
  background-color: $brown-bg !important;
  &:hover {
    background-color: $light-brown-bg !important;
  }
}
.afPinkBg {
  background-color: $pink-bg !important;
  &:hover {
    background-color: $light-pink-bg !important;
  }
}
.afTomatoBg {
  background-color: $tomato-bg !important;
  &:hover {
    background-color: $light-tomato-bg !important;
  }
}
.afOrangeBg {
  background-color: $orange-bg !important;
  &:hover {
    background-color: $light-orange-bg !important;
  }
}
.afYellowBg {
  background-color: $yellow-bg !important;
  &:hover {
    background-color: $light-yellow-bg !important;
  }
}
.afLemonBg {
  background-color: $lemon-bg !important;
  &:hover {
    background-color: $light-lemon-bg !important;
  }
}
.afGreenBg {
  background-color: $green-bg !important;
  &:hover {
    background-color: $light-green-bg !important;
  }
}
.afGrassBg {
  background-color: $grass-bg !important;
  &:hover {
    background-color: $light-grass-bg !important;
  }
}
.afSpaceBg {
  background-color: $space-bg !important;
  &:hover {
    background-color: $light-space-bg !important;
  }
}
.afBlueBg {
  background-color: $blue-bg !important;
  &:hover {
    background-color: $light-blue-bg !important;
  }
}
.afGreyBg {
  background-color: $grey-bg !important;
  &:hover {
    background-color: $light-grey-bg !important;
  }
}
.afPurpleBg {
  background-color: $purple-bg !important;
  &:hover {
    background-color: $light-purple-bg !important;
  }
}

.tiGreenBg {
  color: $limegreen-bg !important;
  background-color: $light-limegreen-bg !important;
  border: 1px solid $limegreen-bg !important;
}
.tiGreenBg.off {
  color: $grey !important;
  background-color: #fff !important;
  border-color: $light-grey !important;
}

.tiBlueBg {
  color: $blue-bg !important;
  background-color: $light-blue-bg !important;
  border: 1px solid $blue-bg !important;
}
.tiBlueBg.off {
  color: $grey !important;
  background-color: #fff !important;
  border-color: $light-grey !important;
}

.tiRedBg {
  color: $red-bg !important;
  background-color: $light-red-bg !important;
  border: 1px solid $red-bg !important;
}
.tiRedBg.off {
  color: $grey !important;
  background-color: #fff !important;
  border-color: $light-grey !important;
}

.tiOrangeBg {
  color: $mango-bg !important;
  background-color: $light-mango-bg !important;
  border: 1px solid $mango-bg !important;
}
.tiOrangeBg.off {
  color: $grey !important;
  background-color: #fff !important;
  border-color: $light-grey !important;
}

.wdSkyblue {
  background-color: $skyblue !important;
}

.wdRed {
  background-color: $red-bg !important;
}

.wdLightGrey {
  background-color: #f9fafb !important;
}

.centerIcon {
  i.icon {
    margin-left: -10px !important;
  }
}

.greyBorder {
  border: 1px solid #dededf !important;
}

.tiLightPurple {
  color: $purple-bg !important;
  background-color: #e7d0e6 !important;
}

.i-maroon {
  color: #db2828;
}

.i-grey {
  color: #767676;
}

.i-yellow {
  color: $mango-bg;
}

.i-blue {
  color: $skyblue;
}

.i-green {
  color: $limegreen-bg;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis.small {
  font-size: 12.6px;
  width: 80px;
}

.refresh-button {
  height: 100%;
  border-left: 1px solid #e2e2e3;
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px !important;
}

.mt-28 {
  margin-top: 28px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-14 {
  margin-top: 14px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-8 {
  margin-top: 8px !important;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-25 {
  margin-bottom: 25px;
}

.rotate-270 {
  transform: rotate(270deg);
}

.position-relative {
  position: relative;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.icon-container {
  margin: 0 0.25em 0 0;
  width: 1.18em;
  width: 1em;
}

.ui.dropdown > .clear.icon:before {
  content: "\f00d";
}

.ui.checkbox.check {
  input.hidden {
    position: initial;
    opacity: 1 !important;
  }
  label {
    display: none;
  }
}

.location-info-popup {
  margin-top: 10px;
  p {
    margin: 0px;
  }
}

.keylocker-icon {
  background-color: #ecd6f4 !important;
  i.icon {
    color: #a333c8;
  }
}

.no-wrap-text {
  white-space: nowrap;
}

.-cursor-pointer {
  cursor: pointer;
}

.button.ui.button.-modal-x-button {
  position: absolute;
  right: 30px;
  background-color: #ffffff;
  border: 1px solid #e2e2e3;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;

  i.icon {
    position: relative;
    bottom: 1px;
    right: 1px;
  }
}

.no-size {
  height: 0;
  width: 0;
}

.bold-text {
  font-weight: bold !important;
}
