.InterventionQuickView-content {
  max-height: 300px;
  overflow-y: auto;
  width: 700px;
  max-width: 700px;
}

.InterventionQuickView-interventions-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #d4d4d5;

  .title-container {
    width: 80%;
    display: flex;

    .title {
      width: 100%;
      font-size: 13px;
      display: inline-block;
      word-wrap: break-word;
      word-break: break-all;
    }
  }

  .special-indicators-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0px;
    padding: 0px;

    .labor-minutes {
      margin-right: 25px;
      font-style: italic;

      i.icon {
        position: relative;
        bottom: 1px;
        margin-right: 5px;
        font-size: 9.5px;
      }
    }
  }

  .AnswerReadyStatus {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 5px;
  }
}

.InterventionQuickView-interventions-container:last-child {
  border-bottom: none;
}

.mechanic-fixed-status-container {
  position: relative;

  .wrench-check-icon {
    font-size: 8px;
    position: absolute;
    right: 0px;
    bottom: 3px;
  }
}
