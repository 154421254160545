.SearchDropdown-wrapper {
  position: absolute;
  top: 35px;
  background: #fff;
  border: 1px solid #e2e2e3;
  width: 700px;
  border-radius: 4px;
  max-height: 250px;
  min-height: auto;
  overflow-y: auto;
  left: 50%;
  transform: translate(-50%);
  top: 35px;

  p {
    font-size: 12.6px;
    margin: 0;
  }

  .StatusCellIcon {
    height: 25px;
    i.icon {
      margin-top: -16px !important;
      font-size: 10px !important;
      &.small-icon {
        top: 22px !important;
        font-size: 8px !important;
      }
      &.top-right {
        top: 9px;
      }
    }
  }

  .no-result {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    p {
      font-size: 14px;
    }
  }
  .search-list {
    font-size: 12.6px;
    padding: 0 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      background-color: #f9fafb;
    }

    .search-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: auto;
    }

    .search-row {
      display: flex;
      align-items: center;
      gap: 10px;

      i.icon {
        margin-top: -10px;
      }
    }
  }
}
