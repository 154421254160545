.StandardList-title {
  display: flex;
  align-items: center;

  .mechanic-info {
    margin-left: 6px;
    display: flex;
    align-items: center;
    font-size: 18px;
    text-transform: capitalize;
    white-space: nowrap;

    .separator {
      margin-left: 7px;
      margin-right: 3px;
    }

    .first-date {
      margin: 0px 3px;
    }

    .second-date {
      margin-left: 2px;
    }

    i.icon {
      font-size: 12px;
      margin-top: -10px;
      margin-left: 3px;
    }
  }
}
