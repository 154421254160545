.ImportantNotesContainer {
  .container {
    background-color: #fcfff5;
    padding: 5px;
    border: 1px solid #e9e9ea;
    border-radius: 4px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
    gap: 1px;
  }

  .note-content {
    color: #000000cc;
    margin-left: 3px;
  }

  .label-text {
    color: #5c5c5c;
  }

  .keep-parts-icon {
    margin-right: 1px !important;
  }
}
