.WarrantyLocationInfo {
  &__location-info-card-container {
    display: flex;
    justify-content: flex-end;

    i.icon {
      margin: auto;
      margin-top: 4px;
      color: #801280;
    }
  }

  &__location-info-card-user {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 25px;
    border-radius: 4px;
    background-color: #e6d0e6;
    color: white;
  }

  &__location-info-card-no-box-shadow {
    box-shadow: none !important;
  }
}
