.DealerDocumentationReadingModal {
  .modal-first-row {
    display: flex;
    gap: 13px;
    align-items: center;
    margin-bottom: 15px;
  }

  .img-name-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;

    .publisher-initials {
      color: #ffff;
      padding: 10px;
      font-weight: 600;
      border-radius: 100px;
      background-color: #dadfe9;
    }

    .profilePic {
      max-height: 40px !important;
      object-fit: cover !important;
      max-width: 40px !important;
      width: 40px !important;
      height: 40px !important;
      border-radius: 100px;
    }
  }

  .dealer-modal-content {
    margin-top: 30px;
    padding: 12px;
    border: 1px solid rgba(222, 222, 223, 0.54);

    img {
      width: 100%;
    }
  }

  .exitModal {
    margin-left: auto;
    padding: relative;
    position: relative;
    margin-right: 25px;
    padding: 20px;
    background-color: transparent;
    border: 1px solid #e2e2e3;
    border-radius: 6px;
    cursor: pointer;

    i {
      position: absolute;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  .dealer-modal-header {
    display: flex;
    justify-content: space-between;
    button {
      margin-right: 25px !important;
    }
  }

  .document-info {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .category-info {
    padding: 2px;
  }

  .category-name-dealer {
    background: none !important;
    border: 1px solid #e2e2e3 !important;
    color: black !important;
    font-weight: normal !important;
  }
}
