.pagination-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  .pagination-controls {
    display: flex;
    align-items: center;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    overflow: hidden;

    button {
      padding: 0.25rem 0.75rem;
      border: none;
      background: none;
      cursor: pointer;
      font-size: 0.875rem;
      color: #374151;
      transition: background-color 0.2s;
      min-width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(:last-child) {
        border-right: 1px solid #e5e7eb;
      }

      &:hover:not(:disabled) {
        background-color: #f9fafb;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &.active {
        background-color: #f3f4f6;
        font-weight: 500;
      }

      &.nav-button {
        font-size: 1.25rem;
        line-height: 1;
        padding: 0.25rem 0.75rem;
      }
    }
  }

  .pagination-info {
    color: #6b7280;
    font-size: 0.875rem;
  }
}
