.Login__gate__container {
  margin-top: 150px !important;
  .Login__gate__container__inner {
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    background: #fff;
    width: 525px;
    h1 {
      color: #646464;
      font-size: 1.8em;
      font-weight: 300;
      text-align: center;
      span {
        color: #22ba45;
        font-weight: 500;
      }
    }
    .Login__gate__container__inner__actions {
      display: flex;
      justify-content: space-between;
      padding-left: 40px;
      padding-right: 40px;
      h2 {
        color: #4b4b4b;
        font-weight: 300;
        font-size: 1.5em;
      }
      input {
        &:focus {
          border-color: #7ab47a;
        }
      }
    }
    .Login__gate__container__form {
      padding: 0 30px 30px 30px;
    }
  }
}

.Login__language_container {
  padding: 0 50px 50px 50px;
}

.google-login {
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 3rem;
  background-size: 1.5em;
  border: 1px solid #cacaca;
  color: #000;
  padding: 1em;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #fcfcfc;
  }
}

.reset-password-container {
  text-align: right;

  a {
    display: inline !important;
  }
}

.warning-message {
  background: #ffffef 0% 0% no-repeat padding-box;
  border: 1px solid #a5673f;
  border-radius: 4px;
  padding: 12px;
  p {
    font: normal normal normal 14px Lato;
    letter-spacing: 0px;
    color: #a5673f;
  }
}
