.CopyToClipboard {
  &__container {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    min-width: 0;
  }

  &__icon {
    cursor: pointer;
    color: #21ba45;
    opacity: 0;
    display: none;
    transition: opacity 0.2s ease;

    &.visible {
      opacity: 1;
      display: inline-block;
    }
  }

  &__content {
    word-break: break-all;
    max-width: 100%;
    max-height: inherit;
  }
}
