.ImageGallery {
  .images-container {
    margin-top: 1rem;
    display: table;
  }

  .images-container .image-container-row {
    display: table-row;
    line-height: 0 !important;
  }

  .images-container .image-container,
  .images-container .image-container-row {
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
  }

  .images-container .image-container {
    width: 25% !important;
    display: table-cell;
    position: relative;
    padding-right: 3px;
  }

  .images-container .image-container .img-label {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 3px;
    width: 60px;
    height: 35px;
    color: #234457;
    background-color: hsla(0, 0%, 100%, 0.8);
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
  }

  .images-container .img-label .img-icon-wrapper {
    height: 27px;
    color: #5d7c8c;
    border: 2px solid #5d7c8c;
    border-radius: 2px;
    padding: 2px 5px;
    display: flex;
    gap: 5px;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: monospace;
  }

  .images-container .report-car-images-letter {
    font-weight: 700;
    line-height: 19px;
    display: inline-block;
    vertical-align: top;
  }

  .images-container .image-container .image-style {
    width: 100% !important;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
    height: 124px;
    border-bottom: 3px solid #fff;
  }
}
