.note-container {
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column;
  gap: 10px;
  display: flex;

  li {
    display: flex;
    align-items: stretch;
    gap: 10px;
    .input-wrapper {
      flex-grow: 1 !important;
      display: flex;
      input {
        width: 100%;
        border: none;
        outline: none;
        border: 1px solid #dededf;
        border-right: none;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: 10px;
      }
    }
  }
}

.drag-note-suggestion {
  border: none;
  outline: none;
  padding: 10px;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #dededf;
  display: flex;
  align-items: center;
  justify-content: center;
}
