/*******************************
         Site Overrides
*******************************/

button.ui.button {
    font-size: 14px;
    font-weight: 500;
    font-family: Lato;
    letter-spacing: 1px;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 0 17px;
    height: 36px;
    margin: 0;
}

button.ui.button.af {
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    margin-left: -12px !important;
  }
  i.icon {
    opacity: 1;
  }
}

.ui.basic.button {
    font-size: 14px;
    font-weight: 600;
    font-family: Lato;
}

.ui.large.button {
    height: 42px;
    border: none;
    font-size: 20px;
    font-weight: 600;
}
.ui.large.basic.button {
    height: 42px;
}

.ui.tiny.button {
    height: 32px;
    border: none;
    font-size: 14px;
    font-weight: 600;
}
.ui.tiny.basic.button {
    height: 32px;
}

button.ui.button:not(.icon) i.icon {
    transform: translateX(10px);
}

button.ui.icon.labeled.button {
    color: #414141;
}

button.ui.icon.labeled.button.disabled {
    background-color: #F9FAFB8A !important;
}

button.ui.icon.grey.labeled.button.disabled {
    background-color: #E0E1E2 !important;
}

/*-------------------
    Appointment status buttons
--------------------*/

button.ui.button.-appointment-status {
    background-color: @lightButton;
    border: 1px solid #E2E2E3;
    color: @black;
    gap: 5px;
    display: flex;
    align-items: center;
    padding: 0 12px;
}

button.ui.button.-appointment-status i.icon {
    margin: 0px;
    transform: translateX(0px);
    color: @greenButton;
}

button.ui.button.-appointment-status i.icon {
    margin: 0px;
    color: @greenButton;
}

button.ui.button.-appointment-status:hover {
    background-color: @lightButtonHover;
}

button.ui.button.-appointment-status:hover i.icon {
    color: @greenButtonHover;
}

button.ui.button.-appointment-status.active {
    background-color: @greenButton;
    color: @white;
    border-color: @greenButton;
}

button.ui.button.-appointment-status.active i.icon {
    color: @white !important;
}


button.ui.button.secondary {
    border: 1px solid #20BF5E;
    background-color: white;
    color: #20BF5E;
}

button.ui.button.secondary:hover {
    background-color: #1DBF5E;
}

/*-------------------
    Labeled buttons
--------------------*/

button.ui.icon.labeled.button, button.ui.icon.labeled.button i.icon {
    transition: 0.2s all ease-in-out
}

button.ui.icon.labeled.button {
    background-color: @lightButton;
    border: 1px solid #70707029;
}

button.ui.icon.labeled.button i.icon {
    background-color: @lightButton;
    border-right: 1px solid #70707029;
    color: @greenButton;
}

button.ui.icon.labeled.button:hover {
    background-color: @lightButtonHover;
}

button.ui.icon.labeled.button:hover i.icon {
    background-color: @lightButtonHover;
    color: @greenButtonHover;
}

button.ui.icon.labeled.button:active {
    background-color: @lightButtonActive;
}

button.ui.icon.labeled.button:active i.icon {
    background-color: @lightButtonActive;
    color: @greenButtonActive;
}

button.ui.icon.labeled.button.disabled {
    background-color: @lightButtonDisabled;
}

button.ui.icon.labeled.button.disabled i.icon {
    background-color: @lightButtonDisabled;
    color: @greenButtonDisabled;
}


button.ui.icon.grey.labeled.button {
    background-color: #E0E1E2;
    border: 1px solid #E0E1E2;
    color: @black;
}

button.ui.icon.grey.labeled.button i.icon {
    background-color: #D6D7D8;
    border-color: #D6D7D8;
}

button.ui.icon.grey.labeled.button:hover {
    background-color: #C9CACB;
    border: 1px solid #C9CACB;
}

button.ui.icon.grey.labeled.button:hover i.icon {
    background-color: #C0C1C2;
    border-color: #C0C1C2;
}

button.ui.icon.grey.labeled.button:active {
    background-color: @greyButtonActive;
}

button.ui.icon.grey.labeled.button:active i.icon {
    background-color: @greyButtonActive;
    color: @greenButtonActive;
}

button.ui.icon.grey.labeled.button.disabled {
    background-color: @greyButtonDisabled;
}

button.ui.icon.grey.labeled.button.disabled i.icon {
    background-color: @greyButtonDisabled;
    color: @greenButtonDisabled;
}

/*-------------------
    Colored buttons
--------------------*/
button.ui.button.green {
    background-color: @greenButton;
}

button.ui.button.green:hover {
    background-color: @greenButtonHover;
}

button.ui.button.green:active {
    background-color: @greenButtonActive;
}

button.ui.button.green.disabled {
    background-color: @greenButtonDisabled;
}

button.ui.button.secondary.green {
    background-color: white;
    border: none;
    color: @greenButton;
}
button.ui.button.secondary.green:hover {
    background-color: @greyBg;
    color: @greenButton;
}
button.ui.button.secondary.green:active {
    background-color: white;
    color: @greenButtonActive;
}

button.ui.button.blue {
    background-color: @blueButton;
}

button.ui.button.blue:hover {
    background-color: @blueButtonHover;
}

button.ui.button.blue:active {
    background-color: @blueButtonActive;
}

button.ui.button.blue.disabled {
    background-color: @blueButtonDisabled;
}

button.ui.button.secondary.blue {
    background-color: white;
    border: none;
    color: @blueButton;
}
button.ui.button.secondary.blue:hover {
    background-color: @greyBg;
    color: @blueButton;
}
button.ui.button.secondary.blue:active {
    background-color: white;
    color: @blueButtonActive;
}


button.ui.button.red {
    background-color: @redButton;
}

button.ui.button.red:hover {
    background-color: @redButtonHover;
}

button.ui.button.red:active {
    background-color: @redButtonActive;
}

button.ui.button.red.disabled {
    background-color: @redButtonDisabled;
}

button.ui.button.secondary.red {
    background-color: white;
    border: none;
    color: @redButton;
}
button.ui.button.secondary.red:hover {
    background-color: @greyBg;
    color: @redButton;
}
button.ui.button.secondary.red:active {
    background-color: white;
    color: @redButtonActive;
}


button.ui.button.orange {
    background-color: @orangeButton;
}

button.ui.button.orange:hover {
    background-color: @orangeButtonHover;
}

button.ui.button.orange:active {
    background-color: @orangeButtonActive;
}

button.ui.button.orange.disabled {
    background-color: @orangeButtonDisabled;
}

button.ui.button.secondary.orange {
    background-color: white;
    border: none;
    color: @orangeButton;
}
button.ui.button.secondary.orange:hover {
    background-color: @greyBg;
    color: @orangeButton;
}
button.ui.button.secondary.orange:active {
    background-color: white;
    color: @orangeButtonActive;
}

button.ui.button.aquamarine {
    background-color: @aquamarineButton;
    color: @white;
}

button.ui.button.aquamarine:hover {
    background-color: @aquamarineButtonHover;
}

button.ui.button.aquamarine:active {
    background-color: @aquamarineButtonActive;
}

button.ui.button.aquamarine.disabled {
    background-color: @aquamarineButtonDisabled;
}
button.ui.button.secondary.aquamarine {
    background-color: white;
    border: none;
    color: @aquamarineButton;
}
button.ui.button.secondary.aquamarine:hover {
    background-color: @greyBg;
    color: @aquamarineButton;
}
button.ui.button.secondary.aquamarine:active {
    background-color: white;
    color: @aquamarineButtonActive;
}


button.ui.button.black {
    background-color: @blackButton;
}

button.ui.button.black:hover {
    background-color: @blackButtonHover;
}

button.ui.button.black:active {
    background-color: @blackButtonActive;
}

button.ui.button.black.disabled {
    background-color: @blackButtonDisabled;
}

button.ui.button.secondary.black {
    background-color: white;
    border: none;
    color: @blackButton;
}
button.ui.button.secondary.black:hover {
    background-color: @greyBg;
    color: @blackButton;
}
button.ui.button.secondary.black:active {
    background-color: white;
    color: @blackButtonActive;
}


button.ui.button.light {
    background-color: @lightButton;
    border: 1px solid @iconButtonDisabled;
    color: @black
}

button.ui.button.light:hover {
    background-color: @lightButtonHover;
}

button.ui.button.light:active {
    background-color: @lightButtonActive;
}

button.ui.button.light.disabled {
    background-color: @lightButtonDisabled;
}

button.ui.button.secondary.light {
    background-color: white;
    border: none;
    color: @lightButton;
}
button.ui.button.secondary.light:hover {
    background-color: @greyBg;
    color: @lightButton;
}
button.ui.button.secondary.light:active {
    background-color: white;
    color: @lightButtonActive;
}


button.ui.button.grey {
    background-color: @greyButton;
    color: @black;
}

button.ui.button.grey:hover {
    background-color: @greyButtonHover;
}

button.ui.button.grey:active {
    background-color: @greyButtonActive;
}

button.ui.button.grey.disabled {
    background-color: @greyButtonDisabled;
}

button.ui.button.secondary.grey {
    background-color: white;
    border: none;
    color: @greyButton;
}
button.ui.button.secondary.grey:hover {
    background-color: @greyBg;
    color: @greyButton;
}
button.ui.button.secondary.grey:active {
    background-color: white;
    color: @greyButtonActive;
}


.ui.icon.button {
    padding: 8px;
}

.ui.icon.right.labeled.button {
    padding: 10px;
}

.ui.icon.left.labeled.button {
    padding: 10px;
}

.ui.circular.icon.button {
    border-radius: 50% !important;
}

.ui.basic.button.light {
    color: @black !important;
    border: none;
}