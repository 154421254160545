/*******************************
         Site Overrides
*******************************/


.ui.form .fields {
    align-items: center;
}

.ui.form .fields .wide.field:first-child {
    padding-left: 0;
}

.ui.form .fields .wide.field{
    padding-right: 12px;
    padding-left: 12px;
}

.ui.form .fields .wide.field:last-child {
    padding-right: 0;
}