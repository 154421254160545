.Manufacturer {
  &__ConfirmModal {
    &-header {
      background-color: #ffba39 !important;
      color: #ffff !important;
    }

    &-content {
      display: flex !important;
      flex-direction: column;

      span {
        margin-bottom: 10px;
        font: normal normal bold 14px/24px Lato;
      }

      textarea {
        padding: 8px 12px;
        border: 1px solid #dededf;
        border-radius: 4px;
        outline: none;
        resize: vertical;
      }
    }

    &-actions {
      justify-content: flex-end !important;
      &--confirmBtn {
        color: #fff !important;
        background-color: #ffba39 !important;
      }
    }
  }
}
