.LoginModal {
    max-width: 403px;
    .dropdown {
        min-height: 36px;
        padding: 0 2.1em 0 1em;
        display: flex;
        align-items: center;
      }
  
      .dropdown.icon {
        top: 6px !important;
      }
  
      .button {
        height: 42px;
      }
  
      .button.light {
        background-repeat: no-repeat;
        background-size: contain;
        background-size: 20px;
        background-position-y: center;
        background-position-x: 12px;
      }
  
      #google-login-button {
        .button.light {
          background-image: url(../../img/g-logo.png);
        }
      }
  
      #microsoft-login-button {
        .button.light {
          background-image: url(../../img/ms-logo.svg);
          font-weight: 500 !important;
          color: #3c4043 !important;
        }
      }
  
      .mt-10 {
        margin-top: 10px;
      }
  
      .mt-15 {
        margin-top: 15px;
      }
  
      .mt-25 {
        margin-top: 25px;
      }
  
      a {
        text-decoration: none;
        color: #b5b5b5;
        text-align: right;
        display: block;
        width: 100%;
        cursor: pointer;
      }
  
      h1 {
        color: #fff;
        font-size: 90px;
        font-weight: bold;
        font-family: Arial;
  
        span {
          color: #1fbf5f;
        }
      }
  
      h3 {
        color: #fff;
        font-family: Arial;
        font-weight: bold;
        font-size: 27px;
        margin: 5px 0 0 0;
      }
    .password {
        position: relative;
  
        .password-icon {
          position: absolute;
          right: 10px;
          top: 9px;
          color: #b5b5b5 !important;
        }
    }
    .warning-message {
        background: #FFFFEF 0% 0% no-repeat padding-box;
        border: 1px solid #A5673F;
        border-radius: 4px;
        padding: 12px;
        p {
            font: normal normal normal 14px Lato;
            letter-spacing: 0px;
            color: #A5673F;
        }
    }
}