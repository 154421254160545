.media-preview-question-result {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 25px;
  border-radius: 4px;
  background-color: #02b5ad;
  color: #ffffff;
  gap: 5px;
  padding-right: 5px;
  padding-left: 5px;
  cursor: pointer;
}

.media-preview-question-text-container {
  display: flex;
}

.media-preview-question-text {
  font-size: 9px;
}

.media-preview-question-result-icon {
  margin: auto !important;
  color: #ffffff;
}

.media-preview-container {
  display: flex;
  align-items: center;
  gap: 12px;
}
