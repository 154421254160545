.PinModal {
  &__modal-header {
    p {
      margin: 0;
      font-size: 18px;
      i.icon {
        margin-right: 10px;
      }
    }

    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px 0 0;
  }

  &__modal-footer {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }

  &__delete-button {
    display: flex !important;
    align-items: center !important;
  }

  &__checkbox-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 9px 6px 9px 10px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 3px;
    white-space: nowrap;
  }

  &__checkbox-box {
    align-self: flex-end;
    margin: 0px !important;
  }

  &__checkbox-container-align {
    width: 100% !important;
  }

  &__checkbox-fields {
    margin-bottom: 0 !important;
  }

  &__checkbox-fields-group {
    align-self: flex-end;
  }

  .appointment-note-attachments i.icon {
    margin-top: 0px;
  }

  .attachment-container .attachment-view .attachment-name i.icon {
    margin-top: -2px;
  }
}
