.react-datepicker__input-container {
  .react-datepicker__close-icon:after {
    display: flex;
    border-radius: 16px;
    padding: 0px;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    width: 14px;
    height: 14px;
  }
}

.CalendarInput {
  border: 1px solid #dededf;
  padding: 8px 16px;
  border-radius: 0.28571429rem;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;

  &:active,
  &:focus,
  &:focus-visible {
    border: 1px solid #96c8da;
    outline: none;
  }
}

.CalendarDatepicker {
  border-radius: 6px;
  border: 1px solid #e2e2e3;
  box-shadow: 0px 3px 6px #00000029;

  .react-datepicker__day--keyboard-selected {
    background: none;
    color: inherit;
    &:focus {
      background-color: #e1e1e1;
      color: #000000;
    }
  }

  .react-datepicker__day {
    font-size: 12px;
    width: 1.7rem;
    margin: 0.25rem;
    &--today {
      background-color: #2d84ce !important;
      color: white !important;
    }
  }

  .react-datepicker__navigation {
    top: 10px;

    .react-datepicker__navigation-icon:before {
      border-width: 1px 1px 0 0;
      border-color: #000000;
    }
  }

  .react-datepicker__header {
    border: none;
    background-color: #fff;
    padding-top: 8px;

    .react-datepicker__current-month {
      padding-top: 6px;
      font-size: 14px;
    }

    .react-datepicker__day-names {
      margin-top: 6px;
      background-color: #f9fafb;
      padding: 4px;
      text-transform: uppercase;

      .react-datepicker__day-name {
        width: 2.15rem;
      }
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--range-start,
  .react-datepicker__day--in-range {
    background-color: #1678c2;
    border-radius: 5px;
  }

  .react-datepicker__day--outside-month {
    color: #aaaaaa;
  }

  .react-datepicker__month {
    padding: 8px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__today-button {
    padding: 6px;
    background-color: #f9fafb;
    border: none;
    color: #333333de;
  }
}
