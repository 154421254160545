.StatusIcon {
  height: 30px;
  width: 34px;
  border-radius: 5px;
  background-color: #e3e3e4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid;
  transition: all 0.2s ease-in-out;
  i.icon {
    color: #000;
    margin: -10px 0 0 0;
  }

  .svg-face-icon {
    display: flex;
    align-items: center;
  }

  &.disabled {
    cursor: not-allowed;
    i.icon {
      color: #afafaf;
    }
  }

  &:not(.disabled):hover,
  &.active:hover {
    box-shadow: 0px 3px 6px #00000029;
  }
}

.StatusIcon-content {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 8fr;
    grid-gap: 15px;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 2px;

    .left-section {
      display: flex;
      align-items: center;

      .bo-icon {
        color: #000000;
      }
    }

    .right-section {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .status-time {
        font-size: 14px;
        font-weight: bold;

        span:first-child {
          margin-right: 4px;
        }
      }

      span {
        font-size: 1rem;
        text-align: left;
      }
    }
  }
}
