.AppointmentNotesIcons-wrapper {
  display: inline-flex;
  gap: 2px;
  .ui.red.label {
    background-color: #ff020233 !important;
    border-color: #ff0202 !important;
    color: #ff0202 !important;
  }
  .ui.green.label {
    background-color: #48913d33 !important;
    border-color: #48913d !important;
    color: #48913d !important;
  }
  .ui.orange.label {
    background-color: #ffba3933 !important;
    border-color: #ffba39 !important;
    color: #ffba39 !important;
  }
  .ui.blue.label {
    background-color: #2185d033 !important;
    border-color: #2185d0 !important;
    color: #2185d0 !important;
  }
}

.AppointmentNotePopup {
  .AppointmentNoteTitle {
    display: flex;
    margin-bottom: 3px;
    align-items: center;
    svg {
      position: relative;
      margin-right: 7px;
      bottom: 1px;
    }
  }
  .AppointmentNoteDescription {
    font-style: italic;
    font-size: 12px;
    color: #767676;
  }
  .AppointmentNoteContent {
    margin-top: 10px;
    font-size: 14px;
    white-space: break-spaces;
    .CallCustomerPhoneNr {
      margin-bottom: 6px;
    }
  }
}
