.customer-communication-icon {
  display: flex !important;
  gap: 10px !important;
  align-items: center !important;
  border: none !important;

  &.border {
    border-right: 1px solid rgba(34, 36, 38, 0.15) !important;
  }

  i.icon.envelope {
    position: relative;
    left: 1px !important;
  }
}
.external-link-btn {
  border: none !important;
}
