.appointment-table-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  .appointment-table-checklist {
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    font-size: 16px;
    font-weight: 900;
    background-color: #ffffff;
  }

  P {
    font-size: 12.6px;
    margin: 0;
    width: max-content;
  }

  i.icon.margin {
    margin-top: -10px;
  }
}

.appointment-table-wrapper.pointer {
  cursor: pointer;
}

.appointment-table-wrapper.end {
  justify-content: flex-end;
}

.appointment-table-flex {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.appointment-table-flex.divider {
  .menu {
    min-width: 220px !important;
  }
  .ui.dropdown .menu .item {
    border-bottom: 1px solid #e2e2e3;
    &.selected {
      font: normal normal bold 14px/16px Lato;
      letter-spacing: 0px;
      color: #000000;
      background-color: #f8f9fa;
    }
  }
}

.appointment-table-last-user-dropdown {
  width: 25px;
  height: 25px;
  border: 1px solid #707070;
  border-radius: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #707070;

  .text {
    font-size: 11px;
  }
}

.appointment-table-user-popup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;

  &-top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;

    &-initials {
      width: 25px;
      height: 25px;
      border: 1px solid #767676;
      border-radius: 360px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #767676;
      font-family: "Lato" !important;
      font-size: 10px;
      font-weight: normal;

      &-image {
        width: 25px;
        height: 25px;
        border: 1px solid #767676;
        border-radius: 360px;
        object-fit: cover;
      }
    }

    &-name {
      color: #000000;
      font-family: "Lato" !important;
      font-size: 14px;
      font-weight: bold;
    }
  }

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;

    &-name,
    &-status {
      color: #000000;
      font-family: "Lato" !important;
      font-size: 14px;
      font-weight: normal;
    }
  }
}

.last-user-dropdown-selection {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
}

.appointment-table-full {
  width: 100%;
  display: flex;
  justify-content: center;
}

.appointment-table-user-container {
  display: flex;
  align-items: center;
}

.appointemnt-table-user-head {
  margin-left: -2px;
}
.appointment-table-user {
  width: 25px;
  height: 25px;
  border: 1px solid #2185d0;
  border-radius: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2185d0;

  .text {
    font-size: 11px;
  }

  .image {
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
    object-fit: cover;
  }
}

.panicRow {
  background-color: #fef0e7 !important;

  &:hover {
    background-color: #f2d5c8 !important;
  }

  .appointment-table-last-user {
    border: 1px solid #ff0202 !important;
    color: #ff0202 !important;
  }
}

.last-user-dropdown-item {
  width: 25px;
  height: 25px;
  border: 1px solid #707070;
  border-radius: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #707070;
}

.AppointmentsTable {
  border-spacing: 0px;
  border-top: 1px solid #e2e2e3;
  border-right: 1px solid #e2e2e3;
  border-left: 1px solid #e2e2e3;
  text-align: left;
  border-radius: 4px;
  width: 100%;
  table-layout: fixed;

  .wo-nr-container {
    display: flex;
    align-items: center;
  }

  .ellipsis-wo-nr {
    display: inline-block !important;
    width: 105px;
    direction: rtl;

    span:nth-child(2) {
      margin-left: 5px;
    }
  }

  tbody {
    .AppointmentTableRow {
      display: contents;

      &:hover {
        color: grey;
      }
    }
  }

  thead {
    background-color: #f9fafb;
    padding: 0 10px;
  }

  tr {
    height: 36px;
    cursor: pointer;
    vertical-align: middle;

    &:hover {
      background-color: #f9fafb;
      color: #000;
    }
  }

  th {
    padding-left: 12px;
    border-bottom: 1px solid #e2e2e3;
    min-width: 75px;

    &:last-child {
      text-align: right;
      padding-right: 10px;
    }
  }

  td {
    border-bottom: 1px solid #e2e2e3 !important;
    padding-left: 10px;

    &.text-center {
      text-align: center;
      display: table-cell !important;
    }

    &:last-child {
      padding: 2.5px 10px;
      display: flex;
      justify-content: flex-end;
    }

    .-panic-user {
      background-color: #fef0e7;
      color: #ff0202;
    }

    .-customer-sign-wo {
      background-color: #e1ecf3;
      color: #2d85d0;
      text-align: center;
      border-radius: 4px;
    }

    .-warranty-signature-missing {
      text-align: center;
      background-color: #fef0e7;
      color: #ff0202;
    }

    .ellipsis-wo-nr {
      display: inline-block !important;
      width: 105px;

      span:nth-child(2) {
        margin-left: 5px;
      }
    }

    .ellipsis-model {
      max-width: 230px;
      min-width: 150px;
    }

    .internal-wo {
      position: relative;
      right: 5px;
      text-align: left;
      padding: 5px 0px;
      color: #ff0202;
      font-weight: 600;
    }

    .internal-wo-text {
      background-color: #fef0e7;
      padding: 6px;
    }
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

.appointment-table-dd-margin {
  margin-left: 4px;
}

i.icon.appointment-pin {
  cursor: pointer;
}

i.icon.appointment-pin.appointment-pin-disabled {
  cursor: default;
  pointer-events: none;
}

.small-status {
  .button.ui.button {
    height: 30px;
    width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.pin-grey {
  color: #584a4a;
  margin-right: 8.5px;
}

.expired-apk {
  background: #fbe8e8;
  border-radius: 4px;
  color: #db2828;
  padding: 6px;
}

.apk-expire-soon {
  background: #efe4f4;
  color: #6d0699;
  border-radius: 4px;
  padding: 6px;
}
