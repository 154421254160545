.ExactDashboard {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__filters {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    width: 100%;
    z-index: 2;
    > * {
      min-width: 0;
    }
    .ui.dropdown,
    .ui.input {
      flex: 1.5;
      width: 100%;
    }
    .datepicker-wrapper {
      flex: 0.8;
      min-width: unset;
      max-width: unset;
      width: 100%;
    }
  }

  .ExactFailuresTable {
    position: relative;
    width: 100%;

    .ReactTable {
      width: 100%;
      min-width: 1200px;
      table-layout: fixed;

      &-wrapper {
        width: 100%;
        overflow-x: hidden;
      }

      &-body {
        max-height: 80vh;
        overflow-y: auto;
      }

      thead {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      th,
      td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .pagination {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }

  .ui.loader {
    margin-top: 48px;
  }
}
