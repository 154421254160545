.AddUpdateAppointmentNote {
    .add-update-appointment-note-textare-field {
      display: block;
      width: 100%;
      border: 1px solid #e2e2e3;
      border-radius: 4px;
      margin-bottom: 5px;
      padding: 10px;
    }
  
    .add-update-appointment-note-textare-field:focus {
      outline: none;
      border-color: inherit;
    }
  
    .required.field.add-update-appointment-note-form-field {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
  
    .add-update-appointment-note-input-field {
      display: block;
      width: 100%;
  
      input {
        width: 100%;
      }
    }
  
    .add-update-appointment-note-checkbox-field {
      display: block;
      text-align: right;
      margin: 15px 0px;
    }
  
    .add-update-appointment-center-container {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  
    .add-update-appointment-note-phone-options-dropdown {
      width: 50%;
      margin-top: 15px;
    }
  
    .add-update-appointment-note-phone-input {
      width: 45%;
      height: 38px;
    }
  
    .add-appointment-note-modal-actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
  
      button:first-child {
        margin-right: 5px;
      }
    }
  
    .update-appointment-note-modal-actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      div:nth-child(2) {
        button:first-child {
          margin-right: 5px;
        }
      }
    }
  
    .attachment-uploaded {
      display: block;
      width: 100%;
      border: 1px solid #e2e2e3;
      padding: 50px 30px;
      text-align: center;
    }
  
    .add-update-appointment-attachments-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
  
      i {
        margin-right: 0px;
      }
  
      i:first-child {
        margin-right: 3px;
        font-size: 14px;
        color: gray;
      }
  
      i:nth-child(2) {
        cursor: pointer;
        color: gray;
        font-size: 14px;
      }
  
      .attachment-name {
        color: #4183c4;
      }
    }
  
    .attachment-container {
      color: #4183c4;
    }
  
    .attachment-uploaded-by {
      color: gray;
      font-style: italic;
      margin-right: 3px;
    }
  
    .attachment-uploaded-date {
      color: gray;
      margin-right: 3px;
    }
  }
  