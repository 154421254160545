.DBB {
    color: #000 !important;
    .DBB-secondary-button {
        height: 100%;
        border-left: 1px solid #e2e2e3;
        align-items: center;
        display: flex;
    }
    .DBB-label {
        margin-right: 2px;
    }
    .checkedTire {
        position: relative;
        height: 15px;
        width: 15px;
        border-radius: 15px;
        overflow: hidden;
        margin-right: 3px;
        margin-left: -3px;
        &.red {
            outline: 2px solid #ff020233;
            background-color: #ff020233;
        }
        &.green {
            outline: 2px solid #8cc34a99;
            background-color: #8cc34a99;
        }
        &.orange {
            outline: 2px solid #ffc00899;
            background-color: #ffc00899;
        }
        i.icon {
            position: absolute;
            &:first-child {
                font-size: 10px !important;
                top: 0;
                left: 2px;
            }
            font-size: 12px !important;
            top: 7px;
            left: 6px;
        }
    }
    i.icon.arrow.rotate {
        padding-left: 8px !important;
    }
    i.icon.arrow.up.right.from.square {
        margin-right: 4px !important;
    }
    &.green {
        border: 1px solid #21ba45 !important;
        background: #ccecd6 !important;
        .DBB-secondary-button {
            border-left: 1px solid #21ba45;
        }
    }
    &.orange {
        background: #fff5ee !important;
        border: 1px solid #ffc300 !important;
        .DBB-secondary-button {
            border-left: 1px solid #ffc300;
        }
    }
    &.red {
        background: #fef0e7 !important;
        border: 1px solid #ff0202 !important;
        .DBB-secondary-button {
            border-left: 1px solid #ff0202;
        }
    }
}

.ui.wide.popup.DBBbutton {
    padding: 8px 10.5px;
    max-width: 500px;
    &.red {
        background-color: #fef0e7 !important;
        &:before {
            background-color: #fef0e7 !important;
        }
    }
    &.orange {
        background-color: #fff5ee !important;
        &:before {
            background-color: #fff5ee !important;
        }
    }
    .DBBPopup {
        font-family: "Lato" !important;
        font-size: 13px;
        letter-spacing: 0px;
        color: #000000;
        span {
            font-weight: bold;
        }
    }
}