.AppointmentDate {
    &.isEditable {
        span {
            border-bottom: 1px solid #ccc;
        }
    }
}

.ui.modal.AppointmentDateModal {
    width: auto;
    .content {
        padding: 0;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .AppointmentDateModal-heading {
        letter-spacing: 0px;
        color: #000000DE;
        opacity: 1;
        font-family: "Lato" !important;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        padding: 0 10px 10px;
    }
    .react-datepicker {
        border: 0;
    }
    .react-datepicker__header {
        background-color: #ffffff;
    }
    .react-datepicker__day-names {
        background-color: #f0f0f0;
    }
    .react-datepicker__header__dropdown {
        padding: 10px 0;
    }
    .react-datepicker__current-month {
        margin-top: -7px;
    }
}

.ui.modal.AppointmentDateModal .react-datepicker__day-name,
.ui.modal.AppointmentDateModal .react-datepicker__day,
.ui.modal.AppointmentDateModal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
}

.ui.modal.AppointmentDateModal .react-datepicker__current-month,
.ui.modal.AppointmentDateModal .react-datepicker-time__header {
    font-size: 1.44rem;
}

@media (max-width: 400px),
(max-height: 550px) {
    .ui.modal.AppointmentDateModal .react-datepicker__day-name,
    .ui.modal.AppointmentDateModal .react-datepicker__day,
    .ui.modal.AppointmentDateModal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}