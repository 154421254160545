.DMSNotes {
  border: 1px solid #e2e2e3;
  margin-top: 10px;
  border-radius: 4px;

  .dms-note-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 13px;
    min-height: 44px;
    border-bottom: 1px solid #e2e2e3;

    &:last-child {
      border-bottom: none;
    }

    .dms-note-info {
      font-size: 12.6px !important;
      font-weight: 600;
    }

    .dms-note-type {
      font-size: 12.6px !important;
      strong {
        text-transform: capitalize;
        margin: 0px 3px;
      }
    }
  }
}
