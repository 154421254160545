.LogHistory {
  padding: 11px;
  border: 1px solid #e2e2e3;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 54px;
  gap: 5px;

  .ui.grid {
    margin-top: 0;
    margin-bottom: 0;

    & > .row {
      padding-top: 1rem;

      &:first-child {
        padding-top: 0;
      }
    }

    .row .column .row {
      margin-top: 5px;
    }
  }
  p {
    margin: 0;
    padding: 0;
  }
  &.empty {
    align-items: center;
    justify-content: center;
  }
  .log-item {
    border-radius: 4px;
    background-color: #e2e2e3;
    height: min-content;
    padding: 0 5px;
  }
  .label {
    i.icon {
      margin-right: 5px;
    }
  }
  .row.log_values {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
  }
}

.log-history-heading-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  margin-bottom: 8px;
  span {
    font-size: 14px;
    font-weight: 700;
    margin: 0em 0em 0.28571429rem 0em;
    color: rgba(0, 0, 0, 0.87);
    text-transform: capitalize;
  }
}

.LogHistory-Container {
  .AppointmentNoteAttachments {
    .appointment-note-attachments {
      gap: 3px;
      i.icon {
        margin-top: 0;
        margin-right: 0;
        height: auto;
      }
    }
  }

  .log-history-container {
    overflow-y: auto;
    max-height: 25vh;
    overflow-x: hidden;
  }
}

.snooze-item-title {
  font-size: 18px;
}
