#CommunicationLog-Content {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;

  @media print {
    padding: 1rem;
  }

  .ui.secondary.menu {
    display: table;
    width: 100% !important;

    a.item {
      display: inline-block;
      padding: 1.2% !important;
    }
  }

  .info-section {
    border: 1px solid #e2e2e3;
    border-radius: 4px;
    margin: 1rem 0;

    &:first-child {
      margin-top: 0;
    }

    .section-header {
      background-color: #f3f5f7;
      font-weight: 700;
      padding: 1em;
    }

    .section-content {
      .ui.grid {
        margin: 0;
      }

      .agreements-label {
        display: block;
        margin-bottom: 4px;

        span {
          cursor: pointer;
        }

        .ui.label {
          cursor: pointer;

          i.icon {
            margin: auto;
          }
        }
      }

      .signature-img {
        background-color: #f3f5f7;
        padding: 17px 15px;
      }

      .event-timestamp {
        text-align: right;
      }

      .services-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.8rem;
        font-weight: 700;
      }

      .service {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.8rem;
        padding-bottom: 0.8rem;
        border-bottom: 1px solid #f3f5f7;

        .service-title-remark-container {
          display: flex;
          flex-direction: column;
          white-space: normal;
          word-wrap: break-word;
          width: 88%;

          span:first-child {
            flex: 1;
            white-space: normal;
            word-wrap: break-word;
            font-weight: bold;
          }
        }

        .service-price-container {
          white-space: nowrap;
          margin-left: 10px;
        }
      }

      span.icon-text {
        color: #21ba45;
        font-weight: 700;
      }
    }

    .section-rows {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-top: 0.5rem;

      & > span:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  .ml-10 {
    margin-left: 10px;
  }
}

.CommunicationLog-Modal {
  &.ui.modal {
    > .actions {
      display: flex;
    }

    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 24px;
    }

    .action-btn {
      margin-left: auto;
    }

    .action-btn:hover {
      background-color: #e0e1e2;
      color: inherit;
    }

    .CommunicationLog-ModalActions {
      display: flex;
      width: 100%;

      button.ui.button {
        margin-left: auto;
      }
    }

    .close-icon-container {
      width: 36px;
      height: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e2e2e3;
      text-align: center;
      line-height: 18px;
      border-radius: 4px;
      margin-left: 5px;
      cursor: pointer;

      i {
        font-size: 16px;
        margin: 0px;
        margin-bottom: 2px;
      }
    }
  }
}

.CommunicationLog-AgreementModal {
  cursor: pointer;

  &.ui.modal > .header {
    display: flex;
    width: 100%;
    padding: 25px 24px;
  }

  span {
    margin-right: 15px;
  }
}

.CommunicationLog-button {
  cursor: pointer;

  .DetailsCards-container {
    padding: 11px 21px;
  }
}

.CommunicationLog-AgreementModal {
  .header {
    display: flex;
    justify-content: space-between;
  }
}
