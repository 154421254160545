.ace-github .ace_print-margin {
  display: none;
}

.ace_layer.ace_print-margin-layer {
  display: none;
}

.ace_placeholder {
  color: grey !important;
}

.ace-github .ace_gutter {
  color: #000000cc;
}

.ace_editor.ace_hidpi.ace-tm {
  textarea {
    line-height: 2;
    font-size: 14px !important;
  }
}
