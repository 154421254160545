.TyreReplacementModal {
  .modal-content-container {
    height: 70vh;
  }

  .in-stock-form-field {
    text-align: center;
  }

  .in-stock-checkbox {
    margin-top: 7px;
    text-align: "center";
  }

  .fields {
    margin: 0 !important;
    .no-padding-right {
      padding-right: 0 !important;
    }
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .ui.grid {
    border: 1px solid #e2e2e3;
    margin-top: 30px;
  }

  .ui.grid > .row {
    padding-top: 0;
    padding-bottom: 0;
    height: 42px;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      border-bottom: 1px solid #e2e2e3;
    }
    &.header {
      background-color: #f9fafb;
      height: 36px;
    }
  }

  .search-button {
    color: #414141;
  }

  .search-form-label {
    visibility: hidden;
  }

  .replacement-tyre-ean {
    display: inline-block;
    width: 50%;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }

  i.order-ref-icon {
    color: #600bff;
    opacity: 1 !important;
    font-size: 25px !important;
    width: 35px !important;
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 55px;
  }

  &__discount-dropdown {
    margin-bottom: 11px !important;
  }

  .tyre-team-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 0px !important;
    padding-right: 0px !important;

    .tyre-row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      padding: 8px 0;

      &.header {
        font-weight: bold;
        background-color: #f9fafb;
      }

      &.tyre-order-row {
        padding: 4px 0;
      }
    }

    .tyre-col {
      padding: 0 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.season {
        width: 3%;
        text-align: center;
        overflow: visible;
      }
      &.description {
        width: 20%;
      }
      &.brand,
      &.gross,
      &.neto {
        width: 7%;
      }
      &.artean {
        width: 10%;
      }
      &.customer {
        width: 6%;
      }
      &.load,
      &.speed {
        width: 4%;
      }
      &.flat-tire,
      &.stock,
      &.add,
      &.status {
        width: 5%;
      }
      &.quantity {
        width: 4%;
      }
      &.delivery {
        width: 10%;
      }
      &.channel {
        width: 7%;
      }
      &.add,
      &.status {
        text-align: center;
        margin: 0 auto;
      }

      &.flat-tire {
        margin: 0 auto;
        text-align: center;
      }

      &.quantity {
        overflow: visible;

        .ui.dropdown {
          min-width: 60px;

          .menu {
            min-width: fit-content !important;
            max-height: 200px;
            overflow-y: auto;
          }
        }
      }
    }

    .lower-customer-price {
      background-color: #fef0e7;
      color: #ff0202;
      width: fit-content;
    }

    .check {
      margin: 0 auto !important;
    }
  }

  &.ui.modal > .actions {
    justify-content: flex-end;

    .tyre-team-order-button {
      &.-order-disabled {
        opacity: 0.5;
      }
    }
  }

  .invalid-quantity.ui.selection.dropdown {
    border-color: #e0b4b4 !important;
    background-color: #fff6f6 !important;
  }

  .three-pmsf-icon {
    display: inline-block;
    margin-left: 2px;

    img {
      position: relative;
      top: 1px;
      width: 16px;
      height: 16px;
    }
  }

  .tyre-team-list-loading,
  .no-results-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 180px;
    width: 100%;
    padding: 20px;

    p {
      font-size: 16px;
    }
  }
}
