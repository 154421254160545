.DBBLabelsModal {
  .dbb_actions {
    display: flex;
    gap: 10px;
  }


  .dbb_label_dropdown {
    display: inline-block;

    span {
      margin-right: 10px;
      font-size: 14px;
      color: #5d5d5d;
      vertical-align: middle;
    }
  }
}

#dbb_label_content_container {
  width: 100% !important;
  padding: 20px;
  margin: 0;

  @media print {
    padding: 0 !important;
  }

  * {
    font-family: Arial, Helvetica, sans-serif;
  }

  div {
    font-size: 20px;
    line-height: 32px;
  }

  .dbb_wrapper {
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .dbb_wrapper_label_size {
    @media print {
      display: block !important;
      height: 100% !important;
    }

    & > div {
      @media print {
        height: fit-content !important;
      }

      div {
        @media print {
          height: 100% !important;
        }
      }
    }
  }

  .dbb_label_box {
    margin: 0 35px 242px 35px;

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }

    .dbb_label_content {
      padding-right: 40px;

      .dbb_label_section div {
        line-height: 27px;
      }

      .mb-28 {
        margin-bottom: 28px;
      }

      .mb-28_label_size {
        @media print {
          margin-bottom: 14px !important;
          height: 30px !important;
        }
      }

      .mb-26 {
        margin-bottom: 25px;
      }

      .mb-26_label_size {
        @media print {
          margin-bottom: 0px !important;
        }
      }

      .mb-24 {
        margin-bottom: 20px;
      }

      .mb-24_label_size {
        @media print {
          margin-bottom: 0px !important;
        }
      }
    }

    .dbb_label_content_label_size {
      @media print {
        padding-right: 0 !important;
        width: auto !important;
      }
    }

    .dbb_label_name {
      color: #4e4e4e;
    }

    .dbb_label_comment {
      display: flex;
      justify-content: space-between;
      height: 60px;
      overflow: hidden;
    }

    .dbb_section_mid {
      line-height: 27px;
    }

    .dbb_location_name {
      display: inline-block;
      height: 65px;
      max-height: 65px;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 85px;
    }

    &:nth-child(even) {
      margin-right: 0;

      .dbb_label_tyre_position span {
        margin-right: 3px;
      }
    }

    &:nth-child(odd) {
      margin-left: 0;

      .dbb_label_tyre_position span {
        margin-right: 1ch;
      }
    }
  }

  .dbb_label_box_label_size {
    @media print {
      width: 100% !important;
      height: auto !important;
      margin: 0px !important;
      page-break-after: always;
    }
  }

  h1 {
    width: 100% !important;
    font-size: 68px;
    font-weight: 900;
    margin: 10px 0 20px 0;
    text-align: center;
  }

  .h1_label_size {
    @media print {
      margin-bottom: 0px !important;
    }
  }

  .dbb_label_tyre {
    display: grid;
    grid-template-areas:
      "a b"
      "c b";

    &_position {
      grid-area: a;
      font-weight: 600;
      font-size: 24px;

      span {
        text-decoration: underline;
      }
    }

    &_size {
      grid-area: c;
      font-size: 20px;
      font-weight: 400;
    }

    &_description {
      grid-area: b;
      margin-left: 20px;
      font-weight: 600;
      font-size: 24px;
    }
  }

  .float-right {
    float: right;
    text-align: right;
    width: auto !important;
  }
}