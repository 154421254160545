@import "../../../../colorbox.scss";

.BackordersDashboardFilters {
  &__toolbar-container {
    display: flex;
    align-items: center;
    margin: 9px 9px 5px 0px;
  }

  &__toolbar-grid {
    width: 100% !important;
    margin: 0px 9px 0px 22px !important;
  }

  &__toolbar-reset-export-buttons-container {
    display: flex;
    gap: 9px;
    margin-right: 12px;
  }

  &__no-left-gutter {
    padding-left: 0px !important;
  }
}
