.UploadFilesZone {
  text-align: center;
  border: 1px dotted #ccc;
  margin-top: 20px;
  height: 61px;
  padding-top: 10px;

  input {
    visibility: hidden;
    height: 0;
  }

  .browse-text {
    color: #2185d0;
    text-decoration: underline;
    cursor: pointer;
  }

  .max-file-size {
    color: #b5b5b5;
  }

  &.error {
    border-color: red;
  }
}