.question-status-card-user {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 25px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #e2e2e3;
}

.question-status-card-icon {
  margin: auto !important;
}

.question-status-card-icon-ok {
  rotate: 180deg;
  transform: scaleX(-1);
}

.question-status-card-icon-ok-container {
  margin-top: 3px;
}
