.other-locations-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  span {
    cursor: default;
    color: #767676;
    font-weight: bold;
  }
}

.other-locations-divider {
  border-top: 1px solid #cececf;
}

.other-locations-text-container {
  align-self: center;
  padding: 6px 0px;
}
