.tabs-container {
  width: 100%;
  overflow: visible;

  &.hideBorder {
    margin-left: -15px;
    .pointing.menu {
      border: none !important;
      border-radius: 0px;
      padding-left: 0px !important;
      padding-top: 0px !important;
      margin: 0px !important;
      overflow: visible;
      .item {
        padding: 15px 17.5px !important;
      }
    }
  }

  .pointing.menu {
    background-color: #fff;
    width: 100%;
    padding-right: 50px;
    position: relative;
    display: flex;
    overflow: visible !important;

    i.icon {
      margin: 0 !important;
    }

    span {
      margin-left: 5px;
    }

    .item {
      white-space: nowrap;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 0 0 auto;
      display: block;

      &.overflow-dropdown {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        background: white;
        overflow: visible;

        &.active.item {
          border: none !important;
          margin: 0 !important;
          color: inherit !important;
        }
      }
    }

    .dropdown-menu-item {
      max-width: none;
    }

    .overflow-dropdown {
      .menu {
        right: 0;
        left: auto !important;
        overflow: visible !important;

        @media screen and (min-width: 768px) {
          &.visible {
            transform-origin: top right;
          }
        }
      }
    }
  }

  .secondary.pointing.menu .active.item {
    padding: 15px 17.5px !important;
    color: #21ba45 !important;
    border-color: #21ba45 !important;
    border-width: 4px !important;
  }

  .menu:after {
    display: none;
  }

  a {
    &:hover {
      text-decoration: none;
    }
    text-decoration: none;
  }
}
