.WarrantyPinType {
  &__pin-type-container {
    display: flex;
    justify-content: flex-end;

    i.icon {
      margin: auto;
      height: auto;
      color: white;
    }
  }

  &__pin-type-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 25px;
    border-radius: 4px;
    color: green;
  }
}
