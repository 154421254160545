.AppointmentSteps {
  .AppointmentSteps__content {
    background-color: #fff;
    z-index: 999999;
    .AppointmentSteps__content_steps {
      .ui.steps {
        border-radius: 0;
        .step {
          cursor: pointer;
          font-size: 0.65rem;
          font-weight: 300;
          width: 175px !important;
          color: #1dbf5e;
          &.disabled {
            color: rgba(40, 40, 40, 0.3);
          }
          padding: 0.8em 1.25em;
          &:hover {
            background-color: #f7f7f7;
          }
          &.-active-status-active {
            background: #1dbf5e;
            color: #fff;
            .content {
              .title {
                color: #fff;
              }
            }
            &:after {
              background-color: #1dbf5e;
            }
          }
        }
      }
    }
  }
}
