.TimePeriodDropdown {
  .ui.dropdown > .text {
    display: inline-block;
    transition: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: stretch;
  }
}
