.CategoryLicenseModal {
  padding-top: 0;
  margin: 0;
  &.ui.modal {
    .content {
      display: block;
      width: 100%;
      font-size: 1em;
      line-height: 1.4;
      padding: 2rem;
      background: #ffffff;

      .addLicenseButton {
        background-color: #21ba45;
        border: none;
        color: #ffffff;
        cursor: pointer;

        &:hover {
          background-color: #21ba45;
        }
        i {
          background-color: #1fb142 !important;
          color: #ffff !important;
        }
      }
    }
    .actions {
      background: #f9fafb;
      display: flex;
      justify-content: space-between;
      background-color: #ffffff;
      border-top: 1px solid #e2e2e3;
      padding: 18px 24px;
      text-align: right;
    }
  }
  .ui.form .fields {
    align-items: flex-end;
  }
  .search {
    display: flex;
    width: 100%;
    align-items: center;

    button {
      margin-top: 0px !important;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      height: 38px;
      padding: 0 12.3px;
      flex: none;
      font-size: 14px;

      font-weight: 600;

      i.icon {
        opacity: 1;
      }
    }

    input {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    &.rounded {
      input {
        border-radius: 4px;
      }
    }
  }
  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
  }
  .radio-button {
    border: 1px solid #dededf !important;
    border-radius: 4px;
    padding: 10px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    label {
      font-weight: normal !important;
    }
  }
  .react-datepicker__portal {
    width: 100%;
    height: 100%;
  }
}
