.progress-bar-container {
  width: 800px;
  height: 200px;
  background-color: #f2f2f2;
  margin-top: 10px;
}

.progress-bar-top div:first-child {
  height: 10px;
  width: 100%;
  background-color: #2185d0;
}

.progress-bar-top div:nth-child(2) {
  height: 80px;
  width: 100%;
  background-color: #48913d;
}

.progress-bar-bottom div:first-child {
  height: 80px;
  width: 100%;
  background-color: #48913d;
}

.progress-bar-bottom div:nth-child(2) {
  height: 10px;
  width: 100%;
  background-color: #2185d0;
}
