.table-question-status-card-user {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 25px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #e7e7e7;
}

.table-question-status-card-icon {
  margin: auto !important;
}
