.AppointmentStatus {
  display: flex;
  align-items: center;
  position: relative;
  gap: 60px;
  width: fit-content;
  .horizontal-line {
    position: absolute;
    height: 2px;
    background-color: #e3e3e4;
    left: 0;
    right: 0;
    z-index: -1;
  }
  .bo-icon {
    font-size: 14px;
    left: 0;
    margin-right: 0;
  }
}

.car-mileage-action-container {
  justify-content: flex-end !important;
}

.car-mileage-input {
  width: 100%;
}
