.CounterTabletModal {
  &__content {
    h3 {
      text-align: center;
    }

    .ui.loader {
      &:before {
        border-color: rgba(0, 0, 0, 0.534) !important;
      }

      &:after {
        border-color: #767676 transparent transparent;
      }
    }
  }

  &__header {
    padding-right: 24px !important;
    text-align: right;
    display: flex;
    justify-content: space-between;

    button.ui.large.basic {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__actions.actions {
    display: flex;
    justify-content: flex-end !important;
  }
}
