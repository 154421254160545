.dealer-documentations-container {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 22px 20px;
}

.dealer-toolbar-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0px 0px 20px 0px;
  align-items: center;
}

.dealer-toggle-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
