.ProgressBar-container {
  opacity: 0;
  transform: translate(0%, 0px);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 5;

  .indicator {
    height: 5px;
    background: #2185d0;
  }
}
