.AccountSetting {
  padding: 80px 20px 15px 20px;
  max-width: 1920px;
  background-color: #f7f7f7;
  height: 100%;

  .subIcon {
    position: absolute;
    top: -3px;
    right: -3px;
  }
  .setting_section {
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #e0e1e2;
    border-radius: 10px;
    padding: 30px 20px 30px 30px;
    margin-bottom: 20px;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    &.equal {
      padding: 30px;
    }
    &.role_order {
      display: flex;
      gap: 3rem;

      .divider {
        border: 1px solid #e2e2e3;
        opacity: 1;
      }
      .user-role {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #000000;
        font-family: "Lato" !important;
      }
      .FormSection {
        display: flex;
        gap: 4rem;
        .ShoppingCart-container {
          .field {
            display: flex;
            gap: 1.5rem;
            align-items: center;
            justify-content: space-between;
            &:not(:last-of-type) {
              margin-bottom: 20px;
            }
            label {
              margin-bottom: 0px !important;
              color: #000000 !important;
              font-size: 14px !important;
              line-height: 17px !important;
              font-family: "Lato" !important;
              font-weight: 400 !important;
            }
            .icon {
              font-size: 1.5em;
              margin: 0;
            }
            .to-order {
              color: #3586ed;
            }
            .same-day {
              color: #21ba45;
            }
            .tomorrow {
              color: #f05b28;
            }
            .backorder {
              color: #c83628;
            }
            .arrived {
              color: #cccc00;
            }
          }
        }
      }
    }
    label.input-label {
      color: #000000cc;
      font-size: 14px !important;
      line-height: 15px !important;
      font-family: "Lato" !important;
      font-weight: bold !important;
      margin-bottom: 8px;
      display: block !important;
    }
    .ui.disabled > input {
      background: #f7f7f7 !important;
    }
    .personal_setting {
      display: flex;
      position: relative;
      margin-bottom: 30px;
      span {
        position: absolute;
        top: -400%;
      }
      h1 {
        color: #000000de;
        font-size: 28px;
        line-height: 23px;
        font-family: "Lato";
        font-weight: bold;
        margin: 0px;
        margin-left: 150px;
      }
    }
    .profile_pic_button {
      position: absolute;
    }
    h2,
    .section-title {
      color: #000000de;
      font-size: 18px;
      line-height: 23px;
      font-family: "Lato" !important;
      font-weight: bold;
    }
    .section-subtitle {
      text-align: left;
      font: normal normal normal 14px/23px Lato;
      letter-spacing: 0px;
      color: #000000de;
    }
    .DesktopNotifications-row {
      .column:nth-child(2) {
        .DesktopNotifications {
          .grouped.fields {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 50px;
          }
        }
      }
    }
    .DesktopNotifications,
    .PinNotifications {
      &.single {
        width: 28%;
        max-width: 330px;
      }
      h4 {
        font-size: 16px;
      }
      .field label {
        display: inline-flex !important;
        align-items: center;
        flex: 1;
        gap: 1rem;
        span {
          flex: 1;
          font: normal normal 600 14px/23px "Lato" !important;
        }
        .circular-icon {
          border-radius: 50%;
          line-height: 1em;
          margin: 0 6px 0 0;
          min-width: 25px;
          position: relative;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          text-align: center;
          transform: none;
          width: 25px;
          height: 25px;
          background-color: #fff;
          border: 1px solid #d4d4d5;
          position: relative;
          i.icon:first-of-type {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 12px;
            transform: translate(-50%, -50%);
          }
        }
        p {
          text-align: left;
          font: normal normal normal 14px/23px Lato;
          letter-spacing: 0px;
          color: #000000;
        }
      }
      .notification-checkbox-toggle {
        display: flex;
        &.single {
          width: auto !important;
          max-width: 330px;
        }
        &.dynamic {
          margin-bottom: 15px;
        }
        .ui.checkbox.toggle {
          vertical-align: middle;
        }
      }
      .ui.dropdown {
        .ui.label {
          height: 28px;
          svg.svg-inline--fa,
          i.material-icons.-notification-status-icon {
            font-size: 18px;
            margin-right: 6px;
            vertical-align: middle;
            width: 18px;
          }
        }
        .ui.basic.label.notification-entity-label {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 46px;
          vertical-align: middle;
          svg,
          i {
            display: inline-flex;
            justify-content: center;
            margin: 0 !important;
            padding: 0 !important;
          }
        }
        .visible.menu .item {
          svg,
          i.material-icons.-notification-status-icon {
            font-size: 16px;
            margin-right: 6px;
            vertical-align: middle;
            width: 18px;
          }
        }
      }
    }
  }
}

.FixedButtonSection {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 67px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  max-width: 1920px;
  &:before {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e0e1e2;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
  }
  &-inner {
    display: flex;
    gap: 1rem;
    .cancel-btn {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e2e2e3;
      border-radius: 4px;
      height: 36px;
      color: #000000 !important;
      .icon.cancel {
        color: #000000;
      }
    }
    .save-btn {
      background: #21ba45 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
      height: 36px;
    }
  }
}

.location-visibility-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.selected-locations-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 12px;
    font-size: 14px;
    border: 1px solid #e2e2e3;
    border-radius: 10px;
  }
}

.my_role {
  margin-top: 30px;
  max-width: 300px;
  .change-password {
    color: #ffffff;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
  }
}
