@import "../../../../colorbox.scss";
.color-picker {
  input[type="color"] {
    -webkit-appearance: none;
    appearance: none;
    border: none;
    width: 80px;
    cursor: pointer;
    background-color: transparent;
    height: 30px;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 4px;
    border: 1px solid #b6b4b4;

    border-radius: 4px;
  }
  input[type="color"]::-webkit-color-swatch {
    border: none;

    border-radius: 4px;
  }
}
