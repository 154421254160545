.CustomerModal {
  .CustomerModal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
      width: 35px;
      margin-right: 20px;
      i.icon {
        margin-top: -4px !important;
      }
    }
  }

  .content {
    padding: 0 1.3rem !important;
  }

  .CustomerModal-footer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
  }

  .Loader-Placeholder {
    padding: 1.5em;
    color: #9f9f9f;
    font-size: 1.3em;
    font-weight: 700;
    margin-top: 2em;
    padding: 50px 0;
    text-align: center;
    section {
      margin-top: 25px;
    }

    .icon {
      font-size: 3em;
      margin-bottom: 15px;
    }
  }

  .customer_detail_dialog .customer_sys_info {
    background-color: #efefef;
    border: 1px solid #e4e4e4;
    display: inline-block;
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 7px 14px;
  }

  .customer_sys {
    margin: 1em 0 1em 0em;
    .customer_sys_info {
      background-color: #eee;
      display: inline-block;
      margin-right: 1em;
      padding: 0.5em 1em;
    }
  }

  .CustomerDetail {
    .lease-company-tag {
      margin-right: 10px !important;
      background-color: #43ba45;
      color: white;
      border-radius: 3px;
      font-size: 12px;
      padding: 5px;
      i {
        margin-left: 3.5px !important;
      }
    }
  }
}
