$orange: #f2711c;
$light-orange: rgba(242, 113, 28, 0.2);

$blue: #2185d0;
$light-blue: rgba(33, 133, 208, 0.2);

$cup-brown: #a5673f;
$light-cup-brown: rgba(165, 103, 63, 0.2);

$green: #21ba45;
$light-green: rgba(33, 186, 69, 0.2);

$red: #db2828;
$light-red: rgba(219, 40, 40, 0.2);

$yellow: #cccc00;
$light-yellow: rgba(204, 204, 0, 0.2);

.circle-icon-container {
  .circle-icon {
    width: 24px;
    height: 24px;
    border-radius: 360px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
      background-color: #70707029;
      i.small.icon {
        color: #767676 !important;
      }
    }
    i.small.icon {
      font-size: 12px;
      margin: 0;
    }
  }

  .orange {
    color: $orange;
  }
  .orangebg {
    background-color: $light-orange;
  }

  .blue {
    color: $blue;
  }
  .bluebg {
    background-color: $light-blue;
  }
  .blue-border {
    border: 2px solid $light-blue;
  }

  .orange {
    color: $orange;
  }
  .orangebg {
    background-color: $light-orange;
  }
  .orange-border {
    border: 2px solid $light-orange;
  }

  .cup-brown {
    color: $cup-brown;
  }
  .cup-brownbg {
    background-color: $light-cup-brown;
  }

  .green {
    color: $green;
  }
  .greenbg {
    background-color: $light-green;
  }
  .green-border {
    border: 2px solid $light-green;
  }

  .red {
    color: $red;
  }
  .redbg {
    background-color: $light-red;
  }
  .red-border {
    border: 2px solid $light-red;
  }

  i.small.icon.shopping.cart.yellow {
    color: $yellow !important;
  }
  .circle-icon.yellowbg.yellow-border.disabled {
    i.small.icon.shopping.cart.yellow {
      color: #767676 !important;
    }
  }
  .yellowbg {
    background-color: $light-yellow;
  }
  .yellow-border {
    border: 2px solid $light-yellow;
  }
}
