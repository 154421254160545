.ResetPassword {
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            width: 100%;
            margin: 0;
            text-align: left;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            font-family: "Lato" !important;
        }
        a {
            font-family: "Lato" !important;
            font-size: 14px;
            letter-spacing: 0px;
            color: #B5B5B5;
        }
    }
    &-language {
        p {
            font-family: "Lato" !important;
            font-size: 14px;
            letter-spacing: 0px;
            color: #414141;
        }
    }
    &-success {
        background: #DEF5E3 0% 0% no-repeat padding-box;
        border: 1px solid #21BA45;
        border-radius: 4px;
        opacity: 1;
        padding: 10px 15px !important;
        font-family: "Lato" !important;
        font-size: 14px;
        letter-spacing: 0px;
        color: #21BA45;
        margin-bottom: 15px;
    }
    &-error {
        font-family: "Lato" !important;
        font-size: 14px;
        letter-spacing: 0px;
        color: #912D2B;
        opacity: 1;
        background: #FFF6F6 0% 0% no-repeat padding-box;
        border: 1px solid #D9B6B5;
        border-radius: 4px;
        padding: 10px 15px;
        margin-top: 15px;
    }
    $strength-colors: ( darkred, orangered, orange, yellowgreen, green);
    $strength-gap: 6px;
    .strength-meter {
        position: relative;
        height: 3px;
        background: #ddd;
        margin: 7px 0;
        border-radius: 2px;
        &:before,
        &:after {
            content: "";
            height: inherit;
            background: transparent;
            display: block;
            border-color: #fff;
            border-style: solid;
            border-width: 0 $strength-gap 0;
            position: absolute;
            width: calc(20% + #{$strength-gap});
            z-index: 10;
        }
        &:before {
            left: calc(20% - #{$strength-gap / 2})
        }
        &:after {
            right: calc(20% - #{$strength-gap / 2});
        }
        .strength-meter-fill {
            background: transparent;
            height: inherit;
            position: absolute;
            width: 0;
            border-radius: inherit;
            transition: width 0.5s ease-in-out, background 0.25s;
            @for $i from 1 through 5 {
                &[data-strength="#{$i - 1}"] {
                    width: (20% * $i);
                    background: nth($strength-colors, $i);
                }
            }
        }
    }
}