.InvoiceTable {
  .pagination-controls {
    .nav-button {
      i {
        margin: 0;
      }
    }
  }

  table {
    border-radius: 6px !important;
    overflow: hidden;
  }
}
