.TextareaEditable {
    font-size: 12.6px;
    width: 100%;
    &.showAsInfo {
        textarea {
            background-color: #ffffff;
            border-radius: 0;
            border: none;
            resize: none;
            overflow: hidden;
            &:hover {
                cursor: text;
                background-color: #f9fafb;
            }
        }
    }
    &.disabled {
        textarea {
            &:hover {
                cursor: default !important;
                background-color: #ffffff !important;
            }
        }
    }
    &.placeholder {
        color: #ccc;
    }
    textarea {
        font-size: 12.6px;
        padding: 2px 5px;
        font-family: "Lato";
        width: 100% !important;
        background-color: #f9fafb;
        border-radius: 4px;
        border: 1px solid #96c8da;
        margin-top: 4px;
        line-height: 1;
        &:focus {
            outline: none !important;
            outline-width: 0 !important;
            box-shadow: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
        }
    }
}