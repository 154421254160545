.DetailsCards-container {
  padding: 15px 21px;
  border: 1px solid #e0e1e2;
  border-radius: 10px;

  .ui.button.icon.new-intervention {
    i.icon {
      margin: -2.2px 0px 0px 0px !important;
    }
  }

  .cancel-appointment {
    background-color: #cc1111 !important;
    border-color: #cc1111 !important;
    width: 50px;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    img {
      width: 16px;
      height: 16px;
    }
  }

  .expand-bar {
    display: flex;
    align-items: center;
    gap: 5px;
    flex: 1;
    border-radius: 4px;
    padding: 5px;
  }

  .checklist-title {
    max-width: calc(100vw - 245px) !important;
  }

  &.button {
    background-color: #f9fafb;

    .row h3 {
      font-size: 14.5px;
      text-transform: none;
      font-weight: 500;
    }
  }

  .row {
    display: flex;
    align-items: center;
    gap: 13px;
    h3 {
      margin: 0;
      text-transform: uppercase;
      flex: none;
      max-width: calc(100vw - 350px);
    }
    i.icon {
      margin-top: -5px;
      font-size: 18px;
    }
  }

  .show {
    height: auto;
  }
  .hide {
    height: 0px;
    overflow: hidden;
    position: relative;
  }
  .pointer {
    cursor: pointer;
  }
}

.car-card-container.small {
  padding: 17px 19px;
}
