.Editable {
  font-size: 12.6px;
  width: 100%;
  &.disabled {
    p {
      &:hover {
        background-color: inherit !important;
        cursor: default;
      }
    }
  }

  &.placeholder {
    color: #ccc;
  }

  p {
    min-height: 24px;
    &:hover {
      background-color: #f9fafb;
      border-radius: 4px;
      cursor: text;
    }
  }
  input {
    font-size: 12.6px;
    padding: 2px;
    font-family: "Lato";
    width: 100%;
    background-color: #f9fafb;
    border-radius: 4px;
    border: 1px solid #96c8da;
    &:focus {
      outline: none !important;
      outline-width: 0 !important;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
}
