.Auth {
  background-color: #f7f7f7;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 55%;
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  position: absolute;
  left: 0;

  .auth_container {
    max-width: 1440px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .auth__overlay {
    background: #134e5e;
    background: -webkit-linear-gradient(to right, rgb(113, 178, 128), rgb(19, 78, 94));
    background: linear-gradient(to right, rgb(113, 178, 128), rgb(19, 78, 94));
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 30px;
    overflow: hidden;
  }

  .auth_footer {
    height: 41px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.13);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;

    p,
    a {
      font-family: "Lato";
      font-size: 16px;
      font-weight: 300;
      line-height: 19px;
      color: #fff;
      margin: 0;
    }
  }

  .repair_img {
    margin-top: 150px;
    width: 60%;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .auth_rhs {
    width: 403px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .dropdown {
      min-height: 36px;
      padding: 0 2.1em 0 1em;
      display: flex;
      align-items: center;
    }

    .dropdown.icon {
      top: 6px !important;
    }

    .button {
      height: 42px;
    }

    .button.light {
      background-repeat: no-repeat;
      background-size: contain;
      background-size: 20px;
      background-position-y: center;
      background-position-x: 12px;
    }

    #google-login-button {
      .button.light {
        background-image: url(./img/g-logo.png);
      }
    }

    #microsoft-login-button {
      .button.light {
        background-image: url(./img/ms-logo.svg);
        font-weight: 500 !important;
        color: #3c4043 !important;
      }
    }

    .mt-10 {
      margin-top: 10px;
    }

    .mt-15 {
      margin-top: 15px;
    }

    .mt-25 {
      margin-top: 25px;
    }

    a {
      text-decoration: none;
      color: #b5b5b5;
      text-align: right;
      display: block;
      width: 100%;
      cursor: pointer;
    }

    h1 {
      color: #fff;
      font-size: 90px;
      font-weight: bold;
      font-family: Arial;

      span {
        color: #1fbf5f;
      }
    }

    h3 {
      color: #fff;
      font-family: Arial;
      font-weight: bold;
      font-size: 27px;
      margin: 5px 0 0 0;
    }

    .rhs_auth_box {
      margin-top: 33px;
      padding: 38px 31px;
      border-radius: 5px;
      background-color: #ffffff;
      box-shadow: 0px 3px 6px #00000029;
      width: 100%;
    }

    .password {
      position: relative;

      .password-icon {
        position: absolute;
        right: 10px;
        top: 7px;
        color: #b5b5b5 !important;
      }
    }
  }

  .ui.message {
    text-align: center;
  }

  .qr-login {
    background-color: #2348b0;
    height: 43px;
  }

  .LightGray {
    color: #ababab;
  }
}
