.ROBListData {
    .section-title {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 14px;
        color: #000000CC;
    }
    .data_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 7px;
        .title {
            font-weight: bold;
            font-size: 14px;
            color: #000000CC;
            flex: 1;
        }
        .value {
            font-size: 14px;
            flex: 1;
            text-align: right;
        }
    }
    .controls {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            background: #FFFFFF !important;
            border: 1px solid #E6E6E6 !important;
            border-radius: 4px;
            width: 27px;
            height: 27px;
            padding: 5px;
            box-shadow: none;
            i {
                margin: 0 !important;
                transform: none !important;
            }
        }
    }
}