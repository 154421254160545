.CheckList {
  border: 1px solid #e2e2e3;
  border-radius: 4px;
  margin-top: 14px;
  &.no-border {
    border: none;
    border-bottom: 1px solid #e2e2e3;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 0;
  }
  p {
    margin: 0;
  }
  .CheckList-group {
    &:not(:first-child) {
      border-top: 1px solid #e2e2e3;
    }
    &:empty + .CheckList-group {
      border: none;
    }
    &:empty {
      display: none;
    }
    .CheckList-group_header {
      background-color: #f9fafb;
      border-bottom: 1px solid #e2e2e3;
      display: flex;
      align-items: center;
      padding: 0 14px;
      height: 36px;
      p {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .CheckListItem {
    padding: 7px 14px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    &:not(:last-child) {
      border-bottom: 1px solid #e2e2e3;
    }
    .title-row {
      display: flex;
      flex-grow: 2;
      padding-left: 8px;
    }
    p {
      font-size: 12.6px;
      strong {
        margin-right: 12px;
      }
      small {
        margin-left: 3px;
        color: #767676;
        font-size: 9px;
      }
    }
    .checklist-media {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .title-container {
      display: grid;
      grid-template-columns: 200px 1fr max-content;
      gap: 20px;
      .status-container {
        height: 27px;
        border: 1px solid #e2e2e3;
        border-radius: 4px;
        display: flex;
        align-items: center;
        .status-dropdown {
          border-right: none;
          i.icon.size-m {
            font-size: 12px;
            margin-top: -12px;
          }
          .menu {
            display: none;
            box-shadow: none;
            border: none !important;
          }
          .visible.transition {
            margin-top: 2px;
            div {
              border: 1px solid #e2e2e3;
              background: #fff;
              box-shadow: none;
              &:first-child {
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
              }
              &:last-child {
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
              }
            }
          }
        }
        .action-dropdown {
          border-right: none;

          i.icon {
            margin-top: -8px;
          }

          .menu {
            display: none;
            box-shadow: none;
            background-color: transparent;
            border: none !important;
          }

          .visible.transition {
            margin-top: 2px;
            width: fit-content;
            box-shadow: none !important;

            :hover {
              background-color: #fafafa;
            }
            div {
              border: 1px solid #e2e2e3;
              background-color: #fff;
              width: auto;
              min-width: 80px;
              display: flex;
              justify-content: left;
              align-items: center;
              box-shadow: none;
              height: 29px;
              font-weight: bold;
              white-space: nowrap;
              padding: 0 10px;

              i.icon {
                margin: 10px 10px 10px 0;
              }
              &:first-child {
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
              }
              &:last-child {
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
              }
            }
          }
        }
        i.icon {
          margin: 0px;
          &.pointer {
            cursor: pointer;
          }
        }
        div {
          height: 100%;
          border-left: 1px solid #e2e2e3;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:first-child {
            border-left: none;
          }
        }
      }
    }
  }
}

.dropdown-menu {
  background-color: transparent !important;
}
