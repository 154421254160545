.QRCodeContainer {
  .qr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .code-expired-text {
    color: #e03f3f;
  }

  .code-expiration-text {
    color: gray;
  }
}
