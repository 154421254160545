.RobModal {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 25px !important;
    .close_button {
      width: 36px;
      height: 36px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 18px;
      i.icon {
        font-size: 16px;
        margin: 0px 0px 0px 2px !important;
        transform: translateX(0px) !important;
      }
    }
  }
  .ui.segment {
    background-color: #f9fafb;
    border-color: #e2e2e3;
  }
  .divider {
    margin: 15px 0;
    padding: 15px 0px 10px 0px;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
  }
}

.ROB {
  .-appointment-status {
    border: 1px solid #ccc !important;
    background: #fff !important;
    &.Contract {
      border: 1px solid #21ba45 !important;
      background: #ccecd6 !important;
    }
    &.NoData {
      background: #fef0e7 !important;
      border: 1px solid #ff0202 !important;
    }
    &.Error {
      background: #fef0e7 !important;
      border: 1px solid #ff0202 !important;
    }
  }
}
