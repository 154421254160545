.AppointmentDetails {
  padding-bottom: 30px;
  p {
    padding: 0;
    margin: 0;
  }

  .AppointmentDetails-tabs {
    position: sticky;
    top: 39px;
    z-index: 2;
  }
  .AppointmentDetails-container {
    margin: 0 20px;

    .auto-width {
      width: auto !important;
    }

    .active-user {
      width: 25px;
      height: 25px;
      border: 1px solid #2185d0;
      border-radius: 360px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #2185d0;
      .text {
        font-size: 11px;
      }
    }

    .AppointmentDetails-NavigationHeader {
      padding-top: 22px;
      padding-bottom: 5px;
      position: sticky;
      background-color: #fff;
      top: 0px;
      z-index: 2;
      display: flex;
      align-items: center;
      .AppointmentDetails-NavigationHeader-container {
        padding: 10px 20px 0px 20px;
        border: 1px solid #e0e1e2;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 13px;
        box-shadow: 0px 3px 6px #00000029;
        width: 100%;
        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .sub-row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;
            height: 25px;
            button.ui {
              height: 25px;
            }
            .ui.icon.button {
              padding: 0 7px !important;
            }
            i.icon {
              margin-right: -2px !important;
              margin-top: -2px !important;
              font-size: 12px;
            }
            .Appointment-time {
              display: flex;
              gap: 24px;
              align-items: center;
              border: 1px solid #e2e2e3;
              border-radius: 4px;
              padding: 0 8px;
              &.small-gap {
                gap: 8.5px;
              }
              p {
                display: flex;
                align-items: center;
                font-size: 12.6px;
                margin: 0;
                padding: 0;
                strong {
                  margin-right: 4px;
                }
              }
              i.icon {
                margin-top: -12px !important;
                cursor: pointer;
              }
              .info-wo {
                padding: 0 5.5px;
                background-color: #fef0e7;
                color: #ff0202;
              }
            }
            .appointment-pin {
              margin-left: 2px;
            }
          }
        }
      }
    }
    .AppointmentStatus-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .AppointmentStatus-actions {
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        button.ui {
          height: 25px;
        }
        .ui.icon.button {
          padding: 0 7px !important;
        }
        i.icon {
          margin-right: -2px !important;
          margin-top: -2px !important;
          font-size: 12px;
        }
        .cancel-appointment {
          background-color: #cc1111;
          border-color: #cc1111;
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .appointment-pin {
        margin-left: 2px;
      }
    }
    .AppointmentDetails-tabs {
      position: sticky;
      top: 39px;
      z-index: 2;
    }
    .AppointmentDetails-cards {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .full {
        width: 100%;
        button.ui {
          height: 25px;
        }
        .ui.icon.button {
          padding: 0 7px !important;
        }
        i.icon {
          margin-right: -2px !important;
          margin-top: -2px !important;
          font-size: 12px;
        }
        .button {
          position: relative;
          i.icon {
            font-size: 12px !important;
          }
          i.icon.arrow.up {
            padding-left: 5px;
          }
          .secondary-button {
            height: 100%;
            border-left: 1px solid #e2e2e3;
            align-items: center;
            display: flex;
          }
        }
      }
      .end {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        .Appointment-time {
          display: flex;
          gap: 24px;
          align-items: center;
          border: 1px solid #e2e2e3;
          border-radius: 4px;
          padding: 0 8px;
          &.small-gap {
            gap: 8.5px;
          }
          p {
            display: flex;
            align-items: center;
            font-size: 12.6px;
            margin: 0;
            padding: 0;
            strong {
              margin-right: 4px;
            }
          }
          i.icon {
            margin-top: -12px !important;
            cursor: pointer;
          }
          .info-wo {
            padding: 0 5.5px;
            background-color: #fef0e7;
            color: #ff0202;
          }
        }
      }
      .between {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .button i.icon {
          font-size: 14px !important;
        }
        .row {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
}

.add-note-container {
  width: 30.52px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  border: 1px solid #e0e1e2;
  border-radius: 4px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #f9fafb;
  }
  i.icon {
    font-size: 14px !important;
    margin: -8.2px 0px 0px 0px !important;
  }
}

.custom-back-order-dropdown-icon {
  width: 25px;
  height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0em;
  margin-right: 5px;
  color: #6c0699;
  padding: 5px;
  border-radius: 4px;
  background-color: #6c0699;
  color: #fff;
}

.CancelAppointmentModal {
  &.ui.modal {
    border: 1px solid #e2e2e3;
    div.header {
      font-family: "Lato" !important;
      font-size: 18px !important;
      letter-spacing: 0px;
      color: #ffffff !important;
      opacity: 1;
      font-weight: bold;
    }
    .CustomConfirm-section {
      i.triangle {
        font-size: 48px;
      }
    }
    .CustomConfirm-inner-section-heading {
      font-size: 14px;
    }
    .CustomConfirm-section {
      gap: 20px !important;
      justify-content: center !important;
    }
  }
}

.AppointmentDetails-loader {
  display: flex;
  height: calc(100vh - 38px);
  justify-content: center;
  align-items: center;
}
