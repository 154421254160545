.InterventionSubitemsModal {
  .actions {
    justify-content: flex-end !important;
  }
  .ui.form .fields {
    display: flex;
    justify-content: space-between;
    padding: 0 7px;
  }
}

.ui.visible.dimmer {
  .InterventionSubitemsModal {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}
