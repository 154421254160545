.AppointmentHistory {
  padding-top: 16px;
  .date-wo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  span.weak {
    font-weight: 400;
    margin-right: 5px;
  }
  table:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  table:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  table {
    border-top: 2px solid #1dbf5e;
    border-radius: 0;
    position: relative;
    .divider {
      height: 1px;
      background-color: #e2e2e3;
      position: absolute;
      left: 0px;
      right: 0px;
      margin-top: -41px;
    }

    th:last-child {
      text-align: right;
      width: 180px;
    }

    th {
      text-align: center;
      padding: 10px 0;
    }

    td {
      padding: 0px;
      text-align: center;
    }

    .empty {
      height: 37px;
    }

    .workorder {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-decoration: none;
    }

    .item-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .small-status {
        margin-right: 10px;
        padding: 0px !important;
        .StatusCellIcon {
          height: 25px;
          i.icon {
            margin-top: -10px;
          }
        }
      }

      .button-icon {
        margin-right: 10px;
        padding: 0px !important;
        button {
          width: 32px;
          padding: 0 5px !important;
        }
        .circle-icon {
          height: 25px;
          width: 25px;
        }
      }
    }
  }

  .name-cell {
    margin: auto;
  }
}
