@import "../../../colorbox.scss";

.ResetFiltersButton {
  &__toolbar-button {
    height: 38px !important;
    width: 38px !important;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
    box-shadow: none !important;
    color: $limegreen-bg;
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__limegreen-color {
    color: $limegreen-bg !important;
  }

  &__reset-button-icons-container {
    display: flex;
    justify-content: center;
  }

  &__reset-button-icon-circle-xmark {
    margin: 3px 0px 0px -10px !important;
    font-size: 0.7em !important;
  }
}
