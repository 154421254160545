.table-view {
  .ui.celled.table {
    td {
      height: 30px;
      padding: 0 0 0 16px !important;
    }
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
    }
  }

  .clipboard-copy {
    cursor: pointer;
  }
}
