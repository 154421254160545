.Manufacturer {
  &__short-column {
    max-width: 60px;
  }
  &__tableColumn {
    &-actions {
      margin-right: 10px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 32px);
      gap: 6px;
      align-items: center;
      margin-left: auto;
      justify-items: center;
      width: max-content;

      &--cell {
        & > * {
          width: 32px !important;
          height: 25px !important;
          overflow: hidden;
          border-radius: 4px;
        }
      }
      &--requestStatus {
        .menu {
          transform: translateX(-82px);
        }
      }

      &--statusId {
        .menu {
          transform: translateX(-70px);
        }
      }
    }

    &-filters {
      display: flex;
      gap: 2px;
      align-items: center;

      p {
        margin-bottom: 0;
      }
    }

    &-iconContainer {
      & > * {
        transform: translate(-1.7px, -1.7px);
      }
    }
    &-openLink {
      max-width: 60px;
      a {
        display: flex;
        gap: 2px;

        i.icon {
          margin-top: 4px;
        }
      }
    }
  }

  &__copyWrapper {
    display: flex;
    align-items: center;
    min-width: 80px;
    i {
      display: none;
    }

    &--shortColumn {
      min-width: 60px;
    }

    &:hover {
      i {
        display: block;
      }
    }
  }
}
