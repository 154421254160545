.Header {
  background-color: #20bf5e;
  border-bottom: 1px solid #8edfae;
  height: 60px;
  .Header__column {
    height: 100%;
    &.-brand-column {
      padding-left: 0.75em !important;
      .icon {
        color: #fff;
        cursor: pointer;
        font-size: 1.5em;
      }
      .Header__brand {
        color: #fff;
        display: inline-block;
        font-size: 1em;
        letter-spacing: 0.5px;
        padding-left: 0.5em;
        padding-top: 0.65rem;
        transform: translateY(-3px);
        a {
          color: #fff;
          &:hover {
            color: #e6e6e6;
          }
        }
      }
    }
    &.-search-column {
      padding-top: 0.55rem !important;
      .input {
        font-weight: 300;
        width: 100%;
        .search.icon {
          color: #20bf5e;
        }
        input {
          background-color: #fff;
          border-radius: 5px;
          letter-spacing: 1px;
          transition: background-color ease 0.2s;
          &:focus {
            border-color: transparent;
          }
        }
        ::-webkit-input-placeholder {
          /* Chrome */
          color: rgb(87, 87, 87);
          font-size: 0.9em;
          font-weight: 300;
        }
        :-ms-input-placeholder {
          /* IE 10+ */
          color: rgb(87, 87, 87);
          font-size: 0.9em;
          font-weight: 300;
        }
        ::-moz-placeholder {
          /* Firefox 19+ */
          color: rgb(87, 87, 87);
          font-size: 0.9em;
          font-weight: 300;
          opacity: 1;
        }
        :-moz-placeholder {
          /* Firefox 4 - 18 */
          color: rgb(87, 87, 87);
          font-size: 0.9em;
          font-weight: 300;
          opacity: 1;
        }
      }
      .Header__search {
        .ui.search > .results .result {
          padding: 7px 7px;
          font-size: 0.9em;
        }
      }
    }
    &.-filter-column {
      padding-top: 0.7rem !important;
      .ui.dropdown.icon {
        background: #fff;
        border-radius: 5px !important;
        color: #000;
        font-size: 0.9em;
        font-weight: 400;
        letter-spacing: 0.7px;
        padding: 1em 1em 1em 3em !important;
        min-width: 155px;
      }
    }
    &.-user-menu-column {
      padding-top: 0.7rem !important;
      .UserMenu__avatar {
        &.icon {
          float: right;
          color: white;
          font-size: 1.3em;
          margin-top: 0.8rem;
        }
      }
    }
  }
  .-contained-large {
    max-width: 1920px;
    margin-top: -4px;
    display: flex;
    align-items: center;
  }
}

.status-offline {
  .Header {
    background-color: #757575 !important;
    border-bottom: 1px solid #757575 !important;
  }
  .DealerDropdown {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  .LocationDropdown {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.DealerDropdown {
  border: none !important;
  input {
    color: #fff;
    margin-top: 2px;
    padding-left: 36px !important;
  }
  &.active > .text:not(.default) {
    color: #a2a2a2 !important;
  }
}

.Header .Header__column.-brand-column .Header__brand {
  padding-top: 0px;
}
