.nav-bar-wrapper {
  height: 39px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #f9fafb;
  position: sticky;
  top: 0;
  z-index: 6;
  margin: auto;
  align-content: center;

  .nav-bar-content {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    max-width: 1920px;
    margin: auto;
  }

  .nav-bar-start {
    display: flex;
    align-items: center;
    gap: 15px;

    i {
      margin-top: -7px;
    }

    h4 {
      margin: 0px;
    }

    .button {
      padding: 0 8px;
      height: 26px;
      display: flex;
      align-items: center;
      box-shadow: 0px 0px 2px #00000029;
      border: 1px solid #e2e2e3;
      border-radius: 4px;
      cursor: pointer;

      i.icon {
        margin: -7px 0 0;
      }
    }

    .pointer {
      cursor: pointer;
      padding: 1px 0px 0px 5px;

      .small_profile_icon {
        width: 18px;
        height: 18px;
        margin-top: 8px;
        border-radius: 50%;
        border: 1px solid #323232;
      }

      .small_icon {
        width: 18px;
        height: 18px;
      }

      .menu {
        top: 135% !important;
      }

      .account_dropdown {
        border: 1px solid #e2e2e3;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        width: 225px;

        .item:nth-child(7) {
          background: #f9fafb !important;
          border-radius: 0px 0px 10px 10px;
        }

        .divider {
          margin: 0px;
          border-color: #e2e2e3;
        }

        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #000000;
          font-size: 14px;
          line-height: 17px;

          &:not(:first-child) {
            height: 41px;
          }
        }

        .profile_details {
          padding: 1rem !important;
          height: 146px;
          background: #f9fafb !important;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          pointer-events: none;

          .huge.icon {
            margin-right: 0;
          }

          .profile_avatar {
            border: 1px solid #767676;
            opacity: 1;
            height: 58px;
            width: 58px;
            border-radius: 50%;
            margin: 0 !important;
            max-height: unset !important;
          }

          h1 {
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            margin: 0;
          }

          p {
            margin: 0;
            margin-top: 7px;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0px;
            color: #414141;
            opacity: 1;
          }
        }
      }
    }
  }

  .nav-bar-dp {
    width: auto;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-self: flex-start;

    .mr-10 {
      margin-right: 10px;
    }

    .dp-container {
      display: flex;
      align-items: center;
      gap: 2px;
      border-radius: 4px;
      height: 21px;
      padding: 1px 1px 1px 5.5px;

      .white {
        color: #ffffff;
      }

      i.small.icon {
        margin-top: 0px;
      }

      .count {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 4px 4px 0px;
        width: 20px;
        height: 100%;
        background-color: #fff;
      }
    }

    .orange {
      background-color: #f49523;
    }

    .pale-yellow {
      background-color: #efc56b;
    }

    .red {
      background-color: #db2828;
    }

    .grey {
      background-color: #5f7d8b;
    }

    .yellow {
      background-color: #f8c02b;
    }

    .sky {
      background-color: #69bec0;
    }

    .pink {
      background-color: #ea2d62;
    }

    .blue {
      background-color: #2d85d0;
    }

    .purple {
      background-color: #6c0699;
    }
  }

  #navbar-portal-container {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    flex: 1;
  }

  .search-container {
    position: relative;

    .nav-bar-search {
      max-width: 600px;
      min-width: 500px;
      width: 100%;
      height: 26px;

      input {
        font-size: 12.6px;
        background-color: #f9fafb;
      }

      i.icon {
        font-size: 10px;
      }
    }

    .list-view {
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 2px;
    }
  }

  .nav-bar-map {
    min-width: 200px;
    max-width: 600px;
    width: auto;
    height: 26px;

    input {
      background-color: #f9fafb;
      padding-left: 12px;
      font-size: 12.6px;
    }

    i.icon {
      font-size: 10px;
      opacity: 1 !important;
    }
  }

  .manufacturer-link-wrapper {
    position: relative;

    .manufacturer-count {
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background-color: #c83627;
      font: normal normal bold 7px/12px Lato;
      color: #ffff;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: -5px;
    }
  }
}
