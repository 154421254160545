.FileDragDrop {
  text-align: center;
  border: 1px dotted #dededf;
  height: 61px;
  width: 100%;
  padding-top: 10px;
  overflow: hidden;

  &.active {
    opacity: 0.3;
    border: 2px dotted #1f1f1f;
  }
  input {
    visibility: hidden;
    height: 0;
  }
  span {
    font-size: 14px;
    font-weight: 600;
  }
  .browse-text {
    color: #2185d0;
    text-decoration: underline;
    cursor: pointer;
  }
  .max-file-size {
    color: #b5b5b5;
  }
  &.error {
    border-color: red;
  }
}
