.SnoozeModal {
    .add-snooze-modal-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button:first-child {
            margin-right: 5px;
        }
    }
    .update-snooze-modal-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div:nth-child(2) {
            button:first-child {
                margin-right: 5px;
            }
        }
    }
}