/*******************************
         Site Overrides
*******************************/

.ui.secondary.pointing.menu {
  border: 1px solid #e0e1e2;
  border-radius: 10px;
  padding: 5px 15px 1px 15px;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
}
.ui.secondary.pointing.menu.item {
  padding: 15px 0;
}

.ui.secondary.pointing.menu .item {
  padding: 15px;
  font-size: 13px;
  font-weight: bold;
}
.ui.secondary.pointing.menu .active.item {
  border-width: 3px;
  padding: 15px 0;
  margin: 0 15px -2px 15px;
}