.AppointmentNoteTypeIconLabel {
  width: 35px;
  height: 25px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  text-align: center;

  i {
    margin-right: 0px;
  }
  .carNoteIcon {
    position: relative;
    top: 0.5px;

    color: #ffff;
  }
}
