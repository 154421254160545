.datepicker-wrapper {
  border: 1px solid #e2e2e3;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 180px;
  height: 36px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 9px 12px;
  &.disabled {
    cursor: not-allowed;
    .datepicker-row {
      .date {
        color: #b5b5b5;
      }
    }
  }
  &.fluid {
    max-width: 100%;
  }
  .datepicker-row {
    z-index: 2;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    width: 100%;
    .label {
      color: #b5b5b5;
      margin: 0px;
    }
    .date {
      margin: 0px 9px;
      color: #000000;
    }
  }
  .react-datepicker-wrapper {
    position: absolute;
    z-index: 1;
    visibility: hidden;
    margin-left: -15px;
  }

  .datepicker-icons {
    display: flex;
    gap: 4px;

    .icon.dropdown {
      position: relative;
      bottom: 5px;
    }
  }

  .clear-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      margin: 0;
    }
  }
}

.datepicker-container {
  background-color: #fff;
  .react-datepicker__header {
    background-color: #fff;
  }
}

.react-datepicker__day {
  &--range,
  &--in-range {
    background-color: #b5b5b5 !important;
    color: white !important;
  }

  &--in-selecting-range {
    background-color: #b5b5b5 !important;
    color: white;
  }

  &--range-start {
    background-color: #21ba45 !important;
    color: white !important;
  }

  &--today {
    background-color: #2d84ce;
    color: white !important;
    border-radius: 4px;
  }
}

.react-datepicker__day--selected:not(.react-datepicker__day--range-start):not(.react-datepicker__day--in-range):not(.react-datepicker__day--today) {
  background-color: #21ba45 !important;
  color: white !important;
}

.react-datepicker__day:focus-visible {
  outline: none !important;
  border: none !important;
}

.react-datepicker__portal {
  position: absolute;
}

.react-datepicker__children-container {
  width: 100% !important;
  margin: 0.4rem 0 !important;
}

.react-datepicker-clear-date-container {
  text-align: center;
  cursor: default;
}

.react-datepicker-clear-date-button {
  margin: 10px 0px 20px 0px !important;
}

.AppointmentDate {
  .datepicker-wrapper {
    border: none;
    padding: 0;
    height: auto;
    input {
      border: none;
      width: auto;
      max-width: 80px;
      min-width: auto;
      border-bottom: 1px solid #ccc;
      caret-color: transparent;
      color: transparent;
      text-shadow: 0 0 0 #000000;
      &:active,
      &:focus,
      &:focus-visible {
        outline: none !important;
        outline-width: 0 !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
  }
}
