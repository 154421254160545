.CustomDateRangeInput {
  display: flex;

  .button {
    box-shadow: none;
    background-color: transparent;
    border: none;
    flex: auto;
    cursor: pointer;
  }

  .dates-container {
    display: flex;
    align-items: center;
  }

  .date-container {
    flex: auto;
    margin-left: 16px;
  }

  .date-right-container {
    flex: auto;
  }

  .date-text {
    display: flex;
    font-size: 14px;
  }

  .dates-divider {
    height: 36px;
    border-left: 1px solid rgba(34, 36, 38, 0.15);
    margin: 0 16px;
  }
}
