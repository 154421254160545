.main-container {
    &-segment {
        margin: 0 !important;
        padding: 0 !important;
    }
    &-pushable {
        overflow-x: visible !important;
        min-height: 100vh !important;
    }
    &-pusher-dimmed {
        overflow: visible !important;
        min-height: 100vh !important;
    }
    .content-container {
        max-width: 1920px;
        margin: auto;
        overflow-x: hidden;
        overflow-y: scroll;
        height: calc(100dvh - 39px);
    }
}

.pushable:not(body) {
    transform: none;
}

.pushable:not(body)>.ui.sidebar,
.pushable:not(body)>.fixed,
.pushable:not(body)>.pusher:after {
    position: fixed;
}