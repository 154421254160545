.status-filters-wrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  .StatusFilters-buttons {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .filter-status-face-icon {
      margin-top: 2px;
      img {
        width: 18px;
        height: 18px;
      }
    }
    button {
      cursor: pointer;
      width: 34px;
      height: 30px;
      transition: 0.1s opacity ease-in-out;
      color: #000 !important;
      &:hover {
        opacity: 0.5;
      }

      &.ui.button.af {
        i.outOfShop {
          margin-left: -20px !important;
        }
      }
    }
  }
}
