.Dayplanner {
    &-AppointmentBrandsFilter {
        width: auto;
        display: flex;
        align-items: center;
        gap: 5px;
        justify-self: flex-start;
        margin-right: 10px;
        .menu {
            max-height: 400px;
            min-width: 220px !important;
            overflow-y: auto;
        }
        &-Dropdown {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #F8F9FA 0% 0% no-repeat padding-box;
            border: 1px solid #E2E2E3;
            border-radius: 4px;
            i {
                margin: 0;
                height: auto;
            }
            &-Selection {
                p.text {
                    font: normal normal normal 14px/16px Lato;
                    letter-spacing: 0px;
                    color: #000000CC;
                }
            }
            .composite-icons {
                position: relative;
                width: 25px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                i {
                    color: #21BA45;
                    &.tag {
                        position: absolute;
                        right: 3px;
                        bottom: 9.5px;
                        height: 12px;
                        font-size: 8px;
                    }
                    &.car {
                        font-size: 10px;
                    }
                }
            }
            .filter-length {
                width: 25px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-left: 1px solid #E2E2E3;
                font: normal normal bold 9px/11px Lato;
                letter-spacing: 0px;
                color: #414141;
            }
        }
        .selected.item {
            background: #F8F9FA 0% 0% no-repeat padding-box !important;
            border: 1px solid #E2E2E3 !important;
            p.text {
                font-weight: bold !important;
            }
        }
        .dropdown.icon {
            display: none;
        }
    }
}