.dealer-quality-checks-tabular {
  .details-card-container {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .details-card-filters-container {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-right: 10px;
  }

  .table-container {
    margin-top: 10px;
  }

  .wo-nr {
    cursor: pointer;
  }
}
