.note-suggestions-modal {
  .noteSuggestionHeader {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    h3 {
      margin: 0;
    }
  }

  .sortable-item-note-suggestion {
    .delete-note-suggestion {
      background-color: transparent;
      position: relative;
      border: 1px solid #dededf !important;

      &:hover {
        background-color: transparent;
        opacity: 0.6;
      }

      i {
        position: absolute;
        top: 50%;
        left: 60%;
        transform: translate(-50%, -50%) !important;
      }
    }
  }

  .note-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;

    .note-toggle-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .add-note-button {
      background-color: #21ba45;
      border-radius: 4px;
      display: flex;
      cursor: pointer;
      align-items: center;

      .plus-wrapper {
        padding: 8px 10px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: #1fb142;

        i {
          color: #ffff;
          margin: 0;
        }
      }

      span {
        color: #fff;
        padding: 8px 20px;
      }
    }
  }

  .note-modal-actions {
    justify-content: flex-end !important;

    & > * {
      display: flex;
      align-items: center;
      height: 35px;
    }
  }
}
