/******************************* Icon *******************************/
/*-------------- Font Files ---------------*/
/* Solid Icons */
  @fontName: 'icons';
  @src: url("@{fontPath}/@{fontName}.woff2") format('woff2');

/* Regular Icons */
  @importRegularIcons: true;
  @regularFontName: 'regular-icons';
  @regularSrc: url("@{fontPath}/@{regularFontName}.woff2") format('woff2');

/* Thin Icons */
  @importThinIcons: true;
  @thinFontName: 'thin-icons';
  @thinSrc: url("@{fontPath}/@{thinFontName}.woff2") format('woff2');

/* Light Icons */
  @importLightIcons: true;
  @lightFontName: 'light-icons';
  @lightSrc: url("@{fontPath}/@{lightFontName}.woff2") format('woff2');

/* Brand Icons */
  @importBrandIcons: true;
  @brandFontName: 'brand-icons';
  @brandSrc: url("@{fontPath}/@{brandFontName}.woff2") format('woff2')

/*-------------- Definition ---------------*/
/* Icon Variables */
@opacity: 1;
@width: @iconWidth;
@height: 1em;
@distanceFromText: 0.25rem;

/* Variations */
@linkOpacity: 0.8;
@linkDuration: 0.3s;
@loadingDuration: 2s;
@circularSize: 2em;
@circularPadding: 0.5em 0;
@circularShadow: 0 0 0 0.1em rgba(0, 0, 0, 0.1) inset;
@borderedSize: 2em;
@borderedVerticalPadding: ((@borderedSize - @height) / 2);
@borderedHorizontalPadding: 0;
@borderedShadow: 0 0 0 0.1em rgba(0, 0, 0, 0.1) inset;
@cornerIconSize: 0.45em;
@cornerIconStroke: 1px;
@cornerIconShadow:
  -@cornerIconStroke -@cornerIconStroke 0 @white,
  @cornerIconStroke -@cornerIconStroke 0 @white,
  -@cornerIconStroke @cornerIconStroke 0 @white,
  @cornerIconStroke @cornerIconStroke 0 @white;
@cornerIconInvertedShadow:
  -@cornerIconStroke -@cornerIconStroke 0 @black,
  @cornerIconStroke -@cornerIconStroke 0 @black,
  -@cornerIconStroke @cornerIconStroke 0 @black,
  @cornerIconStroke @cornerIconStroke 0 @black;
@mini: 0.4em;
@tiny: 0.5em;
@small: 0.75em;
@medium: 1em;
@large: 1.5em;
@big: 2em;
@huge: 4em;
@massive: 8em;
