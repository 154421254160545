/*******************************
         Site Overrides
*******************************/

h1 {
    font-size: @h1;
    line-height: 36px;
    font-family: Lato;
}

h2 {
    font-size: @h2;
    line-height: 30px;
    font-family: Lato;
}

h3 {
    font-size: @h3;
    line-height: 23px;
    font-family: Lato;
}

h4 {
    font-size: @h4;
    line-height: 19px;
    font-family: Lato;
}

h5 {
    font-size: @h5;
    line-height: 18px;
    font-family: Lato;
}

.-subheader-two {
    font-size: 12px;
    line-height: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: #1B1C1D99;
}

.-subheader-one {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

body, p {
    font-size: 16px;
    line-height: 24px;
    font-family: Lato;
}

.-body-text {
    font-size: 14px;
    line-height: 22px;
}

.-small-text {
    font-weight: 12px;
    line-height: 15px;
}

.-price {
    font-size: 14px;
    line-height: 20px;
}

.ReactTable {
  width: 100%;
  border-spacing: 0px;
  border-top: 1px solid #e2e2e3;
  border-right: 1px solid #e2e2e3;
  border-left: 1px solid #e2e2e3;
  text-align: left;
  border-radius: 4px;
  
  thead {
    background-color: #f9fafb;
     padding: 0 10px;
    th {
      font-size: 12.6px;
    }
  }

  tr {
    height: 36px;
    font-size: 12.6px;
  }

 th {
    padding-left: 12px;
    border-bottom: 1px solid #e2e2e3;
  }

  td {
    border-bottom: 1px solid #e2e2e3 !important;
    padding-left: 12px;
    font-size: 12.6px;
    .ellipsis {
      font-size: 12.6px;
      white-space: nowrap;
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
