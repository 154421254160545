.CustomerCommunicationModal {
    overflow-x: hidden;
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h1 {
            color: #000000de;
            font-size: 18px;
            line-height: 23px;
            font-family: "Lato";
            font-weight: bold;
            margin: 0px;
        }
        span {
            width: 36px;
            height: 36px;
            border: 1px solid #e2e2e3;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            i.icon {
                margin: 0px !important;
                width: unset !important;
                height: unset !important;
                color: #767676;
            }
        }
    }
    .field {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .email-field {
        label {
            font: normal normal bold 14px/15px "Lato";
            color: #000000cc;
            width: 100px;
        }
        &.three {
            width: 100%;
        }
        &.two {
            width: 100%;
            label {
                width: 200px;
            }
        }
        &.note-field {
            label {
                width: 7%;
            }
            .error {
                input {
                    background: #FFF6F6;
                    border-color: #E0B4B4;
                    color: #9F3A38;
                }
            }
        }
    }
    &-divider {
        height: 1px;
        background-color: #e2e2e3;
        width: 200%;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: -50%;
    }
    &-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #e0e1e2;
        border-radius: 10px;
        padding: 20px;
    }
    &-action {
        .inner {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            .download-btn,
            .save-btn {
                background: #21ba45 0% 0% no-repeat padding-box;
                border-radius: 4px;
                height: 36px;
            }
            .download-btn {
                margin-right: 10px;
            }
        }
    }
    .DetailsCards-container {
        .end {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            gap: 8px;
            p {
                color: #000000CC;
                font-size: 13px;
                font-family: "Lato";
                font-weight: normal;
                margin: 0px;
            }
        }
        &.error {
            border-color: #E0B4B4;
        }
    }
}