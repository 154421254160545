@import "../../../../../../colorbox.scss";

.activity-step-icon-color {
  color: $limegreen-bg !important;

  &.svg-face-icon {
    display: inline-flex;

    svg {
      fill: $limegreen-bg !important;
      width: 1.1em;
      height: auto;
      margin-right: 5px;
    }
  }
}

.bo-icon {
  display: inline-flex;
  font-weight: 600;
  font-size: 12px;
  color: #21ba45;
  margin-right: 1px;
  position: relative;
  left: -1px;
}
