.InvoiceModal {
    .InvoiceModal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    
        button {
          width: 35px;
          margin-right: 20px;
          i.icon {
            margin-top: -4px !important;
          }
        }
      }
    .InvoiceModal-Content {
        width: 100%;
        height: calc(100vh - 200px);
        display: flex;
        justify-content: center;
        align-items: center;
    
        iframe {
          border: none;
          width: 100%;
          height: calc(100vh - 200px);
        }
      }
    
      .InvoiceModal-Content:focus {
        outline: none;
      }
}