.DayplannerStatusFilter {
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid;
  height: 24px;

  &:hover {
    box-shadow: 0px 3px 6px #00000029;
  }

  &.disabled {
    border-color: #767676 !important;

    .DayplannerStatusFilter-icon {
      background-color: #767676 !important;
      color: #e2e2e3;
    }

    .DayplannerStatusFilter-count {
      background-color: #e2e2e3;
    }
  }

  &.active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 10px;
    padding: 5px 6px;
    height: 100%;
    width: 24px;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;

    i.icon {
      width: auto;
      height: auto;
      margin: 0;
    }

    .backorder-icon span {
      font-size: 10px;
      line-height: 10px;
    }
  }

  &-count {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    font-weight: bold;
    width: 20px;
    height: 100%;
    font-size: 9px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.DayplannerStatusFilters {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
}
