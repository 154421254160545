.ImageLetters {
  .img-icon-wrapper {
    height: 27px;
    color: #5d7c8c;
    border: 2px solid #5d7c8c;
    border-radius: 2px;
    padding: 2px 5px;
    display: flex;
    gap: 5px;
    align-items: center;
    font-family: monospace;
    margin-right: 5px;
  }

  .img-icon-wrapper .report-car-images-letter {
    font-weight: 700;
    line-height: 19px;
    display: inline-block;
    vertical-align: top;
  }
}
