@import "../../colorbox.scss";

.LeadsDashboard {
  .toolbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 9px 9px 5px 0px;
  }

  .toolbar-grid {
    width: 100%;
    margin: 0px 9px 0px 22px;
  }

  .toolbar-button {
    height: 38px !important;
    width: 38px !important;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
    box-shadow: none !important;
    color: $limegreen-bg;
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table-container {
    padding: 0px 10px 55px 10px;
  }

  .toolbar-reset-export-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
    margin-right: 12px;
  }

  .table-header-text {
    font-size: 13px;
    color: #000000cc;
    font-weight: 900;
  }

  .right-gutter {
    padding-right: 4.5px !important;
  }
  .left-gutter {
    padding-left: 4.5px !important;
  }
  .no-right-gutter {
    padding-right: 0px !important;
  }
  .no-left-gutter {
    padding-left: 0px !important;
  }

  .limegreen-color {
    color: $limegreen-bg !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
    visibility: visible;
    cursor: pointer;
  }

  .datepicker-wrapper {
    height: 38px;
    cursor: pointer;
  }

  .flex-align-justify-center {
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
  }

  .filters-checkbox-container {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .filters-checkboxes-container {
    display: flex;
    gap: 7px;
    width: 100%;
    justify-content: space-evenly;
  }

  .table-action-buttons-container {
    display: flex;
    gap: 6px;
    justify-content: flex-end;
    margin-right: 10px;
  }

  .table-icon-placeholder-div {
    width: 32px;
    height: 25px;
  }

  .default-cursor {
    cursor: default;
  }
}
