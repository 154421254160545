.TyreReplacementTableItem {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.-composite-button {
      button.ui.button {
        padding: 0;
        width: 32.5px;
      }

      button:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      button.tyre-team-order-button {
        border-radius: 0 4px 4px 0;
        background-color: #fff;
        border-color: #e2e2e3;
        border-left: none;
      }
    }
  }

  .replace-with {
    margin-left: 37px;
  }

  .tyre-team-order-button {
    border-left: none;
  }
}
