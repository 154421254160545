.mcc-status-0 {
	color: #000000;
	background: #ffffff !important;
}

.mcc-status-1 {
	color: #c6c6c6;
	background: #eaeaea !important;
}

.mcc-status-2 {
	color: #ff0000;
	background: #ffeeee !important;
}

.mcc-status-3 {
	color: #00ff00;
	background: #eeffee !important;
}

.mcc-status-4 {
	color: #0000ff;
	background: #eeeeff !important;
}

.mcc-status-5 {
	color: #ffff00;
	background: #ffffee !important;
}

.mcc-status-6 {
	color: #ff00ff;
	background: #ffeeff !important;
}

.mcc-status-default {
	color: #ffffff;
	background: red !important;
}