.CustomerActions {
  .popup-heading {
    font-family: "Lato" !important;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
  }
  .CustomerActions-items {
    border: 1px solid #e2e2e3;
    border-radius: 4px;
    &:first-child {
      margin-top: 20px;
    }
  }
  display: flex;
  flex-direction: column;
  .actions-container {
    min-height: 44px;
    height: fit-content;
    margin-top: 0 !important;
    &:not(:last-child) {
      border-bottom: 1px solid #e2e2e3;
    }
    .icon-container {
      height: 25px;
      width: 25px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      i.icon {
        color: #fff;
        margin-top: -13px;
        margin-left: 3px;
        font-size: 10px;
      }
      &.skyblue {
        background-color: #78b3e5;
      }
      &.blue {
        background-color: #5279d1;
      }
      &.green {
        background-color: #21ba45;
      }
      &.disabled {
        background-color: grey;
      }
    }
    .heading {
      margin-left: 8px;
      font-size: 12.6px;
      color: #000000cc;
      font-weight: 700;
    }
    .title {
      margin-left: 8px;
      font-size: 12.6px;
      color: #000000cc;
      font-weight: 700;
    }
    .description {
      margin-left: 5px;
      font-size: 12.6px;
      color: #000000cc;
    }
    .end-grid {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      .status-container {
        height: 27px;
        border: 1px solid #e2e2e3;
        border-radius: 4px;
        display: flex;
        align-items: center;
        p {
          font-size: 12.6px;
          strong {
            margin-right: 12px;
          }
          small {
            margin-left: 3px;
            color: #767676;
            font-size: 9px;
          }
        }
        .status-dropdown {
          border-right: none;
          i.icon.size-m {
            font-size: 12px;
            margin-top: -12px;
          }
          .menu {
            display: none;
            box-shadow: none;
            border: none !important;
          }
          .visible.transition {
            margin-top: 2px;
            div {
              border: 1px solid #e2e2e3;
              background: #fff;
              box-shadow: none;
              &:first-child {
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
              }
              &:last-child {
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
              }
            }
          }
        }
        .action-dropdown {
          border-right: none;
          i.icon {
            margin-top: -8px;
          }
          .menu {
            display: none;
            box-shadow: none;
            border: none !important;
          }
          .visible.transition {
            margin-top: 2px;
            width: fit-content;
            box-shadow: none !important;
            :hover {
              background-color: #fafafa;
            }
            div {
              border: 1px solid #e2e2e3;
              background-color: #fff;
              width: 100%;
              display: flex;
              justify-content: left;
              align-items: center;
              box-shadow: none;
              height: 29px;
              font-weight: bold;
              i.icon {
                margin: 10px 10px 10px 0;
              }
              &:first-child {
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
              }
              &:last-child {
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
              }
            }
          }
        }
        i.icon {
          margin: 0px;
          &.pointer {
            cursor: pointer;
          }
        }
        div {
          height: 100%;
          border-left: 1px solid #e2e2e3;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:first-child {
            border-left: none;
          }
        }
      }
    }
  }
  .grid {
    margin: 0;
    .column:not(.row) {
      display: flex;
      align-items: center;
      padding: 9px 13px;
    }
  }
}

.InfoPopup {
  .InfoTitle {
    display: flex;
    margin-bottom: 7px;
    align-items: center;
    font-family: "Lato" !important;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    svg {
      position: relative;
      margin-right: 7px;
      bottom: 1px;
    }
  }
  .InfoDescription,
  .InfoContent {
    font-family: "Lato" !important;
    font-weight: normal;
    font-size: 12.6px;
    letter-spacing: 0px;
    color: #000000;
    span {
      text-transform: capitalize;
    }
  }
  .InfoContent {
    margin-top: 7px;
  }
}

.image-action {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e2e3;
  border-radius: 4px;
  gap: 4px;
  height: 27px;
  width: 32px;
  margin-right: 6px;

  i.icon {
    margin: 0;
    margin-top: 2.4px;
    font-size: 0.75em;
  }

  small {
    font-size: 9px;
    color: #767676;
  }
}
