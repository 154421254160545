.MechanicBox {
  display: flex;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d4d4d5;
  border-radius: 4px;
  padding: 10px;

  &-pinned {
    cursor: pointer;
    i {
      margin-right: 0;
    }
  }

  &.-no-appointments {
    justify-content: flex-end;

    .MechanicBox-side {
      flex-direction: row;
      column-gap: 10px;
    }

    .mechanic-name {
      font-size: 12.6px;
      line-height: 15px;
      font-weight: 700;
      font-family: "Lato";
    }
  }

  &-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 12px;
    height: 100%;
    justify-content: space-between;
    row-gap: 4px;
  }

  &-profile {
    img {
      height: 25px;
      width: 25px;
      border-radius: 50px;
      max-width: 100%;
      vertical-align: top;
    }

    > div {
      display: flex;
      height: 25px;
      width: 25px;
      border-radius: 50px;
      background-color: grey;
      color: #fff;
      font-size: 12px;
      justify-content: center;
      align-items: center;
    }
  }

  &-name {
    margin-left: 8px;
    font-size: 13px;
    font-weight: 600;
  }

  &-appointmentCards {
    display: grid;
    direction: rtl;
    gap: 10px;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);

    @media only screen and (max-width: 1750px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 1500px) {
      grid-template-columns: 1fr;
    }
  }

  &-placeholder {
    height: 91px;
    padding: 8px 12px;
    width: auto;
    position: relative;

    &:not(.-empty) {
      border: 1px dashed #d4d4d5;
      border-radius: 4px;
      background-color: #f2f2f2;
      transition: 0.1s border ease-in-out;
      background-image: url("./dayplanner-placeholder.svg");
      background-repeat: no-repeat;
      background-size: 125px;
      background-position: 50% 50%;

      &.-on-drag-hover {
        border: 1.5px dashed #000000;
        background-image: none;
        .MechanicBox-placeholder__number {
          display: none;
        }
      }

      &.-disabled {
        pointer-events: none;
        cursor: no-drop;
      }

      &.-no-pointer {
        pointer-events: none;
        cursor: default;
        background-color: red !important;
      }

      &.-inactive {
        opacity: 0.45;
      }
    }

    &__number {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 29px;
      width: 29px;
      background-color: #c7c7c7;
      box-shadow: 0px 0px 6px #00000029;
      border-radius: 20px;
      color: #707070;
      font-size: 0.9rem;
      cursor: default;
    }
  }

  &-appointments {
    height: 100%;

    .ui.basic.label.extend-appointments-button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 27px;
      height: 30px;
      padding-bottom: 2px;
      padding-top: 5px;
      box-shadow: 0px 0px 2px #00000029;
      cursor: pointer;

      &.-disabled {
        background-color: #f9fafb;
        cursor: default;
        padding-top: 2px;
        > span:first-child {
          color: #d4d4d5;
        }
      }

      &.-border-green {
        border: 1px solid #21ba45;
      }

      &:not(.-no-pointer) > span:first-child {
        margin-top: 3px;
      }

      > span:first-child {
        font-size: 12.6px;
        font-weight: 700;
        color: #000000de;
      }

      .extend-appointments {
        display: block;
        i.ellipsis {
          margin: 0;
        }
      }
    }
  }

  &-toggle {
    height: 100%;

    .ui.basic.label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 27px;
      box-shadow: 0px 0px 2px #00000029;
      cursor: pointer;
    }
  }

  .-no-pointer {
    pointer-events: none;
    cursor: default;
  }
}

.-column-not-allowed-to-drop {
  opacity: 0.5;
}

@media (max-width: 1440px) {
  &-board {
    overflow-x: auto;
  }

  &-columnContainer {
    min-width: auto !important;

    &.-mechanic-tasks {
      width: auto;
    }

    &.-collapsed {
      .Dayplanner-column {
        width: 50px !important;
      }
    }
  }

  &-column {
    width: 220px !important;

    &.-mechanics-tasks {
      scroll-behavior: smooth;
      width: 100% !important;
    }
  }
}
