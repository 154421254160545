.status-message {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dotted #ccc;
  height: 61px;
}

.image-url-delete {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 3px;

  i {
    cursor: pointer;
  }

  div {
    gap: 3px;
    display: flex;
    a {
      color: darken(#93abd5, 12);
    }
  }
}
