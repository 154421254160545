.Tyres {
  h4 {
    margin-top: 20px;
  }
  p {
    margin: 0;
  }
  .flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 70px;
    .tyre-container {
      width: 100%;
      .header {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f8f8f8;
        height: 38px;
        h4 {
          margin-top: 0;
        }
      }
      .tyre-indicator {
        border-radius: 23px;
        height: 46px;
        width: 46px;
        background-color: #e8f1e3;
        border: 2px solid #188d45;
        display: flex;
        align-items: center;
        justify-content: center;
        &.red {
          border-color: #eb1f1f;
          p {
            color: #eb1f1f;
          }
        }
        &.orange {
          border-color: #eb7a1f;
          p {
            color: #eb7a1f;
          }
        }
        p {
          color: #188d45;
          font-size: 23px;
          font-weight: 500;
        }
      }
    }
    &.row-reverse {
      flex-direction: row-reverse;
    }
  }
  .tyre-details-font {
    font-size: 16px;
    font-weight: 600;
    color: #556e80;
  }
  .flex-row.padding {
    padding: 12px;
  }
  .flex-row.small-gap {
    gap: 14px;
  }
  .flex-row.start {
    justify-content: start;
  }
  .flex-row.end {
    justify-content: end;
  }
  .tyre-details {
    margin-top: 20px;
    width: 100%;
  }
  .tyre-details.right {
    text-align: right;
  }
}
