.TyreTableItem {
  .tyre-position-container {
    display: flex;
    align-items: center;
  }

  .tyre-replacement-icon-container {
    cursor: pointer;
    margin: 4px 10px 0px 5px;
  }
}
