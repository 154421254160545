.ItemsNotRepaired {
  padding-top: 16px;

  .item-buttons {
    height: 25px;
    border: 1px solid #e2e2e3;
    display: flex;
    align-items: center;
    padding: 0 9.25px;
    border-radius: 4px;

    i.icon {
      margin: -5px 0 0 0 !important;
    }

    div {
      height: 25px;
      padding: 0;
    }
    div:not(:first-child) {
      border-left: 1px solid #e2e2e3;
      padding: 0 1px 0 7px;
    }
  }
}
