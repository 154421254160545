.ChecklistSectionContent {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;

  .checklist-notes {
    margin-bottom: 14px;
  }

  .section-header {
    font-weight: 600;
    background: #f7f7f7;
    padding: 14px;
    font-size: 1.25rem;
    color: #1d3d52;
    background-color: rgba(33, 132, 208, 0.29);

    span {
      font-size: 12px;
    }
  }

  .checklist-item {
    color: #1d3d52;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    border-color: rgba(187, 194, 198, 0.75);
    padding: 0 14px;
  }

  .checklist-item-title {
    font-size: 17px;
    font-weight: bold;
  }

  .checklist-item-description {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 10px;
    margin-top: 6px;
  }

  .checklist-item-text-image-letter {
    display: flex;
    align-items: center;
  }
}
