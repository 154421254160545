.upload__profile {
    position: relative;
    width: 119px;
    height: 119px;
    cursor: pointer;
    input {
        display: none;
    }
    &:hover>.avatar-action-container {
        display: block;
    }
    .upload__profile-button {
        border: 3px solid #b5b5b5;
        height: 100%;
        background: #f7f7f7 0% 0% no-repeat padding-box;
        border-radius: 100%;
        .button {
            border-radius: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                >.add-avatar {
                    z-index: 1;
                }
                i.icon.no-avatar {
                    display: none;
                }
                >.avatar-action-container {
                    display: block;
                }
            }
            .add-avatar {
                display: none;
                z-index: -1;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0.6) 0% 0% no-repeat padding-box;
                i.icon {
                    color: #fff;
                    margin-right: 0;
                }
            }
        }
        i.icon.no-avatar {
            margin: 0px;
            text-align: unset;
            width: unset;
            color: #b5b5b5;
        }
        img {
            object-fit: cover;
            width: 119px;
            height: 119px;
            border-radius: 100%;
            border: 3px solid #b5b5b5;
        }
    }
    .avatar-action-container {
        display: none;
        position: absolute;
        top: 105%;
        left: 50%;
        transform: translateX(-50%);
        width: 175px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #e2e2e3;
        border-radius: 4px;
        opacity: 1;
        z-index: 10;
        .action {
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 4px 10px;
            &.upload-section {
                border-bottom: 1px solid #E2E2E3;
            }
            span {
                position: unset !important;
                font: normal normal normal 14px/16px "Lato";
            }
            span.remove {
                color: #ff0000;
            }
            i.icon {
                margin: 0px !important;
                padding: 0px !important;
                height: unset;
            }
        }
    }
}