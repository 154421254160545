.IndicatorsAction {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 25px;

  .IndicatorsAction-kiosk-label {
    cursor: pointer;
    height: 25px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    gap: 10px;
    background-color: #21ba45 !important;
    color: #fff !important;
    padding: 7.78px;
    i.icon.tag {
      margin-top: 0px !important;
      font-size: 13px;
    }
    span {
      font-size: 12.6px;
    }
  }

  button.ui {
    height: 25px;
  }
  .ui.icon.button {
    padding: 0 7px !important;
  }
  i.icon {
    margin-right: -2px !important;
    margin-top: -2px !important;
    font-size: 12px;
  }

  p {
    margin: 0;
    font-size: 11px;
    font-weight: bold;
  }

  .container {
    border: 1px solid #e2e2e3;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 10px;
    height: 25px;

    .dropdown {
      .list-container {
        margin-top: -3px !important;
        display: flex;
        align-items: baseline;
        gap: 8px;

        .yellow-shop {
          color: #cccc00 !important;
        }
      }

      .menu {
        top: 23px;
        left: -5px;
      }
    }

    i.icon.shopping.cart.yellow-shop {
      color: #cccc00 !important;
    }

    .checkbox {
      width: 24px !important;
      height: 11px !important;
      min-height: 11px !important;

      &.checked {
        label:after {
          left: 13px !important;
        }
      }

      label:before {
        height: 11px;
        width: 24px;
      }
      label:after {
        height: 11px;
        width: 11px;
      }
    }
  }

  .dms-container {
    display: flex;
    gap: 8.5px;
    align-items: center;
    border: 1px solid #e2e2e3;
    border-radius: 4px;
    padding: 0 8px;

    strong {
      margin-right: 3px;
    }

    i.icon {
      margin-top: -12px !important;
      cursor: pointer;
    }
  }
}

#car_in_shop_a4_content {
  width: 100% !important;
  margin: 0 !important;
  padding: 25px !important;

  @page {
    size: A4 !important;
    margin: 22mm 64mm !important;
  }

  div {
    width: 100% !important;
    margin: 0 !important;
    padding: 10px !important;
    font-size: 16px !important;
    line-height: 18px !important;
    text-align: center !important;
  }

  .car_in_shop_print_large {
    font-size: 34px !important;
    line-height: 36px !important;
  }

  .car_in_shop_print_bold {
    font-weight: bold !important;
  }
}

#car_in_shop_label_content {
  width: 62mm !important;
  margin: 0 auto !important;
  padding: 0 !important;

  @media print {
    height: 29mm !important;
  }

  @media screen {
    margin: 25px auto !important;
  }

  @page {
    size: 62mm 29mm !important;
    margin: 0 !important;
  }

  div {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 16px !important;
    line-height: 17px !important;
    text-align: center !important;
  }
}

.ui.modal.IndicatorsActionModal {
  .actions {
    display: flex;
    gap: 16px;
    justify-content: flex-end;

    .button {
      color: #414141;
      padding: 0 15px 0 10px;
      margin: 0;
      i.icon {
        margin-right: 20px;
      }
    }

    .button.light {
      color: #414141;
      padding: 0;
      margin: 0;
      height: 36px;
      width: 36px;
      i.icon {
        margin: -4px 0 0 -18px !important;
      }
    }
  }
}
