.CarDetails {
  margin-top: 9px;
  display: flex;
  gap: 20px;

  .car-image-container {
    width: 218px;
    max-height: 182px;
    background-color: #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 5px;

    .car-image {
      border-radius: 4px;
      width: 218px;
      max-height: 182px;
    }
  }

  .car-container {
    border: 1px solid #e2e2e3;
    border-radius: 4px;
    flex: auto;

    .car-header {
      background-color: #f9fafb;
      border-top: 1px solid #e2e2e3;
      border-bottom: 1px solid #e2e2e3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 36px;
      padding: 0 17px;

      .car-items {
        display: flex;
        align-items: center;
        gap: 8px;

        i.icon {
          margin-right: -2px !important;
          margin-top: -5px !important;
          font-size: 16px;
        }

        p {
          font-size: 15px;
          font-weight: bold;
          margin: 0;
          padding: 0;
        }

        .button {
          height: 25px;
          padding: 0 12px;
          font-size: 11px;
          position: relative;

          i.icon {
            font-size: 11px;
            margin-top: -2px !important;
            margin-left: -2px !important;
          }

          i.icon.arrow.rotate {
            padding-left: 5.6px;
            padding-right: 9px;
            margin-top: 0px !important;
          }

          .divider {
            position: absolute;
            width: 1px;
            background-color: #e2e2e3;
            height: 25px;
            top: 0;
            right: 25px;
          }
        }
      }
    }

    .car-info {
      display: flex;
      justify-content: start;
      padding: 5px 17px;
      gap: 55px;

      .info-data {
        text-wrap: nowrap;

        .flex-row {
          display: flex;
          align-items: center;
          gap: 19px;

          .button {
            height: 25px;
            padding: 0 5px;
            font-size: 11px;
            position: relative;

            i.icon {
              font-size: 11px !important;
              margin: -2px 0 0 0 !important;
            }
          }
        }

        p {
          font-size: 14px;
          margin: 0;
        }

        i.icon {
          cursor: pointer;
        }

        .info-wo {
          padding: 0 5.5px;
          background-color: #fef0e7;
          color: #ff0202;
        }

        .info-link {
          text-decoration: none;
          font-weight: 600;

          &:hover {
            color: #21ba45;
            text-decoration: none;
          }
        }

        &.AppointmentDate {
          .react-datepicker__input-container {
            height: 24px;
            display: flex;
            align-items: center;

            input {
              position: relative;
              bottom: 1px;
              border-bottom: none;
              color: inherit;
              color: #414141;
              font: normal normal bold 14px/24px Lato;
              max-width: 90px;
              text-shadow: none;
              padding: 0;
            }
          }

          .extendedCarDetailsDatePicker {
            input {
              width: 100%;
              max-width: 135px;
            }
          }
        }
      }

      .more-info {
        display: none;
        align-items: center;
        cursor: pointer;

        i {
          position: relative;
          bottom: 3px;
        }

        @media (max-width: 1850px) {
          display: flex;
          margin-left: auto;
        }
      }
    }

    .customer-details-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 17px;
      gap: 50px;

      .info-data {
        p {
          color: #414141;

          span {
            font-size: 15px;
            font-weight: bold;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .car-info-value {
    text-align: left;
    text-wrap: nowrap;
    font: normal normal bold 15px/24px Lato;
    letter-spacing: 0px;
    color: #414141;
  }

  .display-car-info-container {
    display: none;

    @media (min-width: 1851px) {
      display: flex;
    }
  }

  .car-make-max-width-column {
    max-width: 100px;
  }

  .car-model-max-width-column {
    max-width: 200px;
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.CarDetails__mode-engine-container {
  width: fit-content;
  padding: 1px 8px;
  display: flex;
  flex-direction: row;
  gap: 36px;

  .info-column {
    display: flex;
    flex-direction: column;

    p:first-child {
      font-size: 14px;
      margin: 0;
      padding: 0;
    }

    .car-info-value {
      text-align: left;
      font: normal normal bold 14px/24px Lato;
      letter-spacing: 0px;
      color: #414141;
    }
  }
}
