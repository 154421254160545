@import "../../colorbox.scss";

.customer-com-container {
  padding: 0 40px 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 24px;

  .fields {
    margin: 0 !important;
  }

  h3 {
    margin: 0;
    text-transform: uppercase;
  }

  .field {
    .title {
      margin: 0em 0em 0.28571429rem 0em;
      font-size: 15px;
      font-weight: 700;
    }
  }

  .customer-com-tabs {
    position: sticky;
    top: 39px;
    z-index: 2;
    .ui.secondary.pointing.menu {
      border: 0;
      justify-content: space-between;
    }
  }

  .customer-com-header {
    position: sticky;
    top: 0;
    padding-top: 24px;
    background: #ffffff;
    z-index: 5;
    .row.center {
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #E2E2E3;
      border-radius: 10px;
    }
  }

  .full {
    width: 100%;
  }

  .save {
    margin-right: 20px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    .field {
      width: 100%;
    }
  }

  .row.center {
    align-items: center;
  }
}
.customer-com-wrapper {
  padding: 19px 24px;
  border-radius: 10px;
  border: 1px solid $light-gray;

  .field > label.small {
    display: flex;
    align-items: center;

    i.icon {
      margin: -10px 0 0 11px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    margin: 18px 0 0 0 !important;
  }

  .file {
    .field {
      margin: 0;
    }
  }

  .full {
    width: 100%;
    .field {
      margin: 0;
    }
  }

  .auto {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 38px;
    .field {
      margin: 0;
    }
    .toggle-test {
      min-width: 140px;
      width: 190px;
      padding-bottom: 5px;
    }
  }

  .items-center {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin: 0;

    .ui.fitted.toggle.checkbox {
      margin: 7px 6px 0 0;
    }
  }

  .mt-25 {
    margin-top: 25px !important;
  }

  .mt-24 {
    margin-top: 24px !important;
  }

  .mt-22 {
    margin-top: 22px !important;
  }

  .mt-20 {
    margin-top: 20px !important;
  }

  .mt-18 {
    margin-top: 18px !important;
  }

  .mt-16 {
    margin-top: 16px !important;
  }

  .mt-14 {
    margin-top: 14px !important;
  }

  .mt-12 {
    margin-top: 12px !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .pr-12 {
    padding-right: 12px !important;
  }

  .row.half {
    width: calc(50% - 12px);
  }
  .ui.form .field {
    width: 100%;
  }
}
