.HeaderSectionContent {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;

  .icon-container {
    padding-right: 3px;
  }

  .text-icon-container {
    margin-bottom: 12px;
    color: #5c7c8d;
    display: flex;
    align-content: center;
    gap: 7px;
    align-items: center;
  }

  .grid-header-container {
    background: #f3f5f7;
  }

  .grid-title-text {
    font-weight: 700;
    color: #234357;
  }

  .header-section {
    border: 2px solid;
    border-color: rgba(187, 194, 198, 0.75);
    margin-bottom: 14px;
    padding-top: 14px;
    font-size: 15px;
  }

  .appointment-section {
    margin-bottom: 14px;
  }

  .reg-container {
    width: 130px;
    height: 24px;
    margin-bottom: 8px;
    border: 2px solid #234457;
    border-radius: 0.15em;
  }

  .reg-country {
    width: 20%;
    height: 100%;
    line-height: 100%;
    display: block;
    float: left;
    text-align: center;
    background-color: #40a7ff;
    padding-top: 3px;
    position: relative;
  }

  .reg-country > div {
    color: #fff;
    font-size: 10px;
    bottom: 0px;
    position: absolute;
    width: 100%;
  }

  .reg-nr {
    width: 80%;
    height: 100%;
    line-height: 100%;
    float: right;
    text-align: center;
    padding-top: 2px;
    font-weight: 500;
    font-size: 16px;
  }

  .BE .reg-nr {
    background-color: #fff;
    color: #ac1713;
  }

  .NL .reg-nr,
  .LU .reg-nr {
    background-color: #efe24c;
    color: #234457;
  }

  .FR .reg-nr {
    background-color: #fff;
    color: #000;
  }
}
