.ImportantNotes {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;

  .title {
    font-weight: 700;
    background: #f8e89e;
    padding: 14px;
    font-size: 17.5px;
    color: #1d3d52;
  }

  .icon-text-container {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #1d3d52;
  }

  .ui.basic.label.label-container {
    display: inline-flex;
    align-items: center;

    svg {
      margin-right: 3px;
    }
  }
}
