.NavBarSearch {
  position: relative;
  .nav-bar-search {
    min-width: 500px;
    width: 100%;
    height: 26px;
    input {
      font-size: 12.6px;
      background-color: #f9fafb;
    }
    i.icon {
      font-size: 10px;
    }
  }
  .list-view {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 2px;
    z-index: 1;
  }
}
