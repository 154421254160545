.CustomerEntities {
  margin-top: 20px;

  .tab {
    padding: 0 !important;
  }

  .ReactTable .rt-tbody {
    overflow-y: hidden;
  }
}
