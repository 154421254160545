.snooze-button-card-user {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 25px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #e2e2e3;
}

.snooze-button-card-icon {
  margin: auto !important;
  color: #767676 !important;
}

.snoozed-button-snoozed {
  background-color: #2d84ce;
}

.snoozed-button-icon-snoozed {
  color: #ffffff !important;
}
