.FilePreviewModal {
  width: 90% !important;
  position: relative;

  .FilePreviewHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;

    > div:first-child {
      flex: 1;
      max-width: 70%;
      display: flex;
      align-items: center;
    }

    > div:nth-child(2) {
      flex: 0 0 30%;
      display: flex;
      justify-content: flex-end;
    }

    .Actions {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-right: 10px;
    }

    .FilePreviewName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
      margin-left: 5px;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .FilePreviewContent {
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center;

    iframe {
      border: none;
      width: 100%;
      height: calc(100vh - 200px);
    }

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: fit-content;
    }
  }

  .FilePreviewContent:focus {
    outline: none;
  }

  .left-arrow {
    position: absolute;
    left: calc(-5% + 15px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    color: #d3d3d3;
    font-size: 40px;
    cursor: pointer;
  }

  .right-arrow {
    position: absolute;
    right: calc(-5% + 15px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    color: #d3d3d3;
    font-size: 40px;
    cursor: pointer;
  }
}
