.PinOverviewContent {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;

  .modal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .buttons-container {
    margin-right: 40px;
    display: flex;
    gap: 14px;
  }

  .cancel-icon {
    color: #767676;
  }
}

.log-section {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  margin: 14px 0px;

  .title {
    font-weight: 600;
    background: #f7f7f7;
    padding: 14px;
    font-size: 1.25rem;
    color: #1d3d52;
  }
}
