.Details {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 21px;
    .Details-img {
        width: 214px;
        height: 138px;
        background-color: #e8e8e8;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }
    .Details-container {
        border: 1px solid #e0e1e2;
        border-radius: 4px;
        padding: 17px 19px;
        height: 138px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 45px;
        .group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 300px;
                margin: 0;
                font-size: 16px;
            }
            p.title {
                font-size: 12px;
            }
            p.title-data {
                height: 20px;
            }
        }
    }
}