.CustomerCard {
  &__customer-card-container {
    display: flex;
    justify-content: flex-end;
  }

  &__customer-card-user {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 25px;
    border-radius: 4px;
    background-color: #e6d0e6;
    color: white;
  }

  &__customer-card-icon {
    margin: auto !important;
    margin-top: 4px !important;
    color: #801280;
  }

  &__customer-card-no-box-shadow {
    box-shadow: none !important;
  }
}
