.Add-update-appointment-note-header {
  span {
    font-size: 18px;
    font-family: "Lato", sans-serif;
  }
  i {
    margin-right: 5px;
  }

  .backorder-icon {
    display: inline-block;
    margin-right: 5px;
    color: #6c0699;
  }
}
