.ui.label.dms-bg-color {
  background-color: #5c5c5c;
}

.ui.label.mechanic-color {
  color: #f2711c;
}

.ui.label.mechanic-bg-color {
  background-color: #f2711c1a;
}

.ui.label.chief-mechanic-color {
  color: #21ba45;
}

.ui.label.chief-mechanic-bg-color {
  background-color: #21ba451a;
}

.ui.label.default-colors {
  color: #2084ce;
}

.ui.label.receptionists-bg-color {
  background-color: #2084ce1a;
}

.ui.label.warranty-color {
  color: #ee3113;
}

.ui.label.chief-mechanic-color {
  background-color: #21ba451a;
  color: #21ba45;
}

.ui.label.warranty-bg-colors {
  background-color: #ee31131a;
}

.pin-user-label-container {
  gap: 5px;
}
