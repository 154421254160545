.carDetail-container {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 22px 20px;
  .carDetail-NavigationHeader {
    padding-top: 22px;
    padding-bottom: 5px;
    position: sticky;
    background-color: #fff;
    top: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    .carDetail-NavigationHeader-container {
      padding: 0px 20px;
      border: 1px solid #e0e1e2;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 13px;
      box-shadow: 0px 3px 6px #00000029;
      width: 100%;
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .sub-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          height: 25px;
          button.ui {
            height: 25px;
          }
          .ui.icon.button {
            padding: 0 7px !important;
          }
          i.icon {
            margin-right: -2px !important;
            margin-top: -2px !important;
            font-size: 12px;
          }
        }
      }
    }
  }
  .carDetail-tabs {
    position: sticky;
    top: 39px;
    z-index: 2;
  }
  .full {
    width: 100%;
  }
  .end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
  .button {
    height: 25px !important;
    padding: 0 12px !important;
    font-size: 11px !important;
    position: relative;
    i.icon {
      font-size: 11px !important;
    }
    i.icon.arrow.rotate {
      padding-left: 7px;
    }
    .divider {
      position: absolute;
      width: 1px;
      background-color: #e2e2e3;
      height: 25px;
      top: 0;
      right: 25px;
      padding-right: 0 !important;
    }
  }
  .button.-appointment-status.button-note {
    height: 25px;
    padding: 0 4px !important;
    i.icon {
      margin: -3px 0 0 0 !important;
      padding: 0 !important;
      font-size: 22px !important;
    }
  }
  .pointing.menu .item {
    padding: 15px 12.5px !important;
  }
  .ReactTable {
    margin: 0;
  }
  thead {
    th {
      font-size: 14px;
      font-weight: bold;
      padding-left: 10px;
    }
    th:first-child {
      padding-left: 17px;
    }
  }
  tbody {
    td {
      font-size: 12.6px;
      padding-left: 10px;
      div {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: flex-end;
        padding-right: 17px;
        button.ui.button.af {
          height: 25px;
        }
        &.StatusCellIcon {
          height: 25px;
          justify-content: center;
          padding: 0;
        }
      }
    }
    td:first-child {
      padding-left: 17px;
    }
  }
}
