.TableDropdownSelector {
  .item-width {
    max-width: 257px;
    border-bottom: 0.5px solid #e2e2e3 !important;
    background-color: white !important;
  }

  .icon-item {
    background-color: #e8f3fa !important;
  }

  .item-width:hover {
    background-color: #f9fafb !important;
  }

  .custom-dropdown {
    padding: 0px !important;
  }

  .selected-text-color {
    color: #b5b5b5cc;
  }

  .icon-selected-text-color {
    color: #000000cc;
  }

  .table-dropdown-filters-icon {
    padding: 0;
    margin-top: 3px;
  }

  .group-title {
    cursor: default;
    padding: 13px 16px;
    border: 1px solid #e2e2e3;
    border-radius: 0.285714rem;
    box-shadow:
      0px 2px 4px 0px rgba(34, 36, 38, 0.12),
      0px 2px 6px 0px rgba(34, 36, 38, 0.15);

    &__text {
      color: #a4a4a4;
      white-space: pre-line;
      font-size: 0.78571429em;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
