.DealerReports__dropdowns {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &-container {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
    width: 100%;

    .ui.selection.dropdown.button {
      margin: 0 10px 0 0;
      background-color: transparent !important;
      text-align: left;
      height: 26px;
      min-height: 26px;
      box-shadow: 0px 0px 2px #00000029 !important;
      border: 1px solid #e2e2e3 !important;
      padding: 0 8px !important;

      &.active {
        background-color: #fff !important;
        border: 1px solid #3498db !important;
      }

      .icon {
        background-color: transparent !important;
        font-size: 13px !important;
        top: inherit;
      }

      .text {
        font-size: 12.6px;
        font-weight: 400 !important;
        color: #414141;
      }

      input.search {
        padding-top: 5px !important;
      }
    }

    .datepicker-wrapper {
      margin-right: 10px;
      left: -10px;
      position: relative;
      background-color: transparent;
      text-align: left;
      height: 26px;
      box-shadow: 0px 0px 2px #00000029 !important;
      border: 1px solid #e2e2e3 !important;
      padding: 0 8px !important;
      max-width: 242px;

      .datepicker-row {
        background-color: transparent;

        p {
          font-size: 12.6px;

          &.date {
            color: #333333de;
          }
        }
      }
    }
  }
}

.DealerReports__container {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 22px 20px;

  &-header {
    padding-top: 22px;
    padding-bottom: 5px;
    position: sticky;
    background-color: #fff;
    top: 0;
    z-index: 2;
    display: flex;
    align-items: center;

    &-wrapper {
      padding: 0px 20px;
      border: 1px solid #e0e1e2;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 13px;
      box-shadow: 0px 3px 6px #00000029;
      width: 100%;

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tabs-container a {
          text-transform: uppercase;
        }

        button.ui {
          height: 25px;
        }

        .ui.icon.button {
          padding: 0 7px !important;
        }

        i.icon {
          margin-right: -2px !important;
          margin-top: -2px !important;
          font-size: 12px;
        }
      }
    }
  }

  &-content {
    padding-top: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;

    .row .export-button {
      margin-left: auto;

      button.button {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #e2e2e3;
        border-radius: 4px;
        box-shadow: none !important;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        i.icon {
          font-size: 13px !important;
        }

        &:hover {
          box-shadow: none !important;
        }
      }
    }

    .full {
      width: 100%;
    }

    .end {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
    }

    .button {
      height: 25px !important;
      padding: 0 12px !important;
      font-size: 11px !important;
      position: relative;

      i.icon {
        font-size: 11px !important;
      }

      i.icon.arrow.rotate {
        padding-left: 7px;
      }

      .divider {
        position: absolute;
        width: 1px;
        background-color: #e2e2e3;
        height: 25px;
        top: 0;
        right: 25px;
        padding-right: 0 !important;
      }
    }

    .ReactTable {
      thead {
        position: sticky;
        top: 0;
      }

      thead tr th {
        vertical-align: top;
        padding-top: 7px;
        padding-bottom: 7px;
        text-transform: capitalize;
      }
      thead tr th,
      tbody tr td {
        &:not(:first-child) {
          text-align: center;
        }
      }
    }
  }
}
