.Message {
  margin: 0 10px;

  span {
    font-size: 12.6px;
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
  }
}
