.Tyres {
  h4 {
    margin-top: 20px;
  }
  p {
    margin: 0;
  }

  .flex-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 70px;

    .tyre-container {
      align-self: flex-start;
      width: 100%;
      .header {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f8f8f8;
        height: 38px;
        h4 {
          margin-top: 0;
        }
      }

      .tyre-indicator {
        border-radius: 23px;
        height: 46px;
        width: 46px;
        background-color: #e8f1e3;
        border: 2px solid #188d45;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          color: #188d45;
          font-size: 23px;
          font-weight: 500;
        }
      }
    }
  }

  .tyre-details-font {
    font-size: 16px;
    font-weight: 600;
    color: #556e80;
  }

  .flex-row.padding {
    padding: 12px;
  }
  .flex-row.small-gap {
    gap: 14px;
  }
  .flex-row.start {
    justify-content: start;
  }
  .flex-row.end {
    justify-content: flex-end;
  }
  .tyre-details {
    margin-top: 20px;
    width: 100%;
  }
  .tyre-details.right {
    text-align: right;
  }
}

.delete-tyre-btn {
  i.icon {
    margin-left: -13px !important;
  }
}

.delete-tyre-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
