.Timer {
  border: 1px solid #e2e2e3;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  height: 36px;
  .Timerslot {
    min-width: 40px;
    text-align: center;
    padding: 5px 15px;
    background-color: #fcfff5;
    color: #000000cc;
    margin: 0;
    float: left;
    border-left: 1px solid #e2e2e3;
    border-color: #e2e2e3;
    font-weight: 600;
    font-size: 14px;
    &:first-child {
      border-bottom-left-radius: 3px;
      border-left: none;
      border-top-left-radius: 3px;
    }
    &:last-child {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
    }
    &.blue {
      background-color: #e7f2fb;
    }
    &.red {
      background-color: #ffecea;
    }
  }
}
