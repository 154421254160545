.Dayplanner {
  height: calc(100dvh - 39px);
  padding: 23px 13px 13px 13px;
  display: grid;
  grid-template-columns: auto 265px 1fr auto auto;
  column-gap: 8px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  &-columnHeader {
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    h4 {
      margin-bottom: 14px;
      font-size: 14px;
      font-family: "Lato";
    }

    .collapse-column-label {
      &:hover {
        background-color: #f9fafb;
      }
    }

    div.label {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      right: 5px;
      position: relative;
      width: 22px;
      height: 22px;
      box-shadow: 0px 0px 2px #00000029;
      border-radius: 4px;
      background-color: white;
    }
    .dropdown.button.icon.labeled {
      background-color: #fff;
      border: 1px solid #70707029;
      font-weight: 600;
      position: absolute;
      right: 50px;
      top: -10px;
      min-width: 120px;
      appointment &.active {
        background-color: #f0f0f0;
      }
      i.filter.icon {
        color: rgb(76, 175, 80);
        background-color: #fff;
        border-right: 1px solid #70707029;
        &::before {
          background-color: #fff;
        }
      }
    }
    .dropdown.button.icon.sort-btn {
      background-color: #fff;
      border: 1px solid #70707029;
      font-weight: 600;
      padding: 4px;
      &.active {
        background-color: #f0f0f0;
      }
      i.sort.icon {
        color: rgb(76, 175, 80);
        background-color: #fff;
        &::before {
          background-color: #fff;
        }
      }
      .asc-desc-img {
        width: 30px;
        height: 20px;
        margin-right: 0;
      }
      &.ui.dropdown {
        .menu > .item {
          padding: 13px 5px 5px 5px !important;
        }
      }
    }
  }
  &-columnContainer {
    height: calc(100vh - 75px);

    &:not(.-mechanic-tasks) {
      width: 265px;
    }

    &.-collapsed {
      width: auto !important;
      cursor: pointer;
    }

    &.-mechanic-tasks {
      .Dayplanner-columnHeader {
        margin-bottom: 5px;
        margin-top: -4px;
        align-items: center;

        h4 {
          margin-bottom: 0;
        }

        .dropdown.button.icon.labeled {
          position: relative;
          right: 0;
          top: 0;
          min-width: auto;
        }

        &-section {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 5px;
        }
      }

      .wo-filter-active {
        background-color: #f9fafb !important;
      }

      .mechanic-filter-active {
        background-color: #f9fafb !important;
      }
    }
  }

  &-column {
    display: flex;
    width: 100%;
    height: calc(100% - 33px);
    background-color: #f2f2f2;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 0;
    overflow-x: hidden;
    scrollbar-width: thin;
    overflow-y: scroll;
    flex-direction: column;
    row-gap: 10px;

    &.-on-drag-hover {
      border: 1px dashed #d4d4d5;
    }
  }
}

.nav-bar-wrapper.Dayplanner-Navbar {
  #navbar-portal-container {
    width: 100%;
    justify-content: flex-start;
    padding-left: 10px;
  }
  .NavBarSearch {
    width: 100%;
    .nav-bar-search {
      min-width: 250px;
    }
  }
}

.BoardItem {
  position: relative;
  background-color: #fff;
  border: 1px solid #d4d4d5;
  border-radius: 4px;
  padding: 10px 12px;
  height: 91px;
  width: 100%;
  flex: 0 0 auto;
  cursor: default;
  font-size: 12.6px;
  line-height: 15px;
  .BoardItem-kiosk-label {
    position: absolute;
    right: 0;
    height: 20px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    gap: 3px;
    background-color: #21ba45 !important;
    color: #fff !important;
  }
  &.-popup {
    width: 254px;
    .-expanded {
      position: absolute;
      right: -3px;
      top: -3px;
    }
  }
  &.-cursor-move {
    cursor: move;
  }
  &.-appointment-internal .wo-nr {
    background-color: #fef0e7;
    color: #fe0202;
  }
  &.-panic {
    border: 1.5px solid #db2828;
  }
  &.-inactive {
    opacity: 0.4;
  }
  &.-bg-color-red {
    background-color: #db28281a;
  }
  &.-bg-color-yellow {
    background-color: #fbbd081a;
  }
  &-info {
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 24px);
    white-space: nowrap;
    font-size: 12.6px;
    line-height: 15px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  &-last-update {
    display: flex;
    column-gap: 7px;

    span.labor-minutes {
      font-style: italic;

      i.icon {
        position: relative;
        bottom: 1px;
        font-size: 9.5px;
        margin-right: 2.6px;
      }
    }
  }
  &-bottom-elements {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    min-height: 25px;
    max-height: 40px;
    margin-top: 3px;
  }

  .Mechanics-Notifications-Container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .NotificationIcons {
      display: inline-flex;
      column-gap: 1px;
      flex-wrap: wrap;
      .bigTire {
        font-size: 1.2em;
        padding: 3px 0 0 0 !important;
        svg {
          width: 25px;
        }
      }
      .ui.circular.label,
      .circle-icon-container .circle-icon {
        width: 16px;
        height: 16px;
        font-size: 8px;
        border-radius: 50%;
        padding: 0px 4px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        transform: none;
        &.checkedTire {
          overflow: hidden;
          position: relative;
          .checkedTire-tire {
            font-size: 10px;
          }
          .checkedTire-check {
            position: absolute;
            bottom: 0px;
            right: 1px;
            font-size: 9px;
          }
        }
        i {
          font-size: 9px;
          &.icon {
            margin: 0;
          }
        }
      }
      .customer-com {
        .smile.icon {
          font-size: 1.3em;
          transform: translateY(1px);
        }
      }
      &-Header {
        position: relative;
        font-size: 12.6px;
        font-weight: 600;
        right: 3px;
      }
      .mechanic-info-profile-picture-fallback {
        display: flex;
        height: 25px;
        width: 25px;
        border-radius: 50px;
        background-color: grey;
        color: #fff;
        font-size: 12px;
        justify-content: center;
        align-items: center;
      }
      .AppointmentSpecialIndicators-AppointmentNotePopup {
        .AppointmentNoteTitle {
          display: flex;
          margin-bottom: 3px;
          align-items: center;
          svg {
            position: relative;
            margin-right: 7px;
            bottom: 1px;
          }
        }
        .AppointmentNoteDescription {
          font-style: italic;
          font-size: 12px;
          color: #767676;
        }
        .AppointmentNoteContent {
          margin-top: 10px;
          font-size: 14px;
          .CallCustomerPhoneNr {
            margin-bottom: 6px;
          }
        }
      }
    }
    &-planning-mechanic {
      display: flex;
      width: 25px;
      height: 25px;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      position: relative;
      top: 10px;
      left: 8px;
      .initials-fallback {
        display: flex;
        width: 25px;
        height: 25px;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        background-color: grey;
        color: #fff;
        font-size: 12px;
      }
    }

    .Working-On-Mechanics {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;

      img,
      .initials-fallback {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        background-color: grey;
        color: #fff;
        font-size: 8px;
        border: 2px solid #21ba45;
      }

      &.margin-right-10 {
        margin-right: 10px;
      }

      &.initials-fallback-margin-right {
        .initials-fallback {
          margin-right: 2px;
        }
      }
    }
  }
}

.dayplanner-disabled {
  width: 100%;
  margin-top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.mechanic-filter-dropdown {
  .divider {
    font-weight: 700 !important;
  }

  &:hover {
    background-color: #f9fafb !important;
    i {
      background-color: #f9fafb !important;
    }
  }
}
