.ResetPasswordRequest {
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            width: 100%;
            margin: 0;
            font-family: "Lato" !important;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #414141;
        }
        a {
            font-family: "Lato" !important;
            font-size: 14px;
            letter-spacing: 0px;
            color: #B5B5B5;
        }
    }
    &-language {
        p {
            font-family: "Lato" !important;
            font-size: 14px;
            letter-spacing: 0px;
            color: #414141;
        }
    }
    &-success {
        background: #DEF5E3 0% 0% no-repeat padding-box;
        border: 1px solid #21BA45;
        border-radius: 4px;
        opacity: 1;
        padding: 15px 13px;
        font-family: "Lato" !important;
        font-size: 14px;
        letter-spacing: 0px;
        color: #21BA45;
    }
}