.PinSectionContent {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;

  .pin-section {
    border: 2px solid;
    border-color: rgba(187, 194, 198, 0.75);
    margin-bottom: 14px;
    padding-top: 14px;
    font-size: 15px;
  }

  .grid-header-container {
    background: #f3f5f7;
  }

  .grid-title-text {
    font-weight: 700;
    color: #234357;
  }

  .grid-field-text {
    font-weight: 400;
    font-size: 15.6px;
    color: #5c7c8d;
  }

  .results-grid {
    margin-bottom: 1px;
  }
}
