.AppointmentNotes {
  .appointment-notes-header {
    width: 20%;
    display: flex;

    .appointment-notes-header-title {
      font-size: 18px;
      text-transform: capitalize;
      font-weight: 600;

      .appointment-notes-chevron-icon {
        font-size: 18px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  .car-note-container {
    border-top: 1px solid #e2e2e3;
  }

  .appointment-notes-content {
    border: 1px solid #e2e2e3;
    border-radius: 4px;
    margin-top: 20px;

    .receptionist-remarks-header {
      width: 20%;
      display: flex;
      margin-bottom: 10px;

      .receptionist-remarks-header-title {
        font-size: 14px;
        text-transform: capitalize;
        margin-right: 5px;
        font-weight: 600;
      }
    }

    .dms-remarks-header {
      width: 20%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .dms-remarks-header-title {
        font-size: 18px;
        text-transform: capitalize;
        margin-right: 5px;
        margin-left: 5px;
        font-weight: 600;
      }
    }
  }
}
