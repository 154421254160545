.checkbox-is-visible {
  transform: scale(0.7);
}

.pin-container {
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  gap: 5px;
}

.cancel-save-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.documentation-modal-title {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 700;
}
