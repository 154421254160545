.CustomerInfo {
  padding-top: 16px;

  .ReactTable {
    margin: 0;
  }

  thead {
    th {
      font-size: 14px;
      font-weight: bold;
      padding-left: 40px;
    }
  }

  tbody {
    td {
      font-size: 12.6px;
      padding-left: 40px;
      span {
        cursor: pointer;
      }
    }
    td:first-child {
      background-color: #f9fafb;
      border-right: 1px solid #e3e3e4;
      font-weight: bold;
      padding-left: 17px;
      text-transform: capitalize;
    }
  }
}
