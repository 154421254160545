.edit-button-container {
  display: flex;
  justify-content: flex-end;
  border: none;
  outline: none;
  background-color: transparent;
}

.edit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  cursor: pointer;
  height: 28px;
  background: #c7eed0;
  border-radius: 5px;
}

.edit-icon {
  font-size: 14px !important;
  color: #32bf53 !important;
  margin: 0px !important;
}

.note-suggestions-container {
  padding: 20px 10px 10px 10px;
}

.note-info-container {
  display: flex;
  gap: 15px;

  .visibilityToggle {
    cursor: pointer !important;
  }
}

.green-lime {
  color: #21ba45;
}

.edit-button-wrapper {
  padding: 5px;
  display: flex;
  justify-content: flex-end;
}
