.PinType {
  .items-center {
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    align-items: center;
    div {
      display: flex;
      align-items: flex-start;
      gap: 10px;
    }
  }

  .checkbox-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 6px 6px 6px 10px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 3px;
    white-space: nowrap;

    i {
      margin: 0px;
      position: relative;
      left: 5px;
    }

    .checkbox-form-field {
      display: flex;
      align-items: center;
    }

    .checkbox-box {
      margin: 0px !important;
    }
  }

  p {
    font-size: 12.6px;
  }

  .warranty-actions {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .orderStatusTitle {
    padding-left: 10px;
  }
}
