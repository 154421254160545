@import "../../../../colorbox.scss";

.WarrantyDashboardFilters {
  &__first-toolbar-container {
    display: flex;
    align-items: flex-end;
    margin: 9px 9px 5px 0px;
    .ui.grid > .column:not(.row) {
      padding-bottom: 0px;
    }
  }

  &__second-toolbar-container {
    display: flex;
    margin: 9px 9px 5px 0px;
    .ui.grid > .column:not(.row) {
      padding-top: 0px;
    }
  }

  &__toolbar-grid {
    width: 100% !important;
    margin: 0px 9px 0px 22px !important;
  }

  &__toolbar-reset-export-buttons-container {
    display: flex;
    gap: 9px;
    margin-right: 12px;
  }

  &__right-gutter {
    padding-right: 4.5px !important;
  }

  &__left-gutter {
    padding-left: 4.5px !important;
  }

  &__no-right-gutter {
    padding-right: 0px !important;
  }

  &__no-left-gutter {
    padding-left: 0px !important;
  }

  &__flex-align-justify-center {
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
  }

  &__filters-checkbox-container {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  &__filters-checkboxes-container {
    display: flex;
    gap: 5px;
    width: 100%;
    justify-content: space-around;
  }
}
