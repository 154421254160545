.NoteAttachments {
  .ui.menu {
    border: 0;
    box-shadow: unset;
    .active.item {
      background-color: #21ba45;
      color: #ffffff;
    }
    .item {
      height: 25px;
      padding-left: 10px;
      padding-right: 10px;
      border: 1px solid #70707029;
      &:last-child {
        border-radius: 0px 0.28571429rem 0.28571429rem 0px;
      }
      &:before {
        content: unset;
      }
    }
  }
  .ui.attached.segment {
    border: none;
    padding: 0;
  }
  .attachment-container {
    margin: 5px 0px 13.5px 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    .attachment-view {
      padding: 0 2px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .attachment-name {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        max-width: 75%;
        p {
          font-size: 14px;
          color: #3175c6;
        }
        i.icon {
          margin-top: -6px;
          margin-right: 0px;
          color: #414141 !important;
          font-size: 14px;
        }
      }
      .attachment-delete {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        p {
          font-size: 12px;
          font-weight: bold;
          font-style: italic;
          color: #000000;
        }
        i.icon {
          cursor: pointer;
          margin-top: -6px;
          margin-right: 0px;
          color: #414141 !important;
          font-size: 14px;
        }
      }
    }
  }
}
