.PinLog {
  .PinLogHistoryHeading {
    margin-top: 10px;

    .right-align {
      text-align: right;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .PinLogHistoryHeading > div {
    display: flex !important;
    align-items: center;
    gap: 5px;
    margin-left: 5px;
  }

  .PinLogHistory {
    border: 1px solid #e2e2e3;
    border-radius: 4px;
    margin-top: 8px;
    padding: 5px;

    .pin-log-item {
      width: fit-content;
      border: 1px solid #e9e9ea;
      border-radius: 4px;
      background-color: #fcfff5;
    }

    .eye {
      cursor: default;
    }

    .pin-info {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 3px;
      font-size: 12.6px;
      font-weight: 400;

      strong {
        font-weight: 700;
      }

      .important-container {
        display: flex;
        align-items: center;
        margin-right: 2px;

        i {
          margin-right: 5px;
        }

        .ui.fitted.checkbox {
          margin-left: 5px;
        }
      }

      & > * {
        display: flex;
        align-items: center;
        height: 25px;
      }
    }

    .ui.grid {
      margin: 5px 0 5px 0 !important;
    }

    .column {
      padding: 5px 10px !important;
    }

    .logInfo {
      .log-label {
        margin-bottom: 5px;
      }
    }

    .icons-right {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: right;
      margin-right: 10px;
    }
  }
}

p.logHistoryParagraph {
  .ui.basic.label.label-container {
    display: inline-flex;
    align-items: center;
    margin: 0 1px;

    svg {
      margin-right: 3px;
    }
  }
}
