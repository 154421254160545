.WarrantyDashboardTable {
  padding: 0px 10px 55px 10px;

  &__intervention-question-result-container {
    width: 105px;
  }

  &__ref-container {
    display: flex;
    align-items: inherit;
    width: 70px;
  }

  &__copy-icon {
    display: none !important;
  }

  &__copy-to-clipboard:hover &__copy-icon {
    display: block !important;
  }

  &__pin-status-container {
    width: 100px;
  }

  &__warranty-pin-type-container {
    width: 60px;
  }

  &__copy-to-clipboard {
    align-items: baseline;
  }

  &__table-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &__table-action-buttons-container {
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: flex-end;
    margin-right: 10px;
  }

  &__table-icon-placeholder-div {
    width: 32px;
    height: 25px;
  }

  &__warranty-mechanic-fixed-status-container {
    flex-direction: column;
    position: relative;

    .fixed-icon-container {
      position: absolute;
      font-size: 0.5rem;
      top: 5px;
      left: 8px;
    }
  }

  &__mechanic-not-fixed {
    margin-top: 5px;
  }

  &__table-header-text {
    font-size: 13px;
    color: #000000cc;
    font-weight: 900;
  }

  &__claim-icon-filter {
    margin-top: 4px !important;
  }

  &__customer-approved-table-column {
    margin-left: 10px;
  }
}
