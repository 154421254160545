.intervention-section {
  margin: 14px 0px;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;

  .title {
    font-weight: 700;
    background: #bdf0c3;
    padding: 14px;
    font-size: 17.5px;
    color: #1d3d52;
  }

  .status-name-container {
    display: flex;
    justify-content: space-between;
    padding: 0 14px;
    margin: 14px 0px;
  }

  .name-text {
    font-size: 18px;
    margin: 0;
  }

  .description {
    padding: 0 14px;
  }
}
