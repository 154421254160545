.AppointmentCard {
  direction: ltr;

  &:not(.-mechanic) {
    width: 100%;
  }

  &.-mechanic {
    width: auto;
  }

  .BoardItem-appointment-status {
    &.-expanded {
      position: absolute;
      top: -3px;
      right: -3px;
    }

    &.-collapsed {
      .fa-layers {
        height: auto;
      }

      .StatusCellIcon {
        i.icon {
          height: auto;
        }
        .small-icon {
          bottom: -7px;
        }
        .top-right {
          top: -7px;
        }
      }
    }

    .AppointmentStatusIcon {
      box-shadow: -1px 1px 3px #00000029;
    }

    i.icon {
      margin: 0;
    }
  }
}
