.Pin {
  .modal-header {
    p {
      margin: 0;
    }
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px 0 0;
  }
  .modal-footer {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }
  .grid {
    margin-top: 0px !important;
  }
}

.icon-count-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  i.icon {
    margin: 0 !important;
  }
}
