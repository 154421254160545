.DeskCommunicationModal {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
      width: 35px;
      margin-right: 20px;
      i.icon {
        margin-top: -4px !important;
      }
    }
  }

  &__content {
    .notes-container {
      padding-bottom: 23px;
      border-bottom: 1px solid #e2e2e3;

      &-input {
        display: inline-flex;
        flex: 1;
        width: 100%;
        align-items: center;
        margin-bottom: 9px;

        i.icon {
          font-size: 14px;
          position: relative;
          bottom: 2px;
          margin-right: 10px !important;
        }

        .clickable-input-wrapper {
          flex: 1;
          cursor: pointer;

          .ui.input {
            flex: 1 1;
            width: 100%;
          }

          input {
            cursor: pointer;
          }
        }
      }

      &-checkbox {
        display: inline-flex;
        column-gap: 10px;
        align-items: center;
        font-weight: 700;
        margin-top: 10px;

        i.icon {
          color: #a5673f;
          margin-right: 10px;
        }
      }
    }

    .interventions-container {
      padding-top: 20px;

      .full.end {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        gap: 8px;

        p {
          color: #000000cc;
          font-size: 13px;
          font-weight: normal;
          margin: 0px;
        }
      }
    }
  }

  &__actions {
    > button.ui.button.save-btn {
      margin-left: auto;
    }
  }
}
