.TableDatePickerInput {
  .filter-button {
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    cursor: pointer;
    padding: 0px;
  }

  .filters-icon {
    margin-left: 1px;
    margin-top: 7px;
  }
}
