.Pon {
  &__title {
    font-weight: bold;
    font-size: 20px;
  }
  &__modal {
    &-vin {
      display: flex;
      align-items: center;
      align-self: flex-start;
      gap: 3px;
    }
    &--itemContainer {
      display: flex;
      gap: 3px;

      span {
        &:first-of-type {
          font-weight: bold;
        }
      }
    }

    &-header {
      padding-right: 25px !important;
      display: flex;
      justify-content: space-between;
    }

    &-content {
      min-height: 420px;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &--gridContainer {
        width: 100%;
        & > * {
          padding: 24px 0;
          border-bottom: 1px solid #e2e2e3d9;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}
