.AttachmentPreview-Container {
  display: flex;
  align-items: center;
  gap: 12px;
  overflow-x: auto;
  padding-bottom: 10px;
  .media-view {
    border: 1px solid #e2e2e3;
    position: relative;
    margin-top: 10px;
    &.file-view {
      background-color: #e2e2e3;
    }
    padding-bottom: 5px;
    img,
    video {
      object-fit: cover;
      width: 200px;
      height: 130px;
    }

    .media-download {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 10px;
      gap: 3px;
      .media-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #d4d4d5;
        width: 35px;
        height: 25px;
        cursor: pointer;
        &:hover {
          background-color: #ccc;
        }
        i.icon {
          margin-top: -10px;
          margin-right: 0px;
        }
      }
    }
  }
}
