.AppointmentNoteEditBtn {
  width: 35px;
  height: 25px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  cursor: pointer;

  i {
    font-size: 0.7em;
    margin-right: 0px;
  }

  i.icon::before {
    position: relative;
    bottom: 1px;
  }
}
