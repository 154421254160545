.SnoozedItems {
  padding-top: 16px;

  .ui.button {
    i.icon {
      margin-left: -13px !important;
    }
  }

  thead {
    th {
      padding-left: 40px;
    }
  }

  tbody {
    td {
      padding-left: 40px;
    }
  }
}
