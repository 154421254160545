.add-update-attachments-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 15px;
  gap: 10px;
  i {
    margin-right: 0px;
  }
  i:first-child {
    margin-right: 3px;
    font-size: 14px;
    color: gray;
  }
  i:nth-child(2) {
    cursor: pointer;
    color: gray;
    font-size: 14px;
  }

  .attachment-name-container {
    max-width: 38rem;
  }
  .attachment-name {
    color: #4183c4;
  }

  & > div {
    word-break: break-all;
  }
}

.attachment-container {
  color: #4183c4;
}

.attachment-uploaded-by {
  color: gray;
  font-style: italic;
  margin-right: 3px;
}

.attachment-uploaded-date {
  color: gray;
  margin-right: 3px;
}
